export const LogoIconHeader = () => {
  return (
    <svg
      width="73"
      height="64"
      viewBox="0 0 73 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 64L36.5028 0L73 64H0Z"
        fill="#1D1E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3431 48.3417L16.1471 47.4721L15.1813 46.3467L14.9624 45.9659L14.7546 45.011L15.0185 44.0675L15.6923 43.3626L16.6132 43.0728H17.0511L18.4998 43.3513L19.8362 43.9709L21.0377 44.8405L22.0035 45.9659L22.2225 46.3467L22.4303 47.3016L22.1663 48.2451L21.4925 48.9499L20.5661 49.2398H20.1337L18.6907 48.9613L17.3431 48.3417Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3061 48.3417L24.1045 47.4721L23.1388 46.3467L22.9254 45.9659L22.712 45.011L22.9759 44.0675L23.6497 43.3626L24.5762 43.0728H25.0085L26.4572 43.3513L27.7992 43.9709L28.9952 44.8405L29.9609 45.9659L30.1799 46.3467L30.3933 47.3016L30.1294 48.2451L29.45 48.9499L28.5291 49.2398H28.0968L26.6481 48.9613L25.3061 48.3417Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2614 48.3417L32.0598 47.4721L31.094 46.3467L30.8807 45.9659L30.6673 45.011L30.9312 44.0675L31.605 43.3626L32.5315 43.0728H32.9638L34.4125 43.3513L35.7544 43.9709L36.956 44.8405L37.9218 45.9659L38.1352 46.3467L38.3485 47.3016L38.0847 48.2451L37.4109 48.9499L36.4844 49.2398H36.052L34.6034 48.9613L33.2614 48.3417Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8615 52.9441L34.6482 51.9835L34.9121 51.04L35.5859 50.3409L36.5123 50.0453H36.9447L38.3934 50.3295L39.7354 50.9434L40.9313 51.813L41.8971 52.9441L42.1161 53.3192L42.3295 54.2798L42.0655 55.2234L41.3861 55.9225L40.4653 56.218H40.0329L38.5843 55.9338L37.2423 55.3199L36.0407 54.4503L35.0749 53.3192L34.8615 52.9441Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7745 50.9434L32.9761 51.813L33.9419 52.9441L34.1608 53.3192L34.3686 54.2798L34.1047 55.2234L33.4309 55.9225L32.5045 56.218H32.0721L30.629 55.9338L29.2814 55.3199L28.0855 54.4503L27.1197 53.3192L26.9007 52.9441L26.693 51.9835L26.9569 51.04L27.6307 50.3409L28.5515 50.0453H28.9895L30.4382 50.3295L31.7745 50.9434Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9445 52.9441L18.7311 51.9835L18.9951 51.04L19.6688 50.3409L20.5953 50.0453H21.0277L22.4763 50.3295L23.8183 50.9434L25.0199 51.813L25.9856 52.9441L26.199 53.3192L26.4124 54.2798L26.1485 55.2234L25.4747 55.9225L24.5482 56.218H24.1159L22.6672 55.9338L21.3252 55.3199L20.1292 54.4503L19.1579 53.3192L18.9445 52.9441Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1388 60.301L22.9254 59.9202L22.712 58.9653L22.9759 58.0218L23.6497 57.317L24.5762 57.0271H25.0085L26.4572 57.3056L27.7992 57.9252L28.9952 58.7948L29.9609 59.9202L30.1799 60.301L30.3933 61.2559L30.1294 62.1994L29.45 62.9042L28.5291 63.194H28.0968L26.6481 62.9155L25.3061 62.296L24.1045 61.4264L23.1388 60.301Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.094 60.301L30.8807 59.9202L30.6673 58.9653L30.9312 58.0218L31.605 57.317L32.5315 57.0271H32.9638L34.4125 57.3056L35.7544 57.9252L36.956 58.7948L37.9218 59.9202L38.1352 60.301L38.3485 61.2559L38.0847 62.1994L37.4109 62.9042L36.4844 63.194H36.052L34.6034 62.9155L33.2614 62.296L32.0598 61.4264L31.094 60.301Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0982 60.301L46.306 61.2559L46.0421 62.1994L45.3683 62.9042L44.4418 63.194H44.0095L42.5608 62.9155L41.2188 62.296L40.0229 61.4264L39.0571 60.301L38.8381 59.9202L38.6247 58.9653L38.8943 58.0218L39.568 57.317L40.4889 57.0271H40.9269L42.3699 57.3056L43.7119 57.9252L44.9135 58.7948L45.8793 59.9202L46.0982 60.301Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3471 39.77L42.8132 41.1853L42.9704 42.6687L42.8132 44.1522L42.3471 45.5619L42.1281 45.9426L41.415 46.6077L40.4773 46.8464L39.5396 46.6077L38.8265 45.9426L38.6076 45.5619L38.1415 44.1522L37.9843 42.6687L38.1415 41.1853L38.6076 39.77L38.8265 39.3949L39.5396 38.7299L40.4773 38.4855L41.415 38.7299L42.1281 39.3949L42.3471 39.77Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9513 35.6949L46.794 37.1727L46.3224 38.588L46.109 38.9688L45.3959 39.6338L44.4582 39.8726L43.5205 39.6338L42.8074 38.9688L42.5884 38.588L42.1224 37.1727L41.9652 35.6949L42.1224 34.2114L42.5884 32.7961L42.8074 32.4154L43.5205 31.7503L44.4582 31.5116L45.3959 31.7503L46.109 32.4154L46.3224 32.7961L46.794 34.2114L46.9513 35.6949Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9278 28.7145L50.7706 30.198L50.3045 31.6075L50.0856 31.9884L49.3781 32.6533L48.4348 32.8921L47.497 32.6533L46.784 31.9884L46.5706 31.6075L46.0989 30.198L45.9417 28.7145L46.0989 27.231L46.5706 25.8157L46.784 25.4406L47.497 24.7756L48.4348 24.5312L49.3781 24.7756L50.0856 25.4406L50.3045 25.8157L50.7706 27.231L50.9278 28.7145Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.9065 35.6949L54.7493 37.1727L54.2833 38.588L54.0643 38.9688L53.3512 39.6338L52.4135 39.8726L51.4758 39.6338L50.7627 38.9688L50.5437 38.588L50.0777 37.1727L49.9204 35.6949L50.0777 34.2114L50.5437 32.7961L50.7627 32.4154L51.4758 31.7503L52.4135 31.5116L53.3512 31.7503L54.0643 32.4154L54.2833 32.7961L54.7493 34.2114L54.9065 35.6949Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.3045 39.77L50.7706 41.1853L50.9278 42.6687L50.7706 44.1522L50.3045 45.5619L50.0856 45.9426L49.3781 46.6077L48.4348 46.8464L47.497 46.6077L46.784 45.9426L46.5706 45.5619L46.0989 44.1522L45.9417 42.6687L46.0989 41.1853L46.5706 39.77L46.784 39.3949L47.497 38.7299L48.4348 38.4855L49.3781 38.7299L50.0856 39.3949L50.3045 39.77Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9652 49.6435L42.1224 48.1657L42.5884 46.7505L42.8074 46.3696L43.5205 45.7047L44.4582 45.4659L45.3959 45.7047L46.109 46.3696L46.3224 46.7505L46.794 48.1657L46.9513 49.6435L46.794 51.127L46.3224 52.5423L46.109 52.9231L45.3959 53.5825L44.4582 53.8268L43.5205 53.5825L42.8074 52.9231L42.5884 52.5423L42.1224 51.127L41.9652 49.6435Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9278 56.623L50.7706 58.1008L50.3045 59.5161L50.0856 59.8969L49.3781 60.5619L48.4348 60.8006L47.497 60.5619L46.784 59.8969L46.5706 59.5161L46.0989 58.1008L45.9417 56.623L46.0989 55.1395L46.5706 53.7243L46.784 53.3434L47.497 52.6841L48.4348 52.4397L49.3781 52.6841L50.0856 53.3434L50.3045 53.7243L50.7706 55.1395L50.9278 56.623Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.9065 49.6424L54.7212 51.2339L54.1766 52.7345L53.368 53.6836L52.9188 53.928L52.4135 54.0133L51.9082 53.928L51.4589 53.6836L50.6504 52.7345L50.1057 51.2339L49.9204 49.6424L50.1057 48.0566L50.6504 46.5504L51.4589 45.6069L51.9082 45.3568L52.4135 45.2716L52.9188 45.3568L53.368 45.6069L54.1766 46.5504L54.7212 48.0566L54.9065 49.6424Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.2608 39.77L58.7324 41.1853L58.8896 42.6687L58.7324 44.1522L58.2608 45.5619L58.0474 45.9426L57.3343 46.6077L56.3966 46.8464L55.4589 46.6077L54.7458 45.9426L54.5268 45.5619L54.0607 44.1522L53.9035 42.6687L54.0607 41.1853L54.5268 39.77L54.7458 39.3949L55.4589 38.7299L56.3966 38.4855L57.3343 38.7299L58.0474 39.3949L58.2608 39.77Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1813 38.9921L16.1471 37.861L17.3431 36.9971L18.6907 36.3775L20.1337 36.0933H20.5661L21.4925 36.3889L22.1663 37.088L22.4303 38.0315L22.2225 38.9921L22.0035 39.3672L21.0377 40.4983L19.8362 41.368L18.4998 41.9818L17.0511 42.266H16.6132L15.6923 41.9704L15.0185 41.2713L14.7546 40.3278L14.9624 39.3672L15.1813 38.9921Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1388 38.9921L24.1045 37.861L25.3061 36.9971L26.6481 36.3775L28.0968 36.0933H28.5291L29.45 36.3889L30.1294 37.088L30.3933 38.0315L30.1799 38.9921L29.9609 39.3672L28.9952 40.4983L27.7992 41.368L26.4572 41.9818L25.0085 42.266H24.5762L23.6497 41.9704L22.9759 41.2713L22.712 40.3278L22.9254 39.3672L23.1388 38.9921Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4844 36.0933L37.4109 36.3889L38.0847 37.088L38.3485 38.0315L38.1352 38.9921L37.9218 39.3672L36.956 40.4983L35.7544 41.368L34.4125 41.9818L32.9638 42.266H32.5315L31.605 41.9704L30.9312 41.2713L30.6673 40.3278L30.8807 39.3672L31.094 38.9921L32.0598 37.861L33.2614 36.9971L34.6034 36.3775L36.052 36.0933H36.4844Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8971 32.3934L40.9313 33.5188L39.7354 34.3885L38.3934 35.008L36.9447 35.2922H36.5123L35.5859 34.9966L34.9121 34.2918L34.6482 33.3483L34.8615 32.3934L35.0749 32.0126L36.0407 30.8872L37.2423 30.0176L38.5843 29.4037L40.0329 29.1195H40.4653L41.3861 29.4094L42.0655 30.1142L42.3295 31.0577L42.1161 32.0126L41.8971 32.3934Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5045 29.1195L33.4309 29.4094L34.1047 30.1142L34.3686 31.0577L34.1608 32.0126L33.9419 32.3934L32.9761 33.5188L31.7745 34.3885L30.4382 35.008L28.9895 35.2922H28.5515L27.6307 34.9966L26.9569 34.2918L26.693 33.3483L26.9007 32.3934L27.1197 32.0126L28.0855 30.8872L29.2814 30.0176L30.629 29.4037L32.0721 29.1195H32.5045Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1579 32.0126L20.1292 30.8872L21.3252 30.0176L22.6672 29.4037L24.1159 29.1195H24.5482L25.4747 29.4094L26.1485 30.1142L26.4124 31.0577L26.199 32.0126L25.9856 32.3934L25.0199 33.5188L23.8183 34.3885L22.4763 35.008L21.0277 35.2922H20.5953L19.6688 34.9966L18.9951 34.2918L18.7311 33.3483L18.9445 32.3934L19.1579 32.0126Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5762 28.3117L23.6497 28.0218L22.9759 27.317L22.712 26.3736L22.9254 25.4187L23.1388 25.0378L24.1045 23.9124L25.3061 23.0428L26.6481 22.4233L28.0968 22.1391H28.5291L29.45 22.4346L30.1294 23.1337L30.3933 24.083L30.1799 25.0378L29.9609 25.4187L28.9952 26.544L27.7992 27.4137L26.4572 28.0275L25.0085 28.3117H24.5762Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9218 25.4187L36.956 26.544L35.7544 27.4137L34.4125 28.0275L32.9638 28.3117H32.5315L31.605 28.0218L30.9312 27.317L30.6673 26.3736L30.8807 25.4187L31.094 25.0378L32.0598 23.9124L33.2614 23.0428L34.6034 22.4233L36.052 22.1391H36.4844L37.4109 22.4346L38.0847 23.1337L38.3485 24.083L38.1352 25.0378L37.9218 25.4187Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7118 27.4124L42.2687 28.0661L40.7022 28.3162L39.4894 28.0832L39.057 27.8103L38.7257 27.4124L38.5516 26.9236L38.5348 26.4064L38.9391 25.2242L39.9385 23.9794L41.2187 23.0415L42.6674 22.388L44.2284 22.1321L45.4468 22.3709L45.8792 22.6437L46.2104 23.0415L46.3845 23.5247L46.4014 24.0419L45.9914 25.2242L44.9976 26.4689L43.7118 27.4124Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.73624 48.535L12.2237 44.1812L13.2288 44.9769L14.0486 45.9659L14.2619 46.3467L14.4753 47.3016L14.2114 48.2451L13.5376 48.9499L12.6111 49.2398H12.1788L10.921 49.0181L9.73624 48.535Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2852 60.7045L54.7232 61.9152L54.8972 63.194H49.9336L50.1133 61.9152L50.5456 60.7045L50.7646 60.3237L51.4777 59.6587L52.4154 59.42L53.3531 59.6587L54.0662 60.3237L54.2852 60.7045Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8628 16.2737L47.3447 20.6274L46.1599 21.1106L44.9021 21.3379H44.4698L43.5433 21.0424L42.8695 20.3432L42.6056 19.3998L42.819 18.4392L43.0324 18.0641L43.8578 17.0694L44.8628 16.2737Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9007 18.4392L26.9513 18.3539L35.0761 4.10455L35.8959 3.11556L36.9009 2.31981L40.0173 7.78766L33.9868 18.3539L33.9419 18.4392L32.9761 19.5702L31.7745 20.4342L30.4382 21.0537L28.9895 21.3379H28.5515L27.6307 21.0424L26.9569 20.3432L26.693 19.3998L26.9007 18.4392Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8615 18.4392L34.9065 18.3539L39.0559 11.0843L39.8757 10.0953L40.8808 9.29958L43.3626 13.6534L43.9971 14.7617L41.9476 18.3539L41.8971 18.4392L40.9313 19.5702L39.7354 20.4342L38.3934 21.0537L36.9447 21.3379H36.5123L35.5859 21.0424L34.9121 20.3432L34.6482 19.3998L34.8615 18.4392Z"
        fill="#99ADFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5067 46.7507L58.7201 46.3699L59.4332 45.7049L60.3709 45.4662L61.3142 45.7049L62.0217 46.3699L70.1971 60.7045L70.635 61.9152L70.8147 63.194H64.5821L58.5067 52.5425L58.0351 51.1273L57.8779 49.6438L58.0351 48.166L58.5067 46.7507Z"
        fill="#EBEFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7788 62.4893L2.13816 61.8527L4.89509 57.0271H17.0515L18.5001 57.3056L19.8365 57.9252L21.0381 58.7948L22.0039 59.9202L22.2229 60.301L22.4306 61.2559L22.1667 62.1994L21.4929 62.9042L20.5664 63.194H4.22132L2.96355 62.9724L1.7788 62.4893Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.876 50.0453H13.0704L14.519 50.3295L15.861 50.9434L17.057 51.813L18.0227 52.9441L18.2417 53.3192L18.4551 54.2798L18.1912 55.2234L17.5118 55.9225L16.5909 56.218H8.19658L6.94446 55.9907L5.75971 55.5075L8.876 50.0453Z"
        fill="#5B7CFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5268 53.7248L54.7458 53.344L55.4589 52.6846L56.3966 52.4403L57.3343 52.6846L58.0474 53.344L58.2608 53.7248L62.2417 60.7045L62.6797 61.9152L62.8594 63.194H56.6268L54.7458 59.8975L54.5268 59.5166L54.0607 58.1013L53.9035 56.6236L54.0607 55.1401L54.5268 53.7248Z"
        fill="#EBEFFF"
      />
    </svg>
  );
};
