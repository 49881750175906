export const PHP = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5528 14.4541C13.7916 14.4541 1.82422 20.5187 1.82422 27.9995C1.82422 35.4804 13.7916 41.545 28.5528 41.545C43.3141 41.545 55.2815 35.4804 55.2815 27.9995C55.2815 20.5187 43.3141 14.4541 28.5528 14.4541ZM21.5979 30.6385C20.96 31.2353 20.2517 31.4816 19.4717 31.7354C18.6916 31.9895 17.6989 31.937 16.4918 31.937H13.7562L12.998 36.312H9.80378L12.6541 21.437H18.7966C20.6446 21.437 21.9925 21.9686 22.84 22.9385C23.6874 23.9085 23.942 25.2853 23.6043 27.0235C23.4647 27.7397 23.2298 28.4055 22.8982 28.9988C22.5545 29.6109 22.1156 30.1644 21.5979 30.6385ZM30.9215 31.937L32.1819 25.6283C32.3254 24.8898 32.2729 24.3412 32.024 24.073C31.7746 23.8044 31.2443 23.6245 30.4337 23.6245H27.9005L26.2682 31.937H23.099L25.9493 17.4995H29.1185L28.3608 21.437H31.184C32.9607 21.437 34.1857 21.7936 34.8598 22.4131C35.534 23.0326 35.7362 23.8814 35.4667 25.2691L34.1406 31.937H30.9215ZM48.5348 27.0467C48.3957 27.7629 48.1603 28.4173 47.8291 29.0102C47.4975 29.6038 47.0643 30.1468 46.5293 30.6385C45.8914 31.2353 45.1827 31.4816 44.403 31.7354C43.6234 31.9895 42.6298 31.937 41.4228 31.937H38.6871L37.9293 36.312H34.7352L37.585 21.437H43.728C45.576 21.437 46.9239 21.9686 47.7709 22.9385C48.6175 23.9085 48.8725 25.3089 48.5348 27.0467ZM42.5174 23.6245H40.3295L39.1364 29.7495H41.0798C42.3669 29.7495 43.3268 29.6227 43.9568 29.137C44.5877 28.6523 45.0125 27.8998 45.2334 26.7654C45.4452 25.6755 45.3489 24.8255 44.9447 24.3775C44.54 23.9295 43.731 23.6245 42.5174 23.6245ZM17.5865 23.6245H15.3981L14.205 29.7495H16.148C17.4355 29.7495 18.395 29.6227 19.0254 29.137C19.6558 28.6523 20.0811 27.8998 20.3016 26.7654C20.5133 25.6755 20.4171 24.8255 20.0128 24.3775C19.6086 23.9295 18.8001 23.6245 17.5865 23.6245Z"
        fill="#6181B6"
      />
    </svg>
  );
};
