export const ISOC = () => {
  return (
    <svg
      width="50"
      height="56"
      viewBox="0 0 50 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_20827)">
        <path
          d="M48.3898 41.9998C48.7743 41.3235 49.0132 40.5614 49.0132 39.8771V16.1226C49.0132 15.4383 48.7746 14.6764 48.39 14L24.5132 27.9999L48.3898 41.9998Z"
          fill="#00599C"
        />
        <path
          d="M26.6351 55.4866L46.8916 43.6094C47.4751 43.2673 48.0055 42.6764 48.3899 41.9999L24.5133 28L0.636719 42.0001C1.02119 42.6764 1.55171 43.2674 2.13506 43.6094L22.3916 55.4866C23.5585 56.1709 25.4682 56.1709 26.6351 55.4866Z"
          fill="#004482"
        />
        <path
          d="M48.39 13.9999C48.0053 13.3234 47.4748 12.7326 46.8914 12.3904L26.6349 0.513245C25.468 -0.171082 23.5583 -0.171082 22.3915 0.513245L2.13492 12.3904C0.967883 13.0746 0.0131836 14.7542 0.0131836 16.1227V39.8772C0.0131836 40.5615 0.251938 41.3236 0.636572 41.9999L24.5132 28L48.39 13.9999Z"
          fill="#659AD2"
        />
        <path
          d="M24.513 44.5877C15.5068 44.5877 8.17969 37.1464 8.17969 27.9999C8.17969 18.8534 15.5068 11.4121 24.513 11.4121C30.3245 11.4121 35.7444 14.5882 38.6575 19.7008L31.5889 23.8549C30.1309 21.2958 27.4194 19.706 24.513 19.706C20.0098 19.706 16.3464 23.4265 16.3464 27.9999C16.3464 32.5731 20.0098 36.2938 24.513 36.2938C27.4197 36.2938 30.1312 34.7039 31.5894 32.1444L38.658 36.2983C35.7449 41.4114 30.3248 44.5877 24.513 44.5877Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_20827">
          <rect
            width="49"
            height="56"
            fill="white"
            transform="translate(0.0131836)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
