export const LogoIcon = () => {
   return (
    <svg width="85" height="120" viewBox="0 0 85 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.36585 102.949H1.30762L8.69647 82.2328H13.3901L20.7892 102.949H16.731L11.1253 86.4812H10.9613L5.36585 102.949ZM5.49908 94.8263H16.567V97.8406H5.49908V94.8263Z" fill="#FCFCFC"/>
<path d="M35.9787 88.8546C35.8079 88.3084 35.5722 87.8195 35.2716 87.3879C34.9778 86.9496 34.6225 86.5753 34.2058 86.2651C33.7959 85.9549 33.3245 85.7223 32.7916 85.5672C32.2587 85.4053 31.6779 85.3244 31.0494 85.3244C29.9221 85.3244 28.9178 85.6043 28.0365 86.164C27.1551 86.7237 26.4617 87.5464 25.9561 88.6321C25.4574 89.7111 25.208 91.026 25.208 92.577C25.208 94.1415 25.4574 95.4666 25.9561 96.5523C26.4548 97.638 27.1483 98.4641 28.0365 99.0306C28.9246 99.5903 29.9563 99.8701 31.1314 99.8701C32.1972 99.8701 33.1195 99.6678 33.8983 99.2632C34.684 98.8586 35.2887 98.2854 35.7123 97.5436C36.1358 96.7951 36.3476 95.9184 36.3476 94.9137L37.2085 95.0452H31.5105V92.1117H40.0267V94.6001C40.0267 96.3736 39.6441 97.9078 38.8789 99.2025C38.1137 100.497 37.0616 101.495 35.7225 102.197C34.3834 102.891 32.8462 103.238 31.1109 103.238C29.1774 103.238 27.4796 102.81 26.0176 101.954C24.5624 101.091 23.4248 99.8667 22.605 98.282C21.792 96.6906 21.3855 94.8024 21.3855 92.6175C21.3855 90.9451 21.6246 89.4514 22.1028 88.1365C22.5879 86.8215 23.2643 85.7054 24.1319 84.7883C24.9996 83.8645 26.0176 83.1631 27.1859 82.6844C28.3541 82.1988 29.6249 81.9561 30.9981 81.9561C32.1596 81.9561 33.2425 82.1246 34.2468 82.4618C35.2511 82.7922 36.1427 83.2643 36.9215 83.8779C37.7072 84.4916 38.3528 85.2199 38.8584 86.0628C39.364 86.9058 39.6953 87.8364 39.8525 88.8546H35.9787Z" fill="#FCFCFC"/>
<path d="M48.1652 82.2393V102.955H44.3632V82.2393H48.1652Z" fill="#FCFCFC"/>
<path d="M52.3787 102.955V82.2393H56.1807V99.8094H65.4245V102.955H52.3787Z" fill="#FCFCFC"/>
<path d="M68.4229 102.949V82.2328H82.0733V85.3787H72.2249V91.0027H81.3662V94.1486H72.2249V99.803H82.1553V102.949H68.4229Z" fill="#FCFCFC"/>
<path d="M5.2661 119.824H1.78266V109.203H5.37643C6.43074 109.203 7.33619 109.416 8.09277 109.841C8.84936 110.263 9.42905 110.87 9.83186 111.661C10.2382 112.45 10.4413 113.395 10.4413 114.498C10.4413 115.604 10.2364 116.555 9.82661 117.35C9.4203 118.145 8.83184 118.757 8.06125 119.186C7.29066 119.611 6.35894 119.824 5.2661 119.824ZM3.40616 118.424H5.17678C5.99641 118.424 6.67768 118.272 7.2206 117.967C7.76352 117.66 8.16983 117.215 8.43954 116.635C8.70925 116.05 8.8441 115.338 8.8441 114.498C8.8441 113.665 8.70925 112.958 8.43954 112.377C8.17334 111.796 7.77578 111.355 7.24687 111.055C6.71796 110.754 6.06121 110.603 5.2766 110.603H3.40616V118.424Z" fill="#FCFCFC"/>
<path d="M12.4656 119.824V109.203H16.301C17.1347 109.203 17.8265 109.345 18.3764 109.628C18.9298 109.912 19.3431 110.304 19.6163 110.806C19.8896 111.303 20.0262 111.879 20.0262 112.533C20.0262 113.182 19.8878 113.755 19.6111 114.249C19.3379 114.74 18.9246 115.122 18.3711 115.395C17.8212 115.668 17.1294 115.805 16.2958 115.805H13.3903V114.425H16.1487C16.6741 114.425 17.1014 114.351 17.4307 114.202C17.7634 114.054 18.0069 113.838 18.161 113.554C18.3151 113.271 18.3921 112.93 18.3921 112.533C18.3921 112.131 18.3133 111.784 18.1557 111.49C18.0016 111.196 17.7582 110.972 17.4254 110.816C17.0962 110.657 16.6636 110.577 16.1277 110.577H14.0891V119.824H12.4656ZM17.7774 115.032L20.436 119.824H18.5865L15.9805 115.032H17.7774Z" fill="#FCFCFC"/>
<path d="M23.1552 119.824V109.203H29.9014V110.583H24.7787V113.819H29.5494V115.193H24.7787V118.444H29.9645V119.824H23.1552Z" fill="#FCFCFC"/>
<path d="M33.9365 119.824H32.2132L36.0854 109.203H37.9611L41.8334 119.824H40.11L37.068 111.132H36.9839L33.9365 119.824ZM34.2255 115.665H39.8158V117.013H34.2255V115.665Z" fill="#FCFCFC"/>
<path d="M42.8933 109.203H44.8635L48.2892 117.459H48.4153L51.8409 109.203H53.8112V119.824H52.2665V112.138H52.1667L48.9932 119.808H47.7112L44.5378 112.133H44.438V119.824H42.8933V109.203Z" fill="#FCFCFC"/>
<path d="M55.2241 119.824V109.203H61.9703V110.583H56.8476V113.819H61.6183V115.193H56.8476V118.444H62.0333V119.824H55.2241Z" fill="#FCFCFC"/>
<path d="M65.0898 119.824V109.203H68.9252C69.7589 109.203 70.4506 109.345 71.0006 109.628C71.554 109.912 71.9673 110.304 72.2405 110.806C72.5137 111.303 72.6503 111.879 72.6503 112.533C72.6503 113.182 72.512 113.755 72.2353 114.249C71.9621 114.74 71.5487 115.122 70.9953 115.395C70.4454 115.668 69.7536 115.805 68.92 115.805H66.0145V114.425H68.7729C69.2983 114.425 69.7256 114.351 70.0548 114.202C70.3876 114.054 70.631 113.838 70.7851 113.554C70.9393 113.271 71.0163 112.93 71.0163 112.533C71.0163 112.131 70.9375 111.784 70.7799 111.49C70.6258 111.196 70.3823 110.972 70.0496 110.816C69.7203 110.657 69.2878 110.577 68.7518 110.577H66.7133V119.824H65.0898ZM70.4016 115.032L73.0602 119.824H71.2107L68.6047 115.032H70.4016Z" fill="#FCFCFC"/>
<path d="M81.6482 111.993C81.5921 111.502 81.3609 111.122 80.9546 110.852C80.5483 110.579 80.0369 110.443 79.4204 110.443C78.9791 110.443 78.5973 110.512 78.2751 110.65C77.9528 110.785 77.7024 110.972 77.5237 111.21C77.3486 111.445 77.261 111.713 77.261 112.014C77.261 112.266 77.3206 112.484 77.4397 112.667C77.5623 112.851 77.7216 113.004 77.9178 113.129C78.1174 113.25 78.3311 113.352 78.5588 113.435C78.7865 113.514 79.0054 113.58 79.2155 113.632L80.2663 113.902C80.6096 113.985 80.9616 114.097 81.3224 114.239C81.6832 114.38 82.0177 114.567 82.3259 114.799C82.6342 115.03 82.8829 115.317 83.072 115.66C83.2647 116.002 83.361 116.412 83.361 116.889C83.361 117.49 83.2034 118.024 82.8881 118.491C82.5764 118.958 82.1228 119.326 81.5273 119.596C80.9354 119.865 80.2191 120 79.3784 120C78.5728 120 77.8758 119.874 77.2873 119.622C76.6988 119.369 76.2382 119.011 75.9055 118.548C75.5727 118.081 75.3888 117.528 75.3538 116.889H76.9826C77.0141 117.272 77.1402 117.592 77.3609 117.848C77.585 118.1 77.8705 118.289 78.2173 118.413C78.5675 118.534 78.9511 118.595 79.3679 118.595C79.8268 118.595 80.2348 118.524 80.5921 118.382C80.9529 118.237 81.2366 118.037 81.4433 117.781C81.6499 117.521 81.7532 117.219 81.7532 116.873C81.7532 116.559 81.6622 116.301 81.48 116.1C81.3014 115.9 81.058 115.734 80.7497 115.603C80.445 115.471 80.1 115.355 79.7147 115.255L78.4432 114.913C77.5815 114.681 76.8985 114.341 76.3941 113.891C75.8932 113.442 75.6428 112.847 75.6428 112.107C75.6428 111.495 75.8109 110.961 76.1472 110.505C76.4834 110.048 76.9388 109.694 77.5132 109.442C78.0877 109.186 78.7357 109.058 79.4572 109.058C80.1858 109.058 80.8285 109.184 81.3855 109.437C81.9459 109.689 82.3872 110.036 82.7095 110.479C83.0317 110.918 83.1999 111.423 83.2139 111.993H81.6482Z" fill="#FCFCFC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M0 72.6631L42.5033 0L85 72.6631H0Z" fill="#1D1E2C"/>
<path fillRule="evenodd" clipRule="evenodd" d="M20.194 54.8853L18.8015 53.898L17.6769 52.6202L17.4219 52.1879L17.18 51.1037L17.4873 50.0325L18.2719 49.2323L19.3441 48.9032H19.8541L21.5409 49.2194L23.0969 49.9228L24.496 50.9102L25.6205 52.1879L25.8755 52.6202L26.1174 53.7044L25.8101 54.7756L25.0256 55.5758L23.9468 55.9049H23.4434L21.7631 55.5887L20.194 54.8853Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M29.4661 54.8853L28.0669 53.898L26.9424 52.6202L26.694 52.1879L26.4455 51.1037L26.7528 50.0325L27.5374 49.2323L28.6161 48.9032H29.1195L30.8063 49.2194L32.3689 49.9228L33.7615 50.9102L34.886 52.1879L35.141 52.6202L35.3895 53.7044L35.0821 54.7756L34.2911 55.5758L33.2188 55.9049H32.7154L31.0286 55.5887L29.4661 54.8853Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M38.729 54.8853L37.3299 53.898L36.2054 52.6202L35.9569 52.1879L35.7085 51.1037L36.0158 50.0325L36.8003 49.2323L37.8791 48.9032H38.3825L40.0693 49.2194L41.6319 49.9228L43.031 50.9102L44.1555 52.1879L44.404 52.6202L44.6524 53.7044L44.3451 54.7756L43.5606 55.5758L42.4818 55.9049H41.9784L40.2916 55.5887L38.729 54.8853Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M40.5922 60.1107L40.3438 59.0201L40.6511 57.9488L41.4356 57.1551L42.5144 56.8195H43.0178L44.7046 57.1422L46.2672 57.8392L47.6597 58.8265L48.7843 60.1107L49.0393 60.5366L49.2877 61.6272L48.9804 62.6985L48.1894 63.4922L47.1171 63.8278H46.6137L44.9269 63.5051L43.3643 62.8081L41.9652 61.8208L40.8407 60.5366L40.5922 60.1107Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.9977 57.8392L38.3968 58.8265L39.5214 60.1107L39.7763 60.5366L40.0183 61.6272L39.711 62.6985L38.9264 63.4922L37.8477 63.8278H37.3442L35.664 63.5051L34.0948 62.8081L32.7023 61.8208L31.5777 60.5366L31.3227 60.1107L31.0808 59.0201L31.3881 57.9488L32.1727 57.1551L33.2449 56.8195H33.7549L35.4417 57.1422L36.9977 57.8392Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.0587 60.1107L21.8102 59.0201L22.1175 57.9488L22.902 57.1551L23.9808 56.8195H24.4843L26.171 57.1422L27.7336 57.8392L29.1327 58.8265L30.2573 60.1107L30.5057 60.5366L30.7541 61.6272L30.4469 62.6985L29.6623 63.4922L28.5835 63.8278H28.0801L26.3933 63.5051L24.8308 62.8081L23.4382 61.8208L22.3071 60.5366L22.0587 60.1107Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.9424 68.4634L26.694 68.031L26.4455 66.9469L26.7528 65.8757L27.5374 65.0755L28.6161 64.7464H29.1195L30.8063 65.0626L32.3689 65.766L33.7615 66.7533L34.886 68.031L35.141 68.4634L35.3895 69.5476L35.0821 70.6188L34.2911 71.419L33.2188 71.7481H32.7154L31.0286 71.4319L29.4661 70.7285L28.0669 69.7411L26.9424 68.4634Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.2054 68.4634L35.9569 68.031L35.7085 66.9469L36.0158 65.8757L36.8003 65.0755L37.8791 64.7464H38.3825L40.0693 65.0626L41.6319 65.766L43.031 66.7533L44.1555 68.031L44.404 68.4634L44.6524 69.5476L44.3451 70.6188L43.5606 71.419L42.4818 71.7481H41.9784L40.2916 71.4319L38.729 70.7285L37.3299 69.7411L36.2054 68.4634Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M53.676 68.4634L53.9179 69.5476L53.6107 70.6188L52.8261 71.419L51.7473 71.7481H51.2439L49.5571 71.4319L47.9945 70.7285L46.6019 69.7411L45.4774 68.4634L45.2225 68.031L44.974 66.9469L45.2879 65.8757L46.0724 65.0755L47.1446 64.7464H47.6546L49.3349 65.0626L50.8974 65.766L52.2965 66.7533L53.4211 68.031L53.676 68.4634Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M49.3083 45.1533L49.8509 46.7602L50.034 48.4445L49.8509 50.1287L49.3083 51.7292L49.0533 52.1615L48.223 52.9166L47.1311 53.1875L46.0393 52.9166L45.209 52.1615L44.954 51.7292L44.4114 50.1287L44.2283 48.4445L44.4114 46.7602L44.954 45.1533L45.209 44.7274L46.0393 43.9724L47.1311 43.6949L48.223 43.9724L49.0533 44.7274L49.3083 45.1533Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M54.6693 40.5266L54.4862 42.2045L53.937 43.8113L53.6886 44.2436L52.8583 44.9987L51.7664 45.2698L50.6746 44.9987L49.8443 44.2436L49.5893 43.8113L49.0467 42.2045L48.8636 40.5266L49.0467 38.8423L49.5893 37.2355L49.8443 36.8032L50.6746 36.0481L51.7664 35.777L52.8583 36.0481L53.6886 36.8032L53.937 37.2355L54.4862 38.8423L54.6693 40.5266Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M59.2995 32.6013L59.1164 34.2856L58.5737 35.886L58.3188 36.3184L57.495 37.0733L56.3966 37.3444L55.3048 37.0733L54.4745 36.3184L54.226 35.886L53.6768 34.2856L53.4938 32.6013L53.6768 30.917L54.226 29.3101L54.4745 28.8843L55.3048 28.1292L56.3966 27.8518L57.495 28.1292L58.3188 28.8843L58.5737 29.3101L59.1164 30.917L59.2995 32.6013Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.9322 40.5266L63.7492 42.2045L63.2065 43.8113L62.9516 44.2436L62.1213 44.9987L61.0294 45.2698L59.9376 44.9987L59.1072 44.2436L58.8523 43.8113L58.3096 42.2045L58.1265 40.5266L58.3096 38.8423L58.8523 37.2355L59.1072 36.8032L59.9376 36.0481L61.0294 35.777L62.1213 36.0481L62.9516 36.8032L63.2065 37.2355L63.7492 38.8423L63.9322 40.5266Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M58.5737 45.1533L59.1164 46.7602L59.2995 48.4445L59.1164 50.1287L58.5737 51.7292L58.3188 52.1615L57.495 52.9166L56.3966 53.1875L55.3048 52.9166L54.4745 52.1615L54.226 51.7292L53.6768 50.1287L53.4938 48.4445L53.6768 46.7602L54.226 45.1533L54.4745 44.7274L55.3048 43.9724L56.3966 43.6949L57.495 43.9724L58.3188 44.7274L58.5737 45.1533Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M48.8636 56.3633L49.0467 54.6855L49.5893 53.0787L49.8443 52.6463L50.6746 51.8913L51.7664 51.6202L52.8583 51.8913L53.6886 52.6463L53.937 53.0787L54.4862 54.6855L54.6693 56.3633L54.4862 58.0476L53.937 59.6545L53.6886 60.0869L52.8583 60.8354L51.7664 61.1129L50.6746 60.8354L49.8443 60.0869L49.5893 59.6545L49.0467 58.0476L48.8636 56.3633Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M59.2995 64.2876L59.1164 65.9654L58.5737 67.5723L58.3188 68.0046L57.495 68.7596L56.3966 69.0307L55.3048 68.7596L54.4745 68.0046L54.226 67.5723L53.6768 65.9654L53.4938 64.2876L53.6768 62.6033L54.226 60.9965L54.4745 60.5641L55.3048 59.8155L56.3966 59.538L57.495 59.8155L58.3188 60.5641L58.5737 60.9965L59.1164 62.6033L59.2995 64.2876Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.9322 56.3621L63.7165 58.169L63.0823 59.8727L62.1409 60.9503L61.6178 61.2278L61.0294 61.3246L60.441 61.2278L59.9179 60.9503L58.9765 59.8727L58.3423 58.169L58.1265 56.3621L58.3423 54.5616L58.9765 52.8516L59.9179 51.7803L60.441 51.4963L61.0294 51.3996L61.6178 51.4963L62.1409 51.7803L63.0823 52.8516L63.7165 54.5616L63.9322 56.3621Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M67.8379 45.1533L68.387 46.7602L68.5701 48.4445L68.387 50.1287L67.8379 51.7292L67.5894 52.1615L66.7591 52.9166L65.6673 53.1875L64.5754 52.9166L63.7451 52.1615L63.4901 51.7292L62.9474 50.1287L62.7644 48.4445L62.9474 46.7602L63.4901 45.1533L63.7451 44.7274L64.5754 43.9724L65.6673 43.6949L66.7591 43.9724L67.5894 44.7274L67.8379 45.1533Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.6769 44.2701L18.8015 42.9859L20.194 42.005L21.7631 41.3016L23.4434 40.979H23.9468L25.0256 41.3146L25.8101 42.1083L26.1174 43.1796L25.8755 44.2701L25.6205 44.696L24.496 45.9802L23.0969 46.9676L21.5409 47.6645L19.8541 47.9872H19.3441L18.2719 47.6516L17.4873 46.8579L17.18 45.7866L17.4219 44.696L17.6769 44.2701Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.9424 44.2701L28.0669 42.9859L29.4661 42.005L31.0286 41.3016L32.7154 40.979H33.2188L34.2911 41.3146L35.0821 42.1083L35.3895 43.1796L35.141 44.2701L34.886 44.696L33.7615 45.9802L32.3689 46.9676L30.8063 47.6645L29.1195 47.9872H28.6161L27.5374 47.6516L26.7528 46.8579L26.4455 45.7866L26.694 44.696L26.9424 44.2701Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M42.4818 40.979L43.5606 41.3146L44.3451 42.1083L44.6524 43.1796L44.404 44.2701L44.1555 44.696L43.031 45.9802L41.6319 46.9676L40.0693 47.6645L38.3825 47.9872H37.8791L36.8003 47.6516L36.0158 46.8579L35.7085 45.7866L35.9569 44.696L36.2054 44.2701L37.3299 42.9859L38.729 42.005L40.2916 41.3016L41.9784 40.979H42.4818Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M48.7843 36.7783L47.6597 38.056L46.2672 39.0433L44.7046 39.7467L43.0178 40.0694H42.5144L41.4356 39.7338L40.6511 38.9336L40.3438 37.8624L40.5922 36.7783L40.8407 36.3459L41.9652 35.0681L43.3643 34.0808L44.9269 33.3839L46.6137 33.0612H47.1171L48.1894 33.3903L48.9804 34.1905L49.2877 35.2618L49.0393 36.3459L48.7843 36.7783Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M37.8477 33.0612L38.9264 33.3903L39.711 34.1905L40.0183 35.2618L39.7763 36.3459L39.5214 36.7783L38.3968 38.056L36.9977 39.0433L35.4417 39.7467L33.7549 40.0694H33.2449L32.1727 39.7338L31.3881 38.9336L31.0808 37.8624L31.3227 36.7783L31.5777 36.3459L32.7023 35.0681L34.0948 34.0808L35.664 33.3839L37.3442 33.0612H37.8477Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.3071 36.3459L23.4382 35.0681L24.8308 34.0808L26.3933 33.3839L28.0801 33.0612H28.5835L29.6623 33.3903L30.4469 34.1905L30.7541 35.2618L30.5057 36.3459L30.2573 36.7783L29.1327 38.056L27.7336 39.0433L26.171 39.7467L24.4843 40.0694H23.9808L22.902 39.7338L22.1175 38.9336L21.8102 37.8624L22.0587 36.7783L22.3071 36.3459Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.6161 32.144L27.5374 31.8149L26.7528 31.0147L26.4455 29.9435L26.694 28.8594L26.9424 28.427L28.0669 27.1493L29.4661 26.1619L31.0286 25.4585L32.7154 25.1359H33.2188L34.2911 25.4714L35.0821 26.2652L35.3895 27.3429L35.141 28.427L34.886 28.8594L33.7615 30.1371L32.3689 31.1245L30.8063 31.8214L29.1195 32.144H28.6161Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M44.1555 28.8594L43.031 30.1371L41.6319 31.1245L40.0693 31.8214L38.3825 32.144H37.8791L36.8003 31.8149L36.0158 31.0147L35.7085 29.9435L35.9569 28.8594L36.2054 28.427L37.3299 27.1493L38.729 26.1619L40.2916 25.4585L41.9784 25.1359H42.4818L43.5606 25.4714L44.3451 26.2652L44.6524 27.3429L44.404 28.427L44.1555 28.8594Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M50.8973 31.123L49.217 31.8652L47.3929 32.1491L45.9808 31.8845L45.4773 31.5747L45.0916 31.123L44.8889 30.5681L44.8693 29.9808L45.34 28.6386L46.5038 27.2253L47.9944 26.1605L49.6812 25.4184L51.4988 25.128L52.9175 25.399L53.421 25.7088L53.8067 26.1605L54.0093 26.7091L54.029 27.2962L53.5517 28.6386L52.3945 30.0518L50.8973 31.123Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.3367 55.1048L14.2331 50.1616L15.4033 51.065L16.3579 52.1879L16.6063 52.6202L16.8548 53.7044L16.5475 54.7756L15.7629 55.5758L14.6842 55.9049H14.1808L12.7163 55.6533L11.3367 55.1048Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.2088 68.9216L63.7188 70.2961L63.9214 71.7481H58.1419L58.3511 70.2961L58.8545 68.9216L59.1095 68.4893L59.9398 67.7342L61.0316 67.4631L62.1235 67.7342L62.9538 68.4893L63.2088 68.9216Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M52.2375 18.4765L55.1273 23.4196L53.7479 23.9682L52.2833 24.2262H51.7799L50.7011 23.8907L49.9166 23.0969L49.6093 22.0258L49.8578 20.9351L50.1062 20.5093L51.0673 19.38L52.2375 18.4765Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M31.3227 20.9351L31.3816 20.8383L40.842 4.66014L41.7966 3.53729L42.9668 2.63383L46.5954 8.84181L39.5736 20.8383L39.5214 20.9351L38.3968 22.2193L36.9977 23.2002L35.4417 23.9036L33.7549 24.2262H33.2449L32.1727 23.8907L31.3881 23.0969L31.0808 22.0258L31.3227 20.9351Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M40.5922 20.9351L40.6445 20.8383L45.4761 12.5847L46.4306 11.4619L47.6009 10.5584L50.4907 15.5016L51.2295 16.7599L48.8431 20.8383L48.7843 20.9351L47.6597 22.2193L46.2672 23.2002L44.7046 23.9036L43.0178 24.2262H42.5144L41.4356 23.8907L40.6511 23.0969L40.3438 22.0258L40.5922 20.9351Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M68.1243 53.079L68.3727 52.6466L69.203 51.8916L70.2949 51.6206L71.3933 51.8916L72.2171 52.6466L81.7363 68.9216L82.2463 70.2961L82.4555 71.7481H75.1984L68.1243 59.6548L67.5751 58.0479L67.3921 56.3637L67.5751 54.6858L68.1243 53.079Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.0712 70.9479L2.48964 70.2251L5.69976 64.7464H19.8545L21.5413 65.0626L23.0973 65.766L24.4964 66.7533L25.6209 68.031L25.8759 68.4634L26.1178 69.5476L25.8105 70.6188L25.026 71.419L23.9472 71.7481H4.91524L3.4507 71.4964L2.0712 70.9479Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.3351 56.8195H15.2189L16.9057 57.1422L18.4683 57.8392L19.8609 58.8265L20.9854 60.1107L21.2404 60.5366L21.4888 61.6272L21.1815 62.6985L20.3905 63.4922L19.3182 63.8278H9.54397L8.08601 63.5696L6.70652 63.0211L10.3351 56.8195Z" fill="#5B7CFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.4901 60.9971L63.7451 60.5647L64.5754 59.8161L65.6673 59.5387L66.7591 59.8161L67.5894 60.5647L67.8379 60.9971L72.4733 68.9216L72.9832 70.2961L73.1925 71.7481H65.9353L63.7451 68.0053L63.4901 67.5729L62.9474 65.966L62.7644 64.2882L62.9474 62.6039L63.4901 60.9971Z" fill="#EBEFFF"/>
</svg>

   );
 };