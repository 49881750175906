export const Python = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8868 0.015625C13.6492 0.015626 14.5383 6.18994 14.5383 6.18994L14.5541 12.5865H28.1408V14.507H9.15756C9.15756 14.507 0.046875 13.4738 0.046875 27.8397C0.046874 42.2057 7.99889 41.6962 7.99889 41.6962H12.7447V35.0299C12.7447 35.0299 12.4889 27.0779 20.5697 27.0779H34.0453C34.0453 27.0779 41.6164 27.2002 41.6164 19.7607V7.45972C41.6164 7.45972 42.7659 0.015625 27.8868 0.015625ZM20.3951 4.31701C21.7469 4.31701 22.8395 5.40957 22.8395 6.76134C22.8395 8.11312 21.7469 9.20567 20.3951 9.20567C19.0434 9.20567 17.9508 8.11312 17.9508 6.76134C17.9508 5.40957 19.0434 4.31701 20.3951 4.31701Z"
        fill="url(#paint0_linear_2470_5956)"
      />
      <path
        d="M28.2911 55.8078C42.5288 55.8078 41.6397 49.6335 41.6397 49.6335L41.6238 43.237H28.0372V41.3165H47.0204C47.0204 41.3165 56.1311 42.3497 56.1311 27.9837C56.1311 13.6178 48.1791 14.1272 48.1791 14.1272H43.4333V20.7936C43.4333 20.7936 43.6891 28.7456 35.6082 28.7456H22.1327C22.1327 28.7456 14.5616 28.6232 14.5616 36.0627V48.3637C14.5616 48.3637 13.4121 55.8078 28.2911 55.8078ZM35.7828 51.5065C34.4311 51.5065 33.3385 50.4139 33.3385 49.0621C33.3385 47.7104 34.4311 46.6178 35.7828 46.6178C37.1346 46.6178 38.2272 47.7104 38.2272 49.0621C38.2272 50.4139 37.1346 51.5065 35.7828 51.5065Z"
        fill="url(#paint1_linear_2470_5956)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2470_5956"
          x1="5.43668"
          y1="5.03399"
          x2="33.23"
          y2="32.55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#387EB8" />
          <stop offset="1" stop-color="#366994" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2470_5956"
          x1="22.4962"
          y1="22.703"
          x2="52.3433"
          y2="50.9183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE052" />
          <stop offset="1" stop-color="#FFC331" />
        </linearGradient>
      </defs>
    </svg>
  );
};
