export const BlueLine = () => {
  return (
    <svg
      width="171"
      height="62"
      viewBox="0 0 171 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 93V154.368C0 171.838 14.1621 186 31.632 186C53.7765 186 69.0672 163.832 61.201 143.132L50.4159 114.75C37.3418 80.3447 62.756 43.5 99.5617 43.5H189.25C201.262 43.5 211 33.7622 211 21.75C211 9.73781 201.262 0 189.25 0H93C41.6375 0 0 41.6375 0 93Z"
        fill="#5B7CFE"
      />
    </svg>
  );
};
