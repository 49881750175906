export const Logo = () => {
  return (
    <svg width="238" height="287" viewBox="0 0 238 287" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.05" filter="url(#filter0_f_2514_513)">
<path fillRule="evenodd" clipRule="evenodd" d="M-84.0291 279.814L76.157 5.62655L232.702 281.895L-84.0291 279.814Z" fill="#1D1E2C"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-8.33862 212.971L-13.5032 209.197L-17.6617 204.33L-18.6011 202.686L-19.4755 198.574L-18.3038 194.524L-15.3604 191.512L-11.3569 190.292L-9.45663 190.304L-3.17904 191.543L2.60164 194.246L7.79042 198.02L11.949 202.887L12.8884 204.531L13.7628 208.643L12.591 212.693L9.64764 215.705L5.61979 216.925L3.74386 216.913L-2.50932 215.674L-8.33862 212.971Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.2112 213.198L21.0223 209.424L16.8639 204.557L15.9488 202.913L15.0501 198.801L16.2217 194.751L19.1651 191.739L23.193 190.519L25.0688 190.531L31.3464 191.77L37.1515 194.473L42.316 198.247L46.4744 203.114L47.4138 204.757L48.3126 208.87L47.1409 212.92L44.1732 215.932L40.1696 217.152L38.2937 217.14L32.0163 215.901L26.2112 213.198Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M60.7273 213.425L55.5383 209.651L51.3799 204.784L50.4649 203.14L49.5661 199.027L50.7377 194.978L53.6811 191.966L57.7091 190.746L59.5851 190.758L65.8625 191.997L71.6675 194.699L76.8565 198.473L81.0149 203.341L81.9299 204.984L82.8287 209.097L81.6571 213.147L78.7137 216.159L74.6857 217.379L72.8099 217.367L66.5324 216.128L60.7273 213.425Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M67.5399 233.263L66.6414 229.126L67.8131 225.076L70.7563 222.089L74.7843 220.844L76.6602 220.856L82.9376 222.12L88.7428 224.798L93.9073 228.572L98.0656 233.464L99.0052 235.083L99.9038 239.22L98.7321 243.27L95.7646 246.257L91.7608 247.502L89.8849 247.49L83.6075 246.226L77.8024 243.548L72.6135 239.774L68.4551 234.882L67.5399 233.263Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M54.2024 224.571L59.3913 228.345L63.5497 233.237L64.4891 234.856L65.3635 238.993L64.1918 243.043L61.2485 246.03L57.2205 247.275L55.3446 247.263L49.0916 245.999L43.262 243.321L38.0975 239.547L33.9392 234.655L32.9996 233.036L32.1254 228.899L33.2971 224.849L36.2403 221.862L40.244 220.617L42.1443 220.629L48.4217 221.893L54.2024 224.571Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-1.52064 232.809L-2.41926 228.672L-1.24751 224.622L1.69557 221.635L5.72372 220.39L7.59964 220.402L13.8769 221.666L19.6821 224.344L24.8711 228.118L29.0293 233.01L29.9445 234.629L30.8431 238.766L29.6715 242.816L26.7283 245.804L22.7001 247.048L20.8244 247.036L14.5469 245.772L8.74172 243.094L3.57716 239.32L-0.60546 234.428L-1.52064 232.809Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.4696 264.567L15.5546 262.923L14.6558 258.81L15.8274 254.76L18.7708 251.749L22.7988 250.528L24.6745 250.541L30.9521 251.78L36.7572 254.482L41.9217 258.256L46.0801 263.123L47.0195 264.767L47.9183 268.88L46.7466 272.93L43.7789 275.941L39.7753 277.162L37.8994 277.149L31.622 275.91L25.8169 273.208L20.628 269.434L16.4696 264.567Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M50.9856 264.793L50.0706 263.149L49.1718 259.037L50.3435 254.987L53.2868 251.975L57.3148 250.755L59.1908 250.767L65.4682 252.006L71.2733 254.709L76.4622 258.483L80.6206 263.35L81.5356 264.994L82.4344 269.107L81.2628 273.157L78.3194 276.168L74.2914 277.388L72.4156 277.376L66.1381 276.137L60.333 273.435L55.144 269.661L50.9856 264.793Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M116.086 265.221L116.96 269.334L115.788 273.383L112.845 276.395L108.817 277.615L106.941 277.603L100.664 276.364L94.8586 273.662L89.694 269.888L85.5356 265.02L84.5962 263.376L83.6975 259.264L84.8936 255.214L87.8368 252.202L91.8404 250.982L93.7407 250.994L99.9939 252.233L105.799 254.936L110.988 258.71L115.146 263.577L116.086 265.221Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M100.39 176.822L102.372 182.921L103.013 189.305L102.289 195.681L100.227 201.729L99.2658 203.361L96.1531 206.2L92.0778 207.2L88.0161 206.147L84.9409 203.267L84.0017 201.623L82.0194 195.547L81.3791 189.163L82.1032 182.788L84.1653 176.715L85.1259 175.108L88.2387 172.269L92.3141 171.244L96.3757 172.322L99.4508 175.202L100.39 176.822Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M120.482 159.428L119.758 165.779L117.672 171.852L116.735 173.483L113.622 176.323L109.547 177.323L105.486 176.27L102.41 173.389L101.471 171.746L99.489 165.646L98.8485 159.286L99.5726 152.911L101.635 146.838L102.595 145.207L105.708 142.367L109.783 141.367L113.845 142.421L116.92 145.301L117.835 146.944L119.842 153.044L120.482 159.428Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M137.933 129.523L137.208 135.898L135.146 141.946L134.186 143.578L131.097 146.417L126.998 147.417L122.936 146.364L119.861 143.484L118.946 141.84L116.939 135.765L116.299 129.381L117.023 123.006L119.109 116.932L120.046 115.325L123.158 112.486L127.234 111.462L131.32 112.539L134.371 115.42L135.31 117.039L137.292 123.139L137.933 129.523Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M154.998 159.655L154.274 166.006L152.212 172.079L151.251 173.71L148.139 176.55L144.063 177.55L140.002 176.496L136.926 173.616L135.987 171.972L134.005 165.873L133.365 159.513L134.089 153.138L136.151 147.065L137.112 145.433L140.224 142.594L144.3 141.594L148.361 142.647L151.436 145.528L152.376 147.171L154.358 153.271L154.998 159.655Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M134.916 177.049L136.898 183.148L137.538 189.532L136.814 195.907L134.752 201.956L133.791 203.588L130.703 206.427L126.603 207.427L122.542 206.374L119.467 203.493L118.552 201.85L116.545 195.774L115.905 189.39L116.629 183.015L118.715 176.942L119.652 175.335L122.764 172.496L126.84 171.471L130.926 172.549L133.976 175.429L134.916 177.049Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.4544 219.271L99.1783 212.921L101.24 206.848L102.201 205.216L105.314 202.377L109.389 201.377L113.451 202.43L116.526 205.31L117.441 206.954L119.447 213.054L120.088 219.414L119.364 225.789L117.277 231.862L116.341 233.493L113.228 236.308L109.153 237.333L105.091 236.255L102.016 233.399L101.077 231.755L99.0947 225.655L98.4544 219.271Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M137.144 249.542L136.42 255.892L134.358 261.965L133.397 263.597L130.309 266.437L126.209 267.436L122.147 266.383L119.072 263.503L118.157 261.859L116.151 255.759L115.51 249.4L116.234 243.025L118.321 236.952L119.257 235.32L122.37 232.505L126.445 231.481L130.531 232.559L133.582 235.414L134.522 237.058L136.504 243.158L137.144 249.542Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M154.604 219.636L153.755 226.474L151.35 232.912L147.815 236.971L145.859 238.009L143.664 238.361L141.474 237.98L139.531 236.916L136.05 232.811L133.729 226.343L132.97 219.493L133.819 212.679L136.225 206.217L139.76 202.183L141.716 201.12L143.911 200.768L146.101 201.149L148.043 202.237L151.524 206.318L153.845 212.811L154.604 219.636Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M169.436 177.275L171.443 183.375L172.083 189.759L171.359 196.134L169.273 202.183L168.336 203.814L165.223 206.654L161.148 207.654L157.086 206.601L154.011 203.72L153.072 202.077L151.089 196.001L150.449 189.617L151.173 183.242L153.235 177.169L154.196 175.562L157.309 172.723L161.384 171.698L165.446 172.776L168.521 175.656L169.436 177.275Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-17.4539 172.702L-13.2316 167.865L-8.01807 164.184L-2.15376 161.558L4.11533 160.377L5.99126 160.39L10.0026 161.687L12.9063 164.713L14.0247 168.778L13.0962 172.903L12.1354 174.51L7.91312 179.346L2.67519 183.052L-3.14034 185.654L-9.43383 186.834L-11.3341 186.822L-15.321 185.525L-18.2248 182.499L-19.3432 178.434L-18.4147 174.309L-17.4539 172.702Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.0717 172.929L21.2938 168.092L26.5318 164.411L32.3718 161.785L38.6652 160.604L40.5411 160.617L44.5282 161.914L47.4561 164.94L48.5745 169.005L47.6216 173.13L46.6609 174.737L42.4387 179.573L37.225 183.279L31.3851 185.88L25.0916 187.061L23.2158 187.049L19.2044 185.752L16.3007 182.726L15.1824 178.661L16.1353 174.536L17.0717 172.929Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M75.0572 160.843L79.0686 162.141L81.9723 165.167L83.0906 169.232L82.1377 173.356L81.2013 174.964L76.9792 179.8L71.7411 183.506L65.9012 186.107L59.6079 187.288L57.7319 187.276L53.7205 185.978L50.8168 182.953L49.6985 178.887L50.6514 174.763L51.5877 173.156L55.8099 168.319L61.0478 164.638L66.8879 162.012L73.1814 160.831L75.0572 160.843Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.6463 145.086L94.4242 149.899L89.2106 153.604L83.3706 156.23L77.0771 157.411L75.2011 157.399L71.1898 156.101L68.2863 153.051L67.168 148.986L68.1206 144.886L69.0571 143.254L73.2793 138.442L78.5174 134.736L84.3571 132.135L90.6506 130.954L92.5265 130.966L96.5138 132.239L99.4416 135.29L100.56 139.355L99.6072 143.455L98.6463 145.086Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M57.9862 130.74L61.9977 132.013L64.9012 135.063L66.0197 139.128L65.0912 143.228L64.1304 144.86L59.9082 149.672L54.6702 153.377L48.8546 156.004L42.5611 157.184L40.6608 157.172L36.6739 155.875L33.7704 152.824L32.6519 148.759L33.5803 144.659L34.5412 143.027L38.7633 138.215L43.9769 134.51L49.8412 131.908L56.1103 130.727L57.9862 130.74Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-0.00342192 142.8L4.24295 137.988L9.45666 134.283L15.2966 131.681L21.5901 130.5L23.4658 130.513L27.4774 131.786L30.381 134.836L31.4993 138.901L30.5465 143.001L29.61 144.633L25.388 149.445L20.1499 153.151L14.3098 155.777L8.01651 156.957L6.14058 156.945L2.12914 155.648L-0.774275 152.597L-1.89271 148.532L-0.93996 144.432L-0.00342192 142.8Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.6101 127.039L19.5985 125.766L16.695 122.716L15.5767 118.651L16.5294 114.551L17.466 112.919L21.688 108.107L26.926 104.402L32.7661 101.776L39.0595 100.595L40.9354 100.607L44.9224 101.904L47.8504 104.93L48.9687 109.02L48.0159 113.12L47.055 114.752L42.833 119.564L37.6193 123.269L31.7794 125.871L25.4859 127.052L23.6101 127.039Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M81.5955 114.979L77.3735 119.791L72.1354 123.496L66.2955 126.098L60.0021 127.279L58.1262 127.266L54.1146 125.993L51.211 122.943L50.0928 118.878L51.0455 114.778L51.982 113.146L56.204 108.334L61.4421 104.628L67.2822 102.002L73.5757 100.822L75.4515 100.834L79.4629 102.131L82.3666 105.157L83.4847 109.247L82.532 113.347L81.5955 114.979Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M106.661 123.718L100.381 126.488L93.577 127.518L88.3215 126.482L86.4532 125.296L85.0271 123.576L84.2857 121.469L84.2273 119.244L86.0146 114.171L90.3863 108.846L95.9673 104.85L102.271 102.08L109.051 101.025L114.331 102.086L116.199 103.272L117.625 104.992L118.367 107.075L118.425 109.3L116.613 114.372L112.266 119.697L106.661 123.718Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-41.3487 213.585L-30.4331 194.933L-26.0949 198.384L-22.5659 202.66L-21.651 204.304L-20.7523 208.416L-21.9239 212.466L-24.8673 215.478L-28.8951 216.698L-30.771 216.686L-36.2218 215.697L-41.3487 213.585Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M151.596 267.19L153.462 272.409L154.181 277.913L132.645 277.772L133.46 272.277L135.371 267.083L136.331 265.452L139.444 262.612L143.519 261.612L147.581 262.666L150.656 265.546L151.596 267.19Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M111.97 75.8488L122.615 94.6427L117.461 96.6869L111.997 97.6285L110.121 97.6162L106.11 96.319L103.206 93.293L102.088 89.2282L103.041 85.1032L103.977 83.4963L107.586 79.2423L111.97 75.8488Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M33.9746 84.6494L34.1963 84.2842L69.8508 23.2373L73.4356 19.0076L77.8188 15.6141L91.1854 39.2171L64.7219 84.4847L64.5247 84.8502L60.3024 89.6868L55.0644 93.3679L49.2489 95.994L42.9554 97.1748L41.0551 97.1624L37.0682 95.8653L34.1645 92.8394L33.046 88.7745L33.9746 84.6494Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M68.5149 84.8764L68.7122 84.5109L86.9212 53.3668L90.5061 49.1371L94.8893 45.7437L105.534 64.5379L108.256 69.3222L99.2623 84.7117L99.0406 85.0769L94.8183 89.9135L89.6049 93.5948L83.7648 96.2208L77.4714 97.4016L75.5954 97.3893L71.5841 96.0921L68.6804 93.0662L67.5621 89.0013L68.5149 84.8764Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M170.306 207.303L171.243 205.671L174.355 202.832L178.431 201.832L182.517 202.885L185.568 205.765L220.634 267.643L222.5 272.862L223.243 278.367L196.201 278.189L170.143 232.21L168.136 226.11L167.496 219.726L168.22 213.376L170.306 207.303Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-76.2686 273.368L-74.6914 270.641L-62.5933 249.967L-9.84943 250.314L-3.57184 251.553L2.20883 254.255L7.39762 258.029L11.5562 262.896L12.4956 264.54L13.37 268.653L12.1982 272.703L9.25483 275.714L5.227 276.935L-65.6909 276.469L-71.1419 275.48L-76.2686 273.368Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M-45.1237 220.056L-26.9254 220.176L-20.6479 221.439L-14.8427 224.117L-9.67815 227.891L-5.51992 232.783L-4.58036 234.402L-3.68173 238.539L-4.85349 242.589L-7.82095 245.577L-11.8247 246.821L-48.246 246.582L-53.6723 245.569L-58.799 243.457L-45.1237 220.056Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M152.841 237.181L153.802 235.549L156.915 232.734L160.99 231.71L165.052 232.788L168.127 235.644L169.042 237.287L186.117 267.417L187.983 272.636L188.727 278.14L161.685 277.963L153.617 263.732L152.677 262.088L150.695 255.989L150.055 249.629L150.779 243.254L152.841 237.181Z" fill="#EBEFFF"/>
</g>
<defs>
<filter id="filter0_f_2514_513" x="-89.0293" y="0.626953" width="326.731" height="286.269" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_2514_513"/>
</filter>
</defs>
</svg>
  );
};
