export const Ionic = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7173 0.00025935C33.2623 0.00025935 37.5419 1.06213 41.5561 3.119L42.0869 3.38441L41.6225 3.74934C40.4945 4.64507 39.5988 5.80621 39.0348 7.13322L38.8689 7.49815L38.5372 7.33227C35.4519 5.87256 32.1675 5.10953 28.7504 5.10953C16.1106 5.10953 5.85944 15.3939 5.85944 28.0005C5.85944 40.6072 16.0774 50.8915 28.7173 50.8915C41.3571 50.8915 51.6083 40.6072 51.6083 28.0005C51.6083 24.9816 51.0443 22.029 49.85 19.2422L49.6841 18.8773L50.049 18.7114C51.4092 18.2138 52.6035 17.3844 53.5656 16.3228L53.9305 15.8583L54.1628 16.3891C55.8547 20.0716 56.6841 23.9863 56.6841 27.9342C56.6841 43.3607 44.1106 55.9342 28.6841 55.9342C13.2575 55.9342 0.684082 43.3607 0.684082 27.9342C0.684082 12.5076 13.2575 -0.06583 28.7173 0.00025935ZM28.7173 15.2612C35.7173 15.2612 41.4566 20.9673 41.4898 28.0337C41.4898 35.1 35.7836 40.8062 28.7173 40.8062C21.6509 40.8062 15.9447 35.1 15.9447 28.0337C15.9447 20.9673 21.6841 15.2612 28.7173 15.2612ZM46.9969 4.71142C50.2216 4.71142 52.8357 7.32557 52.8357 10.5503C52.8357 13.775 50.2216 16.3891 46.9969 16.3891C43.7722 16.3891 41.158 13.775 41.158 10.5503C41.158 7.32557 43.7722 4.71142 46.9969 4.71142Z"
        fill="#3880FF"
      />
    </svg>
  );
};
