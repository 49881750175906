export const PeoplesIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7996 9.12402C13.6445 9.75529 14.3996 11.3447 14.3996 12.324C14.3996 12.6291 14.177 12.8764 13.9024 12.8764H13.5996M10.3996 6.53654C10.9462 6.22037 11.3139 5.62942 11.3139 4.9526C11.3139 4.27577 10.9462 3.68482 10.3996 3.36865M2.09679 12.8764H10.8548C11.1294 12.8764 11.352 12.6291 11.352 12.324C11.352 10.4062 9.74981 8.85161 6.4758 8.85161C3.20179 8.85161 1.59961 10.4062 1.59961 12.324C1.59961 12.6291 1.8222 12.8764 2.09679 12.8764ZM8.30437 4.95259C8.30437 5.96249 7.48569 6.78117 6.4758 6.78117C5.46591 6.78117 4.64723 5.96249 4.64723 4.95259C4.64723 3.9427 5.46591 3.12402 6.4758 3.12402C7.48569 3.12402 8.30437 3.9427 8.30437 4.95259Z"
        stroke="#8E8E8E"
        strokeLinecap="round"
      />
    </svg>
  );
};
