export const AfterEffects = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 4.51369e-06H46.8333C48.136 -0.0012547 49.426 0.260965 50.6297 0.771657C51.8334 1.28235 52.9271 2.03149 53.8482 2.9762C54.7693 3.92091 55.4997 5.04264 55.9977 6.27721C56.4956 7.51177 56.7512 8.83492 56.75 10.1709V45.8291C56.7512 47.1651 56.4956 48.4882 55.9977 49.7228C55.4997 50.9574 54.7693 52.0791 53.8482 53.0238C52.9271 53.9685 51.8334 54.7177 50.6297 55.2284C49.426 55.739 48.136 56.0013 46.8333 56H10.6667C9.36405 56.0013 8.07398 55.739 6.87028 55.2284C5.66658 54.7177 4.57288 53.9685 3.65179 53.0238C2.7307 52.0791 2.00029 50.9574 1.50237 49.7228C1.00444 48.4882 0.748777 47.1651 0.750004 45.8291V10.1709C0.748777 8.83492 1.00444 7.51177 1.50237 6.27721C2.00029 5.04264 2.7307 3.92091 3.65179 2.9762C4.57288 2.03149 5.66658 1.28235 6.87028 0.771657C8.07398 0.260965 9.36405 -0.0012547 10.6667 4.51369e-06Z"
        fill="#00005B"
      />
      <path
        d="M23.2433 33.5047H14.5633L12.79 39.1525C12.7701 39.255 12.7137 39.3462 12.6317 39.4082C12.5496 39.4702 12.448 39.4985 12.3466 39.4876H7.95996C7.7033 39.4876 7.6333 39.344 7.7033 39.0568L15.2166 16.9679C15.2866 16.7286 15.3566 16.5132 15.45 16.226C15.5439 15.7131 15.5907 15.1923 15.59 14.6705C15.583 14.6105 15.5983 14.5501 15.6329 14.5013C15.6676 14.4525 15.7189 14.4189 15.7766 14.4072H21.82C21.9833 14.4072 22.1 14.479 22.1233 14.5987L30.64 39.1286C30.71 39.3918 30.64 39.5115 30.4066 39.5115H25.53C25.4475 39.5196 25.3649 39.4977 25.2966 39.4496C25.2283 39.4014 25.1787 39.3301 25.1566 39.2482L23.2433 33.5047ZM15.9166 28.7423H21.8433C21.7033 28.2397 21.5166 27.6414 21.3066 27.0192C21.0966 26.397 20.8866 25.679 20.6766 24.9611C20.4666 24.2431 20.2333 23.5012 20 22.7594C19.7666 22.0175 19.5566 21.3235 19.37 20.6294C19.1833 19.9354 19.02 19.3371 18.8566 18.7628H18.81C18.6003 19.8058 18.3354 20.8364 18.0166 21.85C17.6666 22.9987 17.3166 24.1953 16.9433 25.3918C16.64 26.6124 16.2666 27.7371 15.9166 28.7423Z"
        fill="#9999FF"
      />
      <path
        d="M44.4766 31.3034H37.0799C37.1682 32.0586 37.415 32.7853 37.8033 33.4333C38.2156 34.0783 38.8098 34.5792 39.5066 34.8692C40.4497 35.2829 41.4677 35.4868 42.4933 35.4675C43.3081 35.4522 44.12 35.3641 44.9199 35.2043C45.6315 35.1087 46.329 34.9238 46.9966 34.6538C47.1133 34.5581 47.1833 34.606 47.1833 34.8453V38.5068C47.1792 38.6041 47.1635 38.7006 47.1366 38.794C47.0944 38.8611 47.0387 38.9183 46.9733 38.9615C46.227 39.2976 45.4434 39.5387 44.6399 39.6795C43.5485 39.8947 42.4378 39.991 41.3266 39.9666C39.5533 39.9666 38.0599 39.6795 36.8466 39.129C35.7049 38.6208 34.6972 37.8415 33.9066 36.8555C33.1679 35.9336 32.62 34.8668 32.2966 33.7205C31.6129 31.333 31.6454 28.7897 32.3899 26.4213C32.762 25.2231 33.3563 24.1098 34.1399 23.1427C34.9116 22.1864 35.8744 21.4111 36.9633 20.8692C38.0599 20.3188 39.3666 20.1273 40.8599 20.1273C42.1041 20.0977 43.3392 20.3511 44.4766 20.8692C45.4325 21.2942 46.2733 21.9513 46.9266 22.7837C47.5468 23.6011 48.0208 24.5248 48.3266 25.5119C48.6233 26.4552 48.773 27.4405 48.7699 28.4316C48.7699 29.006 48.7466 29.5085 48.7233 29.9632C48.6999 30.4179 48.6533 30.753 48.6299 30.9684C48.6197 31.0512 48.5813 31.1278 48.5216 31.1847C48.4619 31.2415 48.3846 31.2751 48.3033 31.2795C48.1633 31.2795 47.9066 31.3034 47.5333 31.3273C47.084 31.3796 46.6321 31.4036 46.1799 31.3991C45.6433 31.3991 45.0833 31.3034 44.4766 31.3034ZM37.0799 27.8094H42.0033C42.6099 27.8094 43.0533 27.8094 43.3333 27.7854C43.525 27.7691 43.7097 27.7032 43.8699 27.594V27.3547C43.8709 27.0536 43.8236 26.7545 43.7299 26.4692C43.5257 25.8144 43.1193 25.2454 42.5721 24.8483C42.0249 24.4511 41.3667 24.2473 40.6966 24.2675C40.0634 24.2311 39.4334 24.3834 38.8827 24.7062C38.332 25.0289 37.8842 25.5082 37.5933 26.0863C37.3211 26.6237 37.1471 27.2077 37.0799 27.8094Z"
        fill="#9999FF"
      />
    </svg>
  );
};
