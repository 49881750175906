
export const AboutUsLogo = () => {
   return (
<svg width="580" height="504" viewBox="0 0 580 504" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.15" filter="url(#filter0_f_2388_5701)">
<path fillRule="evenodd" clipRule="evenodd" d="M5.00106 507.989L298.329 5.90554L584.989 511.8L5.00106 507.989Z" fill="#1D1E2C"/>
<path fillRule="evenodd" clipRule="evenodd" d="M143.603 385.588L134.146 378.678L126.531 369.765L124.811 366.755L123.209 359.224L125.355 351.808L130.745 346.293L138.076 344.058L141.556 344.081L153.051 346.35L163.636 351.299L173.138 358.21L180.753 367.122L182.473 370.132L184.074 377.663L181.929 385.079L176.539 390.594L169.163 392.829L165.728 392.806L154.277 390.537L143.603 385.588Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M206.869 386.004L197.368 379.093L189.753 370.18L188.077 367.17L186.432 359.639L188.577 352.223L193.967 346.708L201.343 344.474L204.778 344.497L216.273 346.766L226.903 351.714L236.36 358.625L243.975 367.538L245.695 370.548L247.341 378.078L245.195 385.495L239.761 391.009L232.43 393.244L228.995 393.222L217.499 390.953L206.869 386.004Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M270.074 386.419L260.572 379.508L252.958 370.595L251.282 367.586L249.636 360.055L251.782 352.639L257.171 347.123L264.547 344.889L267.983 344.912L279.478 347.181L290.108 352.129L299.609 359.04L307.224 367.953L308.9 370.963L310.546 378.494L308.4 385.91L303.01 391.425L295.634 393.659L292.2 393.637L280.704 391.368L270.074 386.419Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M282.549 422.746L280.904 415.17L283.049 407.754L288.439 402.284L295.815 400.004L299.25 400.027L310.745 402.341L321.375 407.245L330.832 414.156L338.447 423.113L340.167 426.078L341.813 433.654L339.667 441.07L334.233 446.54L326.902 448.82L323.467 448.797L311.972 446.484L301.342 441.579L291.84 434.668L284.225 425.711L282.549 422.746Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M258.126 406.829L267.628 413.74L275.242 422.698L276.963 425.663L278.564 433.238L276.418 440.655L271.029 446.125L263.653 448.404L260.218 448.382L248.767 446.068L238.092 441.164L228.635 434.253L221.021 425.295L219.3 422.33L217.699 414.755L219.845 407.338L225.234 401.868L232.566 399.589L236.046 399.612L247.541 401.925L258.126 406.829Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M156.088 421.915L154.442 414.339L156.588 406.923L161.977 401.453L169.353 399.173L172.789 399.196L184.283 401.51L194.914 406.414L204.415 413.325L212.03 422.283L213.706 425.247L215.351 432.823L213.206 440.24L207.816 445.71L200.44 447.989L197.005 447.966L185.51 445.653L174.88 440.748L165.423 433.838L157.764 424.88L156.088 421.915Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M189.031 480.068L187.355 477.058L185.71 469.527L187.855 462.111L193.245 456.596L200.621 454.362L204.056 454.384L215.551 456.653L226.181 461.602L235.638 468.512L243.253 477.425L244.973 480.436L246.619 487.966L244.473 495.382L239.039 500.897L231.708 503.132L228.273 503.109L216.777 500.84L206.147 495.892L196.646 488.981L189.031 480.068Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M252.236 480.483L250.56 477.473L248.914 469.943L251.06 462.526L256.449 457.011L263.825 454.777L267.261 454.8L278.756 457.068L289.386 462.017L298.887 468.928L306.502 477.841L308.178 480.851L309.824 488.382L307.678 495.798L302.288 501.313L294.912 503.547L291.477 503.524L279.982 501.256L269.352 496.307L259.85 489.396L252.236 480.483Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M371.444 481.267L373.046 488.797L370.9 496.213L365.51 501.728L358.134 503.962L354.7 503.94L343.204 501.671L332.574 496.722L323.117 489.812L315.502 480.899L313.782 477.888L312.136 470.358L314.327 462.942L319.716 457.427L327.048 455.192L330.527 455.215L341.978 457.484L352.608 462.433L362.11 469.343L369.725 478.256L371.444 481.267Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M342.704 319.393L346.333 330.562L347.506 342.252L346.18 353.926L342.404 365.003L340.645 367.99L334.945 373.189L327.482 375.02L320.045 373.092L314.413 367.817L312.693 364.807L309.064 353.682L307.891 341.992L309.217 330.318L312.993 319.197L314.752 316.255L320.452 311.055L327.915 309.179L335.352 311.153L340.983 316.427L342.704 319.393Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M379.495 287.542L378.169 299.172L374.349 310.292L372.634 313.279L366.934 318.479L359.472 320.31L352.034 318.381L346.403 313.107L344.683 310.097L341.053 298.928L339.88 287.282L341.206 275.608L344.982 264.487L346.742 261.5L352.442 256.3L359.904 254.469L367.342 256.398L372.973 261.672L374.649 264.682L378.323 275.852L379.495 287.542Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M411.45 232.78L410.124 244.454L406.348 255.53L404.589 258.518L398.933 263.717L391.426 265.548L383.989 263.619L378.358 258.345L376.682 255.335L373.007 244.21L371.835 232.52L373.161 220.846L376.982 209.725L378.696 206.783L384.396 201.583L391.859 199.707L399.341 201.681L404.928 206.955L406.648 209.92L410.277 221.09L411.45 232.78Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M442.7 287.957L441.374 299.587L437.598 310.708L435.839 313.695L430.139 318.895L422.676 320.726L415.239 318.797L409.607 313.522L407.888 310.512L404.258 299.343L403.085 287.697L404.411 276.023L408.187 264.902L409.947 261.915L415.647 256.716L423.109 254.884L430.547 256.813L436.178 262.088L437.898 265.098L441.528 276.267L442.7 287.957Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M405.926 319.808L409.555 330.977L410.728 342.668L409.402 354.342L405.626 365.418L403.867 368.405L398.211 373.605L390.704 375.435L383.267 373.507L377.636 368.233L375.96 365.223L372.285 354.098L371.113 342.407L372.439 330.734L376.26 319.613L377.974 316.67L383.674 311.471L391.137 309.595L398.619 311.569L404.206 316.842L405.926 319.808Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M339.159 397.125L340.484 385.496L344.26 374.375L346.02 371.388L351.72 366.188L359.182 364.357L366.62 366.286L372.251 371.56L373.927 374.57L377.601 385.74L378.773 397.385L377.447 409.059L373.627 420.18L371.912 423.167L366.212 428.322L358.75 430.198L351.312 428.225L345.681 422.995L343.96 419.985L340.331 408.815L339.159 397.125Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M410.006 452.555L408.68 464.184L404.904 475.305L403.145 478.293L397.489 483.492L389.982 485.323L382.544 483.394L376.913 478.12L375.238 475.11L371.564 463.94L370.391 452.295L371.717 440.621L375.538 429.501L377.253 426.513L382.952 421.358L390.415 419.482L397.897 421.456L403.484 426.685L405.204 429.696L408.833 440.865L410.006 452.555Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M441.978 397.792L440.424 410.315L436.019 422.103L429.546 429.535L425.964 431.437L421.945 432.082L417.934 431.384L414.378 429.436L408.003 421.919L403.753 410.074L402.363 397.531L403.918 385.053L408.323 373.221L414.796 365.833L418.378 363.887L422.397 363.242L426.408 363.939L429.963 365.933L436.339 373.405L440.588 385.294L441.978 397.792Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M469.138 320.223L472.812 331.393L473.985 343.083L472.659 354.757L468.839 365.833L467.124 368.821L461.424 374.021L453.961 375.851L446.524 373.923L440.893 368.648L439.172 365.638L435.542 354.513L434.37 342.823L435.696 331.149L439.472 320.028L441.231 317.086L446.931 311.886L454.394 310.01L461.831 311.984L467.463 317.258L469.138 320.223Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M126.911 311.849L134.643 302.992L144.19 296.251L154.928 291.443L166.408 289.28L169.843 289.303L177.189 291.678L182.506 297.219L184.554 304.663L182.854 312.216L181.094 315.159L173.363 324.016L163.771 330.801L153.122 335.565L141.597 337.728L138.118 337.705L130.817 335.329L125.5 329.789L123.452 322.345L125.152 314.791L126.911 311.849Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M190.133 312.264L197.865 303.407L207.456 296.667L218.151 291.858L229.675 289.696L233.11 289.718L240.411 292.094L245.772 297.635L247.82 305.079L246.076 312.631L244.316 315.574L236.585 324.431L227.038 331.217L216.344 335.981L204.819 338.143L201.384 338.12L194.039 335.744L188.722 330.204L186.674 322.76L188.419 315.207L190.133 312.264Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M296.315 290.133L303.66 292.509L308.977 298.05L311.025 305.494L309.28 313.047L307.566 315.99L299.834 324.847L290.242 331.632L279.548 336.396L268.024 338.558L264.589 338.536L257.244 336.16L251.926 330.619L249.879 323.175L251.623 315.622L253.338 312.679L261.07 303.823L270.661 297.082L281.355 292.273L292.88 290.111L296.315 290.133Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M339.51 261.28L331.779 270.092L322.232 276.878L311.538 281.686L300.013 283.849L296.578 283.826L289.233 281.45L283.916 275.865L281.868 268.421L283.613 260.913L285.327 257.925L293.059 249.113L302.651 242.327L313.344 237.564L324.869 235.401L328.304 235.424L335.605 237.755L340.966 243.34L343.014 250.784L341.27 258.292L339.51 261.28Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M265.055 235.008L272.4 237.339L277.717 242.925L279.765 250.369L278.065 257.877L276.306 260.865L268.574 269.676L258.983 276.462L248.333 281.271L236.809 283.433L233.329 283.41L226.028 281.035L220.712 275.449L218.663 268.005L220.363 260.497L222.123 257.509L229.855 248.698L239.401 241.912L250.14 237.148L261.62 234.986L265.055 235.008Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M158.866 257.094L166.642 248.282L176.189 241.496L186.883 236.733L198.407 234.57L201.842 234.593L209.188 236.924L214.505 242.509L216.553 249.953L214.808 257.461L213.093 260.449L205.362 269.261L195.77 276.047L185.076 280.855L173.552 283.018L170.117 282.995L162.771 280.619L157.455 275.034L155.406 267.59L157.151 260.082L158.866 257.094Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M202.106 228.233L194.761 225.902L189.444 220.316L187.396 212.873L189.141 205.364L190.855 202.376L198.587 193.565L208.178 186.779L218.873 181.971L230.397 179.808L233.832 179.831L241.133 182.206L246.494 187.747L248.542 195.236L246.798 202.744L245.038 205.732L237.307 214.543L227.76 221.329L217.066 226.093L205.541 228.255L202.106 228.233Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M308.287 206.147L300.556 214.959L290.964 221.745L280.27 226.508L268.746 228.671L265.311 228.648L257.965 226.317L252.648 220.732L250.601 213.288L252.345 205.78L254.06 202.792L261.791 193.98L271.383 187.194L282.077 182.386L293.602 180.223L297.037 180.246L304.382 182.622L309.699 188.162L311.747 195.651L310.002 203.159L308.287 206.147Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M354.186 222.15L342.687 227.222L330.227 229.11L320.604 227.212L317.183 225.04L314.571 221.89L313.213 218.032L313.107 213.957L316.38 204.669L324.385 194.918L334.605 187.6L346.148 182.529L358.563 180.596L368.231 182.539L371.652 184.71L374.264 187.86L375.622 191.675L375.729 195.748L372.411 205.037L364.451 214.787L354.186 222.15Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M83.156 386.713L103.144 352.557L111.088 358.876L117.55 366.707L119.226 369.717L120.871 377.248L118.726 384.664L113.336 390.179L105.961 392.413L102.525 392.391L92.5441 390.579L83.156 386.713Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M436.469 484.872L439.886 494.428L441.203 504.508L401.767 504.249L403.261 494.188L406.758 484.676L408.518 481.689L414.218 476.49L421.68 474.658L429.118 476.587L434.749 481.862L436.469 484.872Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M363.907 134.494L383.4 168.909L373.962 172.652L363.957 174.376L360.523 174.354L353.177 171.978L347.86 166.437L345.812 158.994L347.557 151.441L349.272 148.498L355.881 140.708L363.907 134.494Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M221.085 150.61L221.491 149.941L286.781 38.1537L293.345 30.4084L301.372 24.1945L325.848 67.4155L277.389 150.308L277.028 150.977L269.296 159.834L259.705 166.575L249.055 171.383L237.531 173.546L234.051 173.523L226.75 171.148L221.433 165.607L219.385 158.163L221.085 150.61Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M284.335 151.025L284.696 150.356L318.04 93.3259L324.604 85.5806L332.631 79.3667L352.123 113.782L357.107 122.543L340.638 150.724L340.232 151.392L332.501 160.249L322.954 166.99L312.26 171.799L300.735 173.961L297.3 173.938L289.955 171.563L284.638 166.022L282.59 158.579L284.335 151.025Z" fill="#99ADFE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M470.732 375.208L472.447 372.22L478.146 367.021L485.609 365.19L493.091 367.119L498.678 372.393L562.89 485.702L566.307 495.259L567.668 505.339L518.15 505.014L470.432 420.818L466.758 409.648L465.586 397.958L466.911 386.329L470.732 375.208Z" fill="#EBEFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.2118 496.185L22.1 491.191L44.2535 453.334L140.836 453.969L152.332 456.238L162.917 461.187L172.419 468.097L180.034 477.01L181.754 480.02L183.355 487.551L181.209 494.967L175.819 500.482L168.444 502.716L38.5813 501.863L28.5997 500.051L19.2118 496.185Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M76.2432 398.562L109.568 398.781L121.063 401.094L131.693 405.999L141.15 412.909L148.764 421.867L150.485 424.832L152.13 432.408L149.985 439.824L144.551 445.294L137.219 447.573L70.5258 447.135L60.5894 445.279L51.2016 441.413L76.2432 398.562Z" fill="#718BF2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M438.75 429.92L440.51 426.933L446.209 421.778L453.672 419.902L461.109 421.876L466.741 427.105L468.416 430.115L499.684 485.287L503.101 494.844L504.463 504.924L454.944 504.598L440.17 478.54L438.45 475.53L434.821 464.36L433.648 452.715L434.974 441.041L438.75 429.92Z" fill="#EBEFFF"/>
</g>
<defs>
<filter id="filter0_f_2388_5701" x="0.000976562" y="0.905762" width="589.987" height="515.894" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_2388_5701"/>
</filter>
</defs>
</svg>

   );
 };
 