export const Cosmos = () => {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_21588)">
        <path
          d="M27.7758 56.0809C42.3301 56.0809 54.1287 44.2823 54.1287 29.7279C54.1287 15.1736 42.3301 3.375 27.7758 3.375C13.2215 3.375 1.42285 15.1736 1.42285 29.7279C1.42285 44.2823 13.2215 56.0809 27.7758 56.0809Z"
          fill="#2E3148"
        />
        <path
          d="M27.7776 45.018C36.2227 45.018 43.0688 38.1719 43.0688 29.7268C43.0688 21.2817 36.2227 14.4355 27.7776 14.4355C19.3324 14.4355 12.4863 21.2817 12.4863 29.7268C12.4863 38.1719 19.3324 45.018 27.7776 45.018Z"
          fill="#1B1E36"
        />
        <path
          d="M27.8287 6.73828C24.984 6.73828 22.6772 17.056 22.6772 29.7836C22.6772 42.5112 24.984 52.8289 27.8287 52.8289C30.6735 52.8289 32.9802 42.5112 32.9802 29.7836C32.9802 17.056 30.6735 6.73828 27.8287 6.73828ZM28.1844 51.5284C27.8591 51.9622 27.5338 51.6367 27.5338 51.6367C26.2236 50.1188 25.5686 47.2988 25.5686 47.2988C23.2771 39.9244 23.8225 24.091 23.8225 24.091C24.8994 11.5208 26.8585 8.55136 27.5251 7.89212C27.5932 7.82484 27.6828 7.78372 27.7782 7.77601C27.8736 7.76829 27.9687 7.79448 28.0467 7.84995C29.014 8.53534 29.8253 11.4017 29.8253 11.4017C32.221 20.2944 32.004 28.645 32.004 28.645C32.221 35.911 30.8035 44.0446 30.8035 44.0446C29.7125 50.2259 28.1844 51.5284 28.1844 51.5284Z"
          fill="#6F7390"
        />
        <path
          d="M47.8167 18.314C46.4004 15.8457 36.3038 18.9798 25.2586 25.3134C14.2133 31.6469 6.42211 38.7827 7.83737 41.2499C9.25262 43.7172 19.3502 40.5842 30.3955 34.2506C41.4407 27.917 49.2319 20.7813 47.8167 18.314ZM9.14405 40.9114C8.60181 40.8431 8.7243 40.3974 8.7243 40.3974C9.38945 38.5061 11.5061 36.5344 11.5061 36.5344C16.7647 30.8768 30.771 23.4708 30.771 23.4708C42.209 18.1492 45.7618 18.3737 46.663 18.6218C46.7556 18.6477 46.8359 18.7054 46.89 18.7848C46.944 18.8642 46.9682 18.9602 46.9582 19.0557C46.8498 20.2363 44.7621 22.3656 44.7621 22.3656C38.2451 28.8714 30.8934 32.8396 30.8934 32.8396C24.6988 36.6449 16.9384 39.4613 16.9384 39.4613C11.0335 41.5892 9.14426 40.9114 9.14426 40.9114H9.14405Z"
          fill="#6F7390"
        />
        <path
          d="M47.7681 41.3309C49.1953 38.8691 41.4238 31.7008 30.4174 25.3197C19.4111 18.9386 9.31591 15.7658 7.88992 18.2317C6.46392 20.6977 14.2342 27.8618 25.2468 34.2429C36.2595 40.624 46.3421 43.797 47.7681 41.3309ZM8.83566 19.1829C8.62484 18.6831 9.06988 18.5627 9.06988 18.5627C11.0402 18.1906 13.8079 19.0385 13.8079 19.0385C21.3374 20.7531 34.7635 29.1632 34.7635 29.1632C45.1007 36.3966 46.6861 39.5827 46.9237 40.4872C46.9479 40.5799 46.9385 40.6782 46.8972 40.7648C46.8559 40.8513 46.7854 40.9204 46.6981 40.96C45.6202 41.4536 42.7332 40.7182 42.7332 40.7182C33.8351 38.3324 26.7188 33.961 26.7188 33.961C20.3248 30.5124 14.0023 25.2061 14.0023 25.2061C9.19806 21.1611 8.83714 19.1884 8.83714 19.1884L8.83566 19.1829Z"
          fill="#6F7390"
        />
        <path
          d="M27.7747 32.4361C29.272 32.4361 30.4859 31.2222 30.4859 29.7249C30.4859 28.2275 29.272 27.0137 27.7747 27.0137C26.2773 27.0137 25.0635 28.2275 25.0635 29.7249C25.0635 31.2222 26.2773 32.4361 27.7747 32.4361Z"
          fill="#B7B9C8"
        />
        <path
          d="M38.8922 20.9429C39.7605 20.9429 40.4645 20.2146 40.4645 19.3162C40.4645 18.4178 39.7605 17.6895 38.8922 17.6895C38.0238 17.6895 37.3198 18.4178 37.3198 19.3162C37.3198 20.2146 38.0238 20.9429 38.8922 20.9429Z"
          fill="#B7B9C8"
        />
        <path
          d="M13.0789 26.4732C13.9474 26.4732 14.6515 25.7449 14.6515 24.8464C14.6515 23.948 13.9474 23.2197 13.0789 23.2197C12.2104 23.2197 11.5063 23.948 11.5063 24.8464C11.5063 25.7449 12.2104 26.4732 13.0789 26.4732Z"
          fill="#B7B9C8"
        />
        <path
          d="M24.5761 46.4273C25.4446 46.4273 26.1487 45.699 26.1487 44.8005C26.1487 43.9021 25.4446 43.1738 24.5761 43.1738C23.7075 43.1738 23.0034 43.9021 23.0034 44.8005C23.0034 45.699 23.7075 46.4273 24.5761 46.4273Z"
          fill="#B7B9C8"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_21588">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.000976562 0.244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
