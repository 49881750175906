export const FutureThreeMobile = () => {
  return <svg width="236" height="167" viewBox="0 0 236 167" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M186.865 164.807C193.034 172.835 191.528 184.343 183.501 190.512L154.432 212.852C146.405 219.021 134.896 217.514 128.728 209.487L186.865 164.807Z" fill="#FCFCFC"/>
      <path d="M186.402 165.015C179.928 157.231 180.989 145.674 188.772 139.2L216.958 115.755C224.741 109.281 236.299 110.343 242.773 118.126L186.402 165.015Z" fill="#FCFCFC"/>
      <path d="M170.125 132.708C176.294 140.735 174.788 152.243 166.76 158.412L137.692 180.752C129.665 186.921 118.156 185.415 111.987 177.387L170.125 132.708Z" fill="#FCFCFC"/>
      <path d="M282.947 41.821C289.116 49.8482 287.61 61.3564 279.583 67.5254L250.514 89.8652C242.487 96.0342 230.979 94.5279 224.81 86.5007L282.947 41.821Z" fill="#FCFCFC"/>
      <path d="M56.6226 223.392C50.1486 215.608 51.21 204.051 58.9932 197.577L87.1788 174.132C94.9621 167.658 106.52 168.72 112.994 176.503L56.6226 223.392Z" fill="#FCFCFC"/>
      <path d="M169.662 132.915C163.188 125.132 164.249 113.574 172.032 107.1L200.218 83.6558C208.001 77.1818 219.559 78.2432 226.033 86.0264L169.662 132.915Z" fill="#FCFCFC"/>
      <path d="M153.385 100.606C159.554 108.633 158.047 120.142 150.02 126.311L120.951 148.65C112.924 154.819 101.416 153.313 95.2471 145.286L153.385 100.606Z" fill="#FCFCFC"/>
      <path d="M266.211 9.71948C272.38 17.7466 270.874 29.2548 262.846 35.4238L233.778 57.7637C225.75 63.9326 214.242 62.4263 208.073 54.3991L266.211 9.71948Z" fill="#FCFCFC"/>
      <path d="M39.8823 191.29C33.4084 183.507 34.4697 171.949 42.253 165.475L70.4386 142.031C78.2218 135.557 89.7796 136.618 96.2536 144.401L39.8823 191.29Z" fill="#FCFCFC"/>
      <path d="M152.921 100.814C146.447 93.0303 147.509 81.4725 155.292 74.9985L183.478 51.5542C191.261 45.0802 202.819 46.1416 209.293 53.9248L152.921 100.814Z" fill="#FCFCFC"/>
  </svg>

}