export const HIVE = () => {
  return (
    <svg
      width="57"
      height="50"
      viewBox="0 0 57 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.6489 28.2137C40.6903 28.2172 40.7303 28.2305 40.7654 28.2525C40.8005 28.2745 40.8297 28.3045 40.8505 28.34C40.8713 28.3755 40.8831 28.4154 40.8849 28.4563C40.8867 28.4973 40.8785 28.538 40.8608 28.5751L28.7777 49.1551C28.7552 49.1907 28.724 49.2203 28.687 49.2412C28.65 49.262 28.6084 49.2736 28.5658 49.2748H21.179C21.136 49.2748 21.0938 49.2637 21.0567 49.2425C21.0195 49.2213 20.9887 49.1908 20.9675 49.1541C20.9462 49.1173 20.9352 49.0757 20.9356 49.0334C20.9359 48.9911 20.9477 48.9497 20.9696 48.9133L33.0527 28.3333C33.0739 28.2971 33.1044 28.267 33.1412 28.246C33.1779 28.225 33.2196 28.2139 33.2621 28.2137H40.6489ZM33.4662 22.3159C33.4236 22.3147 33.382 22.3031 33.345 22.2823C33.308 22.2614 33.2769 22.2318 33.2543 22.1962L20.9696 1.27259C20.9477 1.23623 20.9359 1.19479 20.9356 1.1525C20.9352 1.11022 20.9462 1.06858 20.9675 1.03185C20.9887 0.995117 21.0195 0.964593 21.0567 0.943387C21.0938 0.922182 21.136 0.911053 21.179 0.911133H28.5658C28.6084 0.912319 28.65 0.923862 28.687 0.944736C28.724 0.965609 28.7552 0.995164 28.7777 1.03077L41.0624 21.9544C41.0844 21.9908 41.0961 22.0322 41.0965 22.0745C41.0969 22.1168 41.0858 22.1584 41.0646 22.1951C41.0433 22.2319 41.0125 22.2624 40.9754 22.2836C40.9382 22.3048 40.896 22.3159 40.8531 22.3159H33.4662Z"
        fill="#E31337"
      />
      <path
        d="M34.9269 1.27215C34.9042 1.23466 34.8923 1.19176 34.8925 1.1481C34.8928 1.10443 34.9052 1.06166 34.9283 1.02443C34.9515 0.987193 34.9846 0.956912 35.024 0.936864C35.0633 0.916816 35.1076 0.907766 35.1518 0.910695H42.549C42.5915 0.910617 42.6333 0.92165 42.67 0.942668C42.7068 0.963685 42.7373 0.993935 42.7583 1.03033L56.8419 24.9729C56.8635 25.0092 56.8748 25.0505 56.8748 25.0925C56.8748 25.1346 56.8635 25.1759 56.8419 25.2122L42.7583 49.1547C42.7373 49.1911 42.7068 49.2214 42.67 49.2424C42.6333 49.2634 42.5915 49.2744 42.549 49.2744H35.1518C35.1086 49.2749 35.0661 49.2641 35.0286 49.2431C34.9911 49.2221 34.9599 49.1916 34.9383 49.1548C34.9168 49.118 34.9055 49.0761 34.9058 49.0336C34.906 48.9911 34.9178 48.9495 34.9398 48.9129L48.9381 25.0925L34.9269 1.27215ZM28.9151 24.9703C28.9378 25.009 28.9497 25.0529 28.9497 25.0976C28.9497 25.1423 28.9378 25.1862 28.9151 25.2249L14.7669 49.1547C14.7432 49.1889 14.7114 49.2168 14.6742 49.2361C14.6371 49.2553 14.5957 49.2654 14.5537 49.2654C14.5117 49.2654 14.4703 49.2553 14.4332 49.2361C14.396 49.2168 14.3642 49.1889 14.3405 49.1547L0.0346273 25.2147C0.0119425 25.176 0 25.1321 0 25.0874C0 25.0428 0.0119425 24.9989 0.0346273 24.9602L14.1828 1.03033C14.2065 0.996207 14.2384 0.968293 14.2755 0.949006C14.3127 0.929718 14.354 0.919638 14.396 0.919638C14.438 0.919638 14.4794 0.929718 14.5166 0.949006C14.5537 0.968293 14.5855 0.996207 14.6093 1.03033L28.9151 24.9703Z"
        fill="#E31337"
      />
    </svg>
  );
};
