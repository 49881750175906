export const GitHub = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_20823)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.1519 0C12.7148 0 0.235352 12.8333 0.235352 28.7099C0.235352 41.4009 8.23135 52.1436 19.3239 55.9458C20.7108 56.2316 21.2188 55.328 21.2188 54.5679C21.2188 53.9023 21.1731 51.6209 21.1731 49.2438C13.4073 50.9553 11.7902 45.8214 11.7902 45.8214C10.5422 42.4941 8.69306 41.6389 8.69306 41.6389C6.15135 39.8802 8.87821 39.8802 8.87821 39.8802C11.6976 40.0703 13.1771 42.8272 13.1771 42.8272C15.6725 47.1998 19.6936 45.9643 21.3113 45.2037C21.5422 43.3498 22.2822 42.0665 23.0679 41.3537C16.8742 40.6881 10.3576 38.2165 10.3576 27.1886C10.3576 24.0514 11.4662 21.4848 13.2228 19.4886C12.9456 18.7758 11.9748 15.8282 13.5005 11.8831C13.5005 11.8831 15.8576 11.1224 21.1725 14.8301C23.448 14.2016 25.7946 13.8819 28.1519 13.8793C30.5091 13.8793 32.9119 14.2123 35.1308 14.8301C40.4462 11.1224 42.8034 11.8831 42.8034 11.8831C44.3291 15.8282 43.3576 18.7758 43.0805 19.4886C44.8833 21.4848 45.9462 24.0514 45.9462 27.1886C45.9462 38.2165 39.4296 40.6403 33.1896 41.3537C34.2068 42.2567 35.0845 43.9676 35.0845 46.6772C35.0845 50.5272 35.0388 53.6171 35.0388 54.5673C35.0388 55.328 35.5473 56.2316 36.9336 55.9463C48.0262 52.143 56.0222 41.4009 56.0222 28.7099C56.0679 12.8333 43.5428 0 28.1519 0Z"
          fill="#24292F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_20823">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.235352)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
