export const Chainlink = () => {
  return (
    <svg
      width="47"
      height="54"
      viewBox="0 0 47 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4671 0.375L18.6224 3.15536L5.42043 10.7711L0.575684 13.5515V39.9044L5.42043 42.6848L18.7435 50.3005L23.5882 53.0809L28.433 50.3005L41.5138 42.6848L46.3585 39.9044V13.5515L41.5138 10.7711L28.3119 3.15536L23.4671 0.375ZM10.2652 34.3437V19.1122L23.4671 11.4964L36.669 19.1122V34.3437L23.4671 41.9595L10.2652 34.3437Z"
        fill="#2A5ADA"
      />
    </svg>
  );
};
