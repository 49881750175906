export const Flutter = () => {
  return (
    <svg
      width="46"
      height="56"
      viewBox="0 0 46 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2676 0.019122L45.6841 0L16.4639 29.0973L8.84862 36.642L0.184082 27.9989L28.2676 0.019122ZM28.1055 25.9996C28.3316 25.7021 28.7474 25.8487 29.063 25.8105L45.6692 25.8147L30.5345 40.8786L21.8252 32.2546L28.1055 25.9996Z"
        fill="#44D1FD"
      />
      <path
        d="M13.1382 40.8938L21.8198 32.2549L30.5291 40.8789L30.5653 40.9172L21.8219 49.5476L13.1382 40.8938Z"
        fill="#1FBCFD"
      />
      <path
        d="M21.8335 49.5464L30.5769 40.916L45.6818 55.9799C39.9921 55.9927 34.3046 55.9757 28.6171 55.9884C28.293 56.0606 28.0925 55.7653 27.8857 55.5826L21.8335 49.5464Z"
        fill="#08589C"
      />
    </svg>
  );
};
