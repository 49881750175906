export const BlueLines = () => {
  return (
    <svg width="191" height="218" viewBox="0 0 191 218" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M171.784 -22.9971L186.462 40.5055C189.457 53.465 189.837 66.8919 187.579 80L183.048 106.308C178.196 134.478 161.521 159.224 137.235 174.298L119.251 185.46C87.9571 204.884 48.5186 205.533 16.6024 187.148L-35.9589 156.872" stroke="#EBEFFF"/>
<path d="M164.161 -22.9964L178.111 37.2059C181.121 50.1959 181.503 63.6566 179.234 76.7963L175.318 99.4773C170.449 127.669 153.74 152.426 129.415 167.485L115.329 176.206C84.0801 195.553 44.7376 196.199 12.8705 177.89L-35.9583 149.835" stroke="#EBEFFF"/>
<path d="M156.536 -22.9957L169.757 33.9023C172.783 46.9253 173.166 60.4228 170.885 73.5966L167.587 92.6433C162.702 120.859 145.955 145.626 121.589 160.67L111.396 166.964C80.1974 186.226 40.9585 186.87 9.14463 168.641L-35.9596 142.797" stroke="#EBEFFF"/>
<path d="M148.913 -22.995L161.402 30.5942C164.445 43.6531 164.831 57.1906 162.537 70.4017L159.862 85.806C154.957 114.048 138.169 138.827 113.759 153.853L107.457 157.733C76.3125 176.905 37.1858 177.546 5.42977 159.404L-35.9589 135.759" stroke="#EBEFFF"/>
<path d="M141.29 -22.9943L153.045 27.281C156.107 40.3791 156.496 53.9604 154.187 67.212L152.139 78.9648C147.213 107.235 130.381 132.026 105.923 147.034L103.508 148.515C72.4228 167.589 33.418 168.227 1.72509 150.18L-35.9583 128.721" stroke="#EBEFFF"/>
<path d="M133.665 -22.9976L144.683 23.958C147.766 37.099 148.157 50.7283 145.832 64.0246L144.289 72.8522C139.422 100.684 122.82 125.079 98.715 139.818V139.818C68.2073 158.471 29.9787 159.095 -1.12183 141.448L-35.9596 121.68" stroke="#EBEFFF"/>
<path d="M126.042 -22.9969L136.319 20.6321C139.425 33.8205 139.819 47.5027 137.477 60.8481L136.23 67.9512C131.556 94.5777 115.642 117.902 92.554 131.964V131.964C63.469 149.679 27.0762 150.272 -2.57071 133.514L-35.959 114.642" stroke="#EBEFFF"/>
<path d="M118.419 -23.0001L127.951 17.2944C131.084 30.5353 131.481 44.2761 129.119 57.6758L128.172 63.0461C123.691 88.4671 108.464 110.721 86.3927 124.106V124.106C58.7307 140.882 24.1737 141.444 -4.01925 125.576L-35.9583 107.601" stroke="#EBEFFF"/>
<path d="M110.794 -22.9995L119.796 14.8723C122.813 27.5641 123.195 40.7396 120.92 53.585L120.112 58.1447C115.823 82.3603 101.284 103.543 80.2291 116.251V116.251C53.9904 132.088 21.2693 132.619 -5.46936 117.642L-35.9596 100.563" stroke="#EBEFFF"/>
<path d="M103.171 -22.9988L111.696 12.6757C114.562 24.6696 114.925 37.1255 112.763 49.2661L112.055 53.243C107.958 76.2534 94.1051 96.3656 74.0669 108.396V108.396C49.2521 123.295 18.3668 123.795 -6.91709 109.707L-35.959 93.5252" stroke="#EBEFFF"/>
<path d="M95.5485 -22.9981L103.596 10.479C106.311 21.7751 106.655 33.5114 104.607 44.9473L103.999 48.3411C100.093 70.1464 86.9263 89.1878 67.9042 100.541V100.541C44.5137 114.501 15.4644 114.969 -8.36426 101.771L-35.9583 86.4876" stroke="#EBEFFF"/>
<path d="M87.9236 -22.9974L95.4933 8.28231C98.0581 18.8806 98.3833 29.8974 96.448 40.6285L95.9412 43.4389C92.2262 64.0393 79.7452 82.0097 61.7388 92.6843V92.6843C39.7733 105.706 12.56 106.144 -9.81277 93.8349L-35.9597 79.4499" stroke="#EBEFFF"/>
<path d="M80.3007 -22.9967L87.3927 6.08562C89.8071 15.986 90.1131 26.2834 88.2911 36.3097L87.8865 38.5363C84.3618 57.932 72.5658 74.8313 55.5746 84.8272V84.8272C35.0348 96.9107 9.65765 97.3172 -11.2586 85.8977L-35.959 72.4122" stroke="#EBEFFF"/>
<path d="M72.6777 -22.9966L79.3047 4.17873C81.5607 13.4299 81.8467 23.0521 80.1442 32.4209L79.7661 34.5015C76.4726 52.6253 65.45 68.4165 49.5731 77.7569V77.7569C30.3802 89.0481 6.66704 89.4279 -12.8776 78.7573L-35.9584 66.156" stroke="#EBEFFF"/>
<path d="M64.0118 -22.9964L70.1738 2.27187C72.2715 10.8739 72.5374 19.8208 70.9543 28.5321L70.6028 30.4667C67.5403 47.3187 57.2913 62.0018 42.5285 70.6867V70.6867C24.6825 81.1855 2.63345 81.5387 -15.5397 71.6168L-37.0007 59.8999" stroke="#EBEFFF"/>
<path d="M56.3889 -22.9962L62.0858 0.365009C64.0251 8.31778 64.271 16.5894 62.8074 24.6434L62.4824 26.432C59.6511 42.0121 50.1756 55.587 36.527 63.6164V63.6164C20.0278 73.3229 -0.357165 73.6494 -17.1587 64.4764L-37.0001 53.6437" stroke="#EBEFFF"/>
<path d="M48.7659 -22.996L53.9978 -1.54185C55.7788 5.76172 56.0046 13.3581 54.6605 20.7546L54.362 22.3972C51.7618 36.7054 43.0598 49.1722 30.5254 56.5462V56.5462C15.3731 65.4603 -3.34778 65.7601 -18.7778 57.3359L-36.9994 47.3876" stroke="#EBEFFF"/>
<path d="M41.141 -22.9998L45.9078 -3.45263C47.5306 3.20174 47.7363 10.1229 46.5117 16.8619L46.2397 18.3585C43.8706 31.3949 35.9421 42.7535 24.5219 49.472V49.472C10.7165 57.5937 -6.34034 57.867 -20.3988 50.1916L-37.0007 41.1275" stroke="#EBEFFF"/>
<path d="M33.5181 -22.9957L37.8198 -5.3556C39.2843 0.649555 39.4699 6.8955 38.3647 12.977L38.1193 14.3276C35.9814 26.0922 28.8264 36.3426 18.5203 42.4057V42.4057C6.06177 49.735 -9.33097 49.9816 -22.0179 43.055L-37.0001 34.8753" stroke="#EBEFFF"/>
<path d="M25.8951 -22.9994L29.7318 -7.26636C31.0379 -1.91041 31.2035 3.66029 30.2178 9.08436L29.9989 10.2889C28.0922 20.7817 21.7107 29.9239 12.5188 35.3315V35.3315C1.40708 41.8685 -12.3216 42.0884 -23.6369 35.9107L-36.9994 28.6152" stroke="#EBEFFF"/>
<path d="M18.2702 -22.9954L21.6419 -9.16934C22.7897 -4.46259 22.9352 0.432878 22.069 5.19949L21.8766 6.25804C20.2009 15.4789 14.593 23.5131 6.51525 28.2652V28.2652C-3.24957 34.0098 -15.3141 34.2031 -25.2579 28.7741L-37.0007 22.363" stroke="#EBEFFF"/>
</svg>

  );
};
