export const Leaf = () => {
   return (
<svg width="208" height="168" viewBox="0 0 208 168" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.08">
<path d="M135.787 36.1809C135.29 46.6688 143.389 55.5739 153.877 56.071L191.857 57.8711C202.345 58.3682 211.25 50.2691 211.747 39.7812L135.787 36.1809Z" fill="#5B7CFE"/>
<path d="M220.592 49.6584C210.104 49.1613 201.199 57.2604 200.701 67.7483L198.901 105.728C198.404 116.216 206.503 125.121 216.991 125.618L220.592 49.6584Z" fill="#5B7CFE"/>
<path d="M60.3298 104.81C59.8327 115.298 67.9318 124.203 78.4197 124.7L116.4 126.501C126.888 126.998 135.793 118.898 136.29 108.411L60.3298 104.81Z" fill="#5B7CFE"/>
<path d="M145.135 118.288C134.647 117.791 125.742 125.89 125.244 136.378L123.444 174.358C122.947 184.846 131.046 193.751 141.534 194.248L145.135 118.288Z" fill="#5B7CFE"/>
<path d="M110.634 59.0573C110.137 69.5452 118.237 78.4503 128.724 78.9474L166.704 80.7476C177.192 81.2447 186.097 73.1455 186.595 62.6576L110.634 59.0573Z" fill="#5B7CFE"/>
<path d="M195.439 72.5348C184.951 72.0377 176.046 80.1369 175.549 90.6248L173.749 128.605C173.252 139.093 181.351 147.998 191.839 148.495L195.439 72.5348Z" fill="#5B7CFE"/>
<path d="M35.1735 127.687C34.6764 138.175 42.7756 147.08 53.2635 147.577L91.2435 149.377C101.731 149.874 110.637 141.775 111.134 131.287L35.1735 127.687Z" fill="#5B7CFE"/>
<path d="M119.978 141.164C109.49 140.667 100.585 148.766 100.088 159.254L98.2881 197.234C97.791 207.722 105.89 216.627 116.378 217.124L119.978 141.164Z" fill="#5B7CFE"/>
<path d="M85.4821 81.9338C84.985 92.4217 93.0842 101.327 103.572 101.824L141.552 103.624C152.04 104.121 160.945 96.022 161.442 85.5341L85.4821 81.9338Z" fill="#5B7CFE"/>
<path d="M170.287 95.4113C159.799 94.9142 150.894 103.013 150.397 113.501L148.597 151.481C148.1 161.969 156.199 170.874 166.687 171.371L170.287 95.4113Z" fill="#5B7CFE"/>
<path d="M10.0212 150.563C9.52407 161.051 17.6232 169.956 28.1111 170.453L66.0912 172.253C76.5791 172.751 85.4842 164.651 85.9813 154.163L10.0212 150.563Z" fill="#5B7CFE"/>
<path d="M94.826 164.041C84.3381 163.544 75.433 171.643 74.9359 182.131L73.1357 220.111C72.6386 230.599 80.7378 239.504 91.2257 240.001L94.826 164.041Z" fill="#5B7CFE"/>
</g>
</svg>
   );
 };