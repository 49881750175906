export const Tron = () => {
  return (
    <svg
      width="51"
      height="54"
      viewBox="0 0 51 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.2379 16.7358C47.7673 14.4546 44.3497 10.9711 41.5662 8.50047L41.4015 8.38518C41.1274 8.16512 40.8184 7.99253 40.4874 7.87459C33.7756 6.62283 2.53911 0.784004 1.9297 0.858121C1.75894 0.882037 1.59572 0.943948 1.45205 1.0393L1.29558 1.16283C1.10291 1.3585 0.956579 1.59489 0.867348 1.85459L0.826172 1.96165V2.54636V2.63694C4.34264 12.4287 18.2273 44.5052 20.9615 52.0322C21.1262 52.5428 21.4391 53.5146 22.0238 53.564H22.1556C22.4685 53.564 23.8026 51.8017 23.8026 51.8017C23.8026 51.8017 47.6521 22.8793 50.065 19.7993C50.3773 19.4199 50.6531 19.0118 50.8885 18.5805C50.9486 18.243 50.9203 17.8957 50.8063 17.5724C50.6923 17.2491 50.4965 16.9609 50.2379 16.7358ZM29.9215 20.104L40.1003 11.6628L46.0709 17.164L29.9215 20.104ZM25.9685 19.5522L8.44382 5.18989L36.7979 10.4193L25.9685 19.5522ZM27.5497 23.3158L45.4862 20.4252L24.9803 45.1311L27.5497 23.3158ZM6.06382 6.62283L24.5026 22.2699L21.8344 45.1475L6.06382 6.62283Z"
        fill="#FF060A"
      />
    </svg>
  );
};
