export const JS = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2470_2196)">
        <path d="M56.772 0H0.771973V56H56.772V0Z" fill="#F7DF1E" />
        <path
          d="M38.39 43.7499C39.518 45.5917 40.9855 46.9454 43.5811 46.9454C45.7615 46.9454 47.1544 45.8557 47.1544 44.3499C47.1544 42.5454 45.7233 41.9063 43.3233 40.8566L42.0078 40.2921C38.2104 38.6743 35.6878 36.6477 35.6878 32.3632C35.6878 28.4166 38.6949 25.4121 43.3944 25.4121C46.7402 25.4121 49.1455 26.5766 50.8789 29.6254L46.7811 32.2566C45.8789 30.6388 44.9055 30.0014 43.3944 30.0014C41.8531 30.0014 40.8762 30.9792 40.8762 32.2566C40.8762 33.8352 41.854 34.4743 44.1118 35.4521L45.4273 36.0157C49.8984 37.933 52.4229 39.8877 52.4229 44.2823C52.4229 49.0201 48.7011 51.6157 43.7029 51.6157C38.8158 51.6157 35.6584 49.2868 34.1135 46.2343L38.39 43.7499ZM19.8006 44.2059C20.6273 45.6726 21.3793 46.9126 23.1873 46.9126C24.9162 46.9126 26.0069 46.2361 26.0069 43.6059V25.7126H31.2691V43.677C31.2691 49.1259 28.0744 51.6059 23.4113 51.6059C19.198 51.6059 16.758 49.4254 15.5171 46.7992L19.8006 44.2059Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2470_2196">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.771973)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
