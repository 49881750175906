export const WhiteLines = () => {
  return (
    <svg width="188" height="193" viewBox="0 0 188 193" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.2162 240L4.53832 176.497C1.54288 163.538 1.16294 150.111 3.42072 137.003L7.95213 110.695C12.8041 82.5254 29.4788 57.7789 53.7651 42.7048L71.7485 31.5429C103.043 12.119 142.481 11.4703 174.398 29.8546L226.959 60.1307" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M26.8391 239.999L12.8888 179.797C9.87877 166.807 9.49698 153.346 11.7659 140.207L15.6825 117.526C20.5506 89.334 37.2601 64.5774 61.5845 49.5178L75.6714 40.7964C106.92 21.45 146.262 20.8037 178.13 39.1133L226.958 67.1684" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M34.464 239.999L21.2433 183.101C18.2173 170.078 17.8335 156.58 20.1146 143.406L23.4126 124.36C28.2982 96.144 45.0454 71.3765 69.4109 56.3327L79.6039 50.0393C110.803 30.7765 150.042 30.1328 181.855 48.3618L226.96 74.2061" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M42.087 239.998L29.598 186.409C26.5546 173.35 26.1687 159.812 28.463 146.601L31.1382 131.197C36.0429 102.955 52.831 78.1762 77.241 63.1496L83.5428 59.2703C114.688 40.0979 153.814 39.4569 185.57 57.5988L226.959 81.2438" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M49.7099 239.997L37.9553 189.722C34.8929 176.624 34.5045 163.043 36.8133 149.791L38.861 138.038C43.7865 109.768 60.6191 84.9767 85.0775 69.969L87.4915 68.4877C118.577 49.4135 157.582 48.7755 189.275 66.8228L226.958 88.2814" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M57.3348 240.001L46.3174 193.045C43.2341 179.904 42.8431 166.275 45.1679 152.978L46.7114 144.151C51.5778 116.319 68.1796 91.9241 92.285 77.1852V77.1852C122.793 58.5317 161.021 57.9075 192.122 75.5549L226.96 95.323" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M64.9578 240L54.681 196.371C51.5745 183.182 51.1806 169.5 53.5231 156.155L54.7699 149.052C59.4436 122.425 75.3577 99.1012 98.446 85.039V85.039C127.531 67.3244 163.924 66.7312 193.571 83.4886L226.959 102.361" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M72.5807 240.003L63.0486 199.708C59.9164 186.468 59.5191 172.727 61.8813 159.327L62.828 153.957C67.3093 128.536 82.5359 106.282 104.607 92.8971V92.8971C132.269 76.1212 166.826 75.5592 195.019 91.4266L226.958 109.402" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M80.2056 240.002L71.2039 202.131C68.1873 189.439 67.8047 176.263 70.08 163.418L70.8876 158.858C75.1768 134.643 89.7163 113.46 110.771 100.752V100.752C137.01 84.9145 169.731 84.3836 196.469 99.3611L226.96 116.44" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M87.8285 240.002L79.3041 204.327C76.4381 192.333 76.0747 179.877 78.2365 167.737L78.9447 163.76C83.042 140.749 96.8949 120.637 116.933 108.607V108.607C141.748 93.7082 172.633 93.2083 197.917 107.296L226.959 123.478" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M95.4515 240.001L87.4043 206.524C84.689 195.228 84.3447 183.491 86.3932 172.056L87.0011 168.662C90.9071 146.857 104.074 127.815 123.096 116.462V116.462C146.486 102.502 175.536 102.034 199.364 115.232L226.958 130.515" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M103.076 240L95.5067 208.721C92.9419 198.122 92.6167 187.106 94.552 176.374L95.0588 173.564C98.7738 152.964 111.255 134.993 129.261 124.319V124.319C151.227 111.297 178.44 110.859 200.813 123.168L226.96 137.553" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M110.699 240L103.607 210.917C101.193 201.017 100.887 190.72 102.709 180.693L103.114 178.467C106.638 159.071 118.434 142.172 135.425 132.176V132.176C155.965 120.092 181.342 119.686 202.259 131.105L226.959 144.591" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M118.322 239.999L111.695 212.824C109.439 203.573 109.153 193.951 110.856 184.582L111.234 182.501C114.527 164.378 125.55 148.586 141.427 139.246V139.246C160.62 127.955 184.333 127.575 203.878 138.246L226.958 150.847" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M126.988 239.999L120.826 214.731C118.729 206.129 118.463 197.182 120.046 188.471L120.397 186.536C123.46 169.684 133.709 155.001 148.471 146.316V146.316C166.317 135.817 188.367 135.464 206.54 145.386L228.001 157.103" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M134.611 239.999L128.914 216.638C126.975 208.685 126.729 200.413 128.193 192.36L128.518 190.571C131.349 174.991 140.824 161.416 154.473 153.386V153.386C170.972 143.68 191.357 143.354 208.159 152.527L228 163.359" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M142.234 239.999L137.002 218.545C135.221 211.241 134.995 203.645 136.339 196.248L136.638 194.606C139.238 180.297 147.94 167.831 160.475 160.457V160.457C175.627 151.543 194.348 151.243 209.778 159.667L227.999 169.615" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M149.859 240.003L145.092 220.456C143.469 213.801 143.264 206.88 144.488 200.141L144.76 198.644C147.129 185.608 155.058 174.249 166.478 167.531V167.531C180.284 159.409 197.34 159.136 211.399 166.811L228.001 175.875" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M157.482 239.999L153.18 222.359C151.716 216.353 151.53 210.107 152.635 204.026L152.881 202.675C155.019 190.911 162.174 180.66 172.48 174.597V174.597C184.938 167.268 200.331 167.021 213.018 173.948L228 182.128" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M165.105 240.002L161.268 224.269C159.962 218.913 159.796 213.343 160.782 207.919L161.001 206.714C162.908 196.221 169.289 187.079 178.481 181.671V181.671C189.593 175.134 203.322 174.915 214.637 181.092L227.999 188.388" stroke="#FCFCFC" strokeOpacity="0.16"/>
<path d="M172.73 239.998L169.358 226.172C168.21 221.466 168.065 216.57 168.931 211.803L169.123 210.745C170.799 201.524 176.407 193.49 184.485 188.738V188.738C194.25 182.993 206.314 182.8 216.258 188.229L228.001 194.64" stroke="#FCFCFC" strokeOpacity="0.16"/>
</svg>

  );
};
