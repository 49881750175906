export const Redis = () => {
  return (
    <svg
      width="68"
      height="56"
      viewBox="0 0 68 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2401_20881"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="68"
        height="56"
      >
        <path d="M67.4849 0H0.984863V56H67.4849V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2401_20881)">
        <path
          d="M64.8688 43.1729C61.3263 44.9938 42.9414 52.4244 38.981 54.464C35.0206 56.5035 32.8996 56.4455 29.8021 54.9906C26.7047 53.5357 7.20703 45.7435 3.67357 44.0834C1.89321 43.2622 0.984863 42.5615 0.984863 41.9055V35.296C0.984863 35.296 26.4549 29.8647 30.5651 28.4009C34.6754 26.937 36.097 26.9013 39.5896 28.1599C43.0822 29.4184 64.0195 33.1315 67.4758 34.3632V40.879C67.4758 41.5574 66.681 42.2179 64.8688 43.1685V43.1729Z"
          fill="#A32422"
        />
        <path
          d="M64.8665 36.5943C61.324 38.4062 42.9391 45.8368 38.9787 47.8407C35.0183 49.8445 32.8973 49.8267 29.7998 48.3762C26.7024 46.9258 7.20473 39.1336 3.67126 37.4868C0.137799 35.84 0.0696732 34.6886 3.53501 33.3587C7.00035 32.0288 26.4526 24.5445 30.5628 23.0718C34.6731 21.599 36.0947 21.5678 39.5873 22.8308C43.0799 24.0938 61.3876 31.2388 64.8257 32.484C68.2637 33.7291 68.4227 34.7511 64.8665 36.5586V36.5943Z"
          fill="#DC382C"
        />
        <path
          d="M64.8688 32.4087C61.3263 34.225 42.9414 41.6512 38.981 43.6551C35.0206 45.6589 32.9178 45.641 29.8203 44.1817C26.7228 42.7223 7.20703 34.9748 3.67357 33.3191C1.89321 32.489 0.984863 31.7928 0.984863 31.1323V24.5451C0.984863 24.5451 26.4549 19.0959 30.5651 17.6321C34.6754 16.1683 36.097 16.1281 39.5896 17.4045C43.0822 18.6809 64.0195 22.3583 67.4758 23.599V30.1147C67.4758 30.7931 66.681 31.4715 64.8688 32.4087Z"
          fill="#A32422"
        />
        <path
          d="M64.8648 25.7993C61.3223 27.6201 42.9373 35.0463 38.9769 37.0501C35.0166 39.054 32.8956 39.0406 29.7981 37.5857C26.7006 36.1308 7.203 28.3699 3.66954 26.7097C0.136072 25.0495 0.0724875 23.9204 3.53329 22.5905C6.99408 21.2605 26.4509 13.7585 30.5611 12.3259C34.6714 10.8933 36.0929 10.8041 39.5855 12.0492C43.0781 13.2943 61.3858 20.5019 64.8239 21.7113C68.262 22.9207 68.421 23.9829 64.8648 25.7859V25.7993Z"
          fill="#DC382C"
        />
        <path
          d="M64.8688 21.2382C61.3263 23.0591 42.9414 30.4897 38.981 32.4891C35.0206 34.4884 32.9178 34.4751 29.8203 33.0246C26.7228 31.5742 7.20703 23.8043 3.67357 22.1486C1.89321 21.3275 0.984863 20.6268 0.984863 19.9663V13.3568C0.984863 13.3568 26.4549 7.92548 30.5651 6.46613C34.6754 5.00677 36.097 4.96214 39.5896 6.22067C43.0822 7.4792 64.0104 11.1923 67.4667 12.433V18.9488C67.4758 19.636 66.681 20.3055 64.8688 21.2382Z"
          fill="#A32422"
        />
        <path
          d="M64.8665 14.6377C61.324 16.4228 42.9391 23.8981 38.9787 25.8841C35.0183 27.87 32.8973 27.8656 29.7998 26.4196C26.7024 24.9737 7.20473 17.1949 3.67126 15.5436C0.137799 13.8924 0.0696732 12.7454 3.53501 11.4155C7.00035 10.0855 26.4526 2.58793 30.5628 1.13304C34.6731 -0.321855 36.0947 -0.366484 39.5873 0.892044C43.0799 2.15057 61.3876 9.30454 64.8257 10.5452C68.2637 11.7859 68.4227 12.8168 64.8665 14.6243V14.6377Z"
          fill="#DC382C"
        />
      </g>
      <path
        d="M35.2744 5.25305L39.6617 3.83833L38.4718 6.63208L42.9363 8.27441L37.1819 8.86351L35.8876 11.9161L33.8074 8.51094L27.1538 7.92185L32.1225 6.16348L30.6282 3.45898L35.2744 5.25305Z"
        fill="white"
      />
      <path
        d="M35.5517 23.0735L24.7969 18.6865L40.2024 16.3613L35.5517 23.0735Z"
        fill="white"
      />
      <path
        d="M20.6396 9.9707C25.1813 9.9707 28.8737 11.3765 28.8737 13.0947C28.8737 14.8129 25.1722 16.2187 20.6396 16.2187C16.1069 16.2187 12.4009 14.8129 12.4009 13.0947C12.4009 11.3765 16.0933 9.9707 20.6396 9.9707Z"
        fill="white"
      />
      <path
        d="M49.7559 9.1084L58.8711 12.6474L49.7649 16.1865L49.7559 9.1084Z"
        fill="#741113"
      />
      <path
        d="M49.7529 9.1084L49.7619 16.1865L48.7718 16.5658L39.6611 13.0268L49.7529 9.1084Z"
        fill="#AC2724"
      />
    </svg>
  );
};
