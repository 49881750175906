export const NuxtJS = () => {
  return (
    <svg
      width="75"
      height="56"
      viewBox="0 0 75 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2470_2199)">
        <path
          d="M42.6526 15.4234L40.1373 19.8778L31.535 4.64006L5.12767 51.4111H23.0072C23.0072 53.8712 24.9573 55.8655 27.3628 55.8655H5.12767C3.57173 55.8655 2.13412 55.0161 1.35627 53.6379C0.578433 52.2598 0.578512 50.562 1.35648 49.1839L27.7639 2.41287C28.5419 1.0346 29.9798 0.185547 31.5359 0.185547C33.0921 0.185547 34.53 1.0346 35.308 2.41287L42.6526 15.4234Z"
          fill="#00C58E"
        />
        <path
          d="M61.7152 49.1844L45.1677 19.8782L42.6523 15.4238L40.137 19.8782L23.5913 49.1844C22.8134 50.5624 22.8133 52.2603 23.5911 53.6384C24.369 55.0165 25.8066 55.866 27.3625 55.866H57.9385C59.4948 55.866 60.933 55.0174 61.7113 53.6392C62.4896 52.2609 62.4897 50.5627 61.7115 49.1844H61.7152ZM27.3625 51.4116L42.6523 24.3326L57.9385 51.4116L27.3625 51.4116Z"
          fill="#2F495E"
        />
        <path
          d="M73.5459 53.6383C72.768 55.0163 71.3305 55.8655 69.7748 55.8655H57.9385C60.344 55.8655 62.2941 53.8712 62.2941 51.4112H69.7657L48.565 13.8588L45.1677 19.8778L42.6523 15.4234L44.7938 11.6316C45.5719 10.2534 47.0098 9.4043 48.5659 9.4043C50.1221 9.4043 51.56 10.2534 52.338 11.6316L73.5459 49.184C74.324 50.5621 74.324 52.2602 73.5459 53.6383Z"
          fill="#108775"
        />
      </g>
      <defs>
        <clipPath id="clip0_2470_2199">
          <rect
            width="73.5"
            height="56"
            fill="white"
            transform="translate(0.771973)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
