export const Figma = () => {
  return (
    <svg
      width="40"
      height="56"
      viewBox="0 0 40 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_21484)">
        <path
          d="M10.377 55.9997C15.6889 55.9997 20.0001 51.8183 20.0001 46.6663V37.333H10.377C5.06504 37.333 0.753906 41.5143 0.753906 46.6663C0.753906 51.8183 5.06504 55.9997 10.377 55.9997Z"
          fill="#0ACF83"
        />
        <path
          d="M0.753906 28.0003C0.753906 22.8483 5.06504 18.667 10.377 18.667H20.0001V37.3337H10.377C5.06504 37.3337 0.753906 33.1523 0.753906 28.0003Z"
          fill="#A259FF"
        />
        <path
          d="M0.753906 9.33333C0.753906 4.18133 5.06504 0 10.377 0H20.0001V18.6667H10.377C5.06504 18.6667 0.753906 14.4853 0.753906 9.33333Z"
          fill="#F24E1E"
        />
        <path
          d="M20 0H29.6231C34.935 0 39.2462 4.18133 39.2462 9.33333C39.2462 14.4853 34.935 18.6667 29.6231 18.6667H20V0Z"
          fill="#FF7262"
        />
        <path
          d="M39.2462 28.0003C39.2462 33.1523 34.935 37.3337 29.6231 37.3337C24.3111 37.3337 20 33.1523 20 28.0003C20 22.8483 24.3111 18.667 29.6231 18.667C34.935 18.667 39.2462 22.8483 39.2462 28.0003Z"
          fill="#1ABCFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_21484">
          <rect
            width="38.5"
            height="56"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
