export const Java = () => {
  return (
    <svg
      width="32"
      height="56"
      viewBox="0 0 32 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_20767)">
        <path
          d="M30.421 43.8778H30.207V43.7617H30.7831V43.8778H30.5705V44.4569H30.421V43.8778ZM31.5699 43.908H31.5666L31.3546 44.457H31.2576L31.0469 43.908H31.0443V44.457H30.9034V43.7618H31.1108L31.3061 44.2541L31.5007 43.7618H31.7074V44.457H31.5698L31.5699 43.908Z"
          fill="#5382A1"
        />
        <path
          d="M11.2662 29.6621C11.2662 29.6621 9.77751 30.5016 12.3257 30.7857C15.4127 31.1272 16.9904 31.0782 20.3923 30.4538C20.3923 30.4538 21.2867 30.9977 22.5357 31.4687C14.9096 34.6381 5.27627 31.2851 11.2662 29.6621Z"
          fill="#5382A1"
        />
        <path
          d="M10.3345 25.5264C10.3345 25.5264 8.66476 26.7249 11.2148 26.9806C14.5124 27.3105 17.1166 27.3375 21.6229 26.4961C21.6229 26.4961 22.2462 27.1088 23.2263 27.4439C14.0058 30.0584 3.73585 27.6501 10.3345 25.5264Z"
          fill="#5382A1"
        />
        <path
          d="M18.1903 18.5101C20.0694 20.6079 17.6966 22.4957 17.6966 22.4957C17.6966 22.4957 22.4679 20.1073 20.2767 17.1164C18.2301 14.3272 16.6607 12.9413 25.157 8.16309C25.157 8.16309 11.8206 11.393 18.1903 18.5101Z"
          fill="#F8981D"
        />
        <path
          d="M28.2767 32.7209C28.2767 32.7209 29.3784 33.6011 27.0634 34.2821C22.6615 35.5751 8.74219 35.9656 4.87546 34.3336C3.48547 33.7472 6.09209 32.9335 6.91204 32.7627C7.76716 32.5829 8.25583 32.6164 8.25583 32.6164C6.71002 31.5604 -1.73566 34.6898 3.96584 35.586C19.5147 38.0312 32.3099 34.485 28.2767 32.7209Z"
          fill="#5382A1"
        />
        <path
          d="M11.9822 21.2405C11.9822 21.2405 4.90191 22.8712 9.47487 23.4634C11.4057 23.7141 15.2548 23.6574 18.8401 23.3661C21.7703 23.1264 24.7125 22.6168 24.7125 22.6168C24.7125 22.6168 23.6793 23.0459 22.9318 23.5408C15.7419 25.3744 1.85239 24.5214 5.851 22.6458C9.23263 21.0607 11.9822 21.2405 11.9822 21.2405Z"
          fill="#5382A1"
        />
        <path
          d="M24.6835 28.1253C31.9924 24.4424 28.613 20.9032 26.2543 21.38C25.6762 21.4967 25.4184 21.5978 25.4184 21.5978C25.4184 21.5978 25.633 21.2718 26.043 21.1307C30.7093 19.5399 34.2979 25.8225 24.5366 28.3109C24.5366 28.3109 24.6497 28.2129 24.6835 28.1253Z"
          fill="#5382A1"
        />
        <path
          d="M20.2766 0.448242C20.2766 0.448242 24.3244 4.37466 16.4375 10.4124C10.113 15.2557 14.9953 18.0172 16.4349 21.1723C12.7432 17.9424 10.034 15.0992 11.8515 12.4529C14.5193 8.56845 21.9098 6.68511 20.2766 0.448242Z"
          fill="#F8981D"
        />
        <path
          d="M12.7004 38.1051C19.716 38.5406 30.4892 37.8635 30.7444 34.6445C30.7444 34.6445 30.2539 35.8648 24.9464 36.8339C18.9584 37.9266 11.5732 37.7991 7.19287 37.0988C7.19287 37.0988 8.08957 37.8184 12.7004 38.1051Z"
          fill="#5382A1"
        />
        <path
          d="M11.5505 50.4048C10.8887 50.9614 10.1897 51.2739 9.56245 51.2739C8.66711 51.2739 8.18338 50.7533 8.18338 49.9183C8.18338 49.0151 8.70166 48.3547 10.781 48.3547H11.5504L11.5505 50.4048ZM13.3767 52.4028V46.2181C13.3767 44.6382 12.4474 43.5957 10.2069 43.5957C8.89895 43.5957 7.75319 43.9089 6.82195 44.3083L7.09075 45.4031C7.82396 45.1421 8.77306 44.8992 9.70462 44.8992C10.9953 44.8992 11.5505 45.4031 11.5505 46.4449V47.2265H10.9063C7.77052 47.2265 6.35596 48.4069 6.35596 50.1787C6.35596 51.7077 7.28815 52.5768 9.04333 52.5768C10.1716 52.5768 11.0138 52.1251 11.801 51.4641L11.944 52.4029L13.3767 52.4028Z"
          fill="#5382A1"
        />
        <path
          d="M19.4714 52.4024H17.1938L14.4526 43.752H16.4417L18.1425 49.0669L18.5207 50.6653C19.3811 48.3542 19.9897 46.0096 20.2941 43.752H22.2289C21.7106 46.6005 20.7777 49.7272 19.4714 52.4024Z"
          fill="#5382A1"
        />
        <path
          d="M28.2086 50.4048C27.5455 50.9614 26.8453 51.2739 26.218 51.2739C25.3243 51.2739 24.8393 50.7533 24.8393 49.9183C24.8393 49.0151 25.3588 48.3547 27.4379 48.3547H28.2086V50.4048ZM30.0346 52.4028V46.2181C30.0346 44.6382 29.103 43.5957 26.8651 43.5957C25.5562 43.5957 24.41 43.9089 23.4792 44.3083L23.7476 45.4031C24.4811 45.1421 25.432 44.8992 26.3628 44.8992C27.6525 44.8992 28.2086 45.4031 28.2086 46.4449V47.2265H27.5634C24.4266 47.2265 23.0127 48.4069 23.0127 50.1787C23.0127 51.7077 23.9442 52.5768 25.699 52.5768C26.8279 52.5768 27.6698 52.1251 28.4584 51.4641L28.6018 52.4029L30.0346 52.4028Z"
          fill="#5382A1"
        />
        <path
          d="M4.35401 53.8717C3.83342 54.6088 2.99258 55.1919 2.07205 55.5218L1.17041 54.4929C1.87108 54.1443 2.47199 53.5812 2.75119 53.0568C2.99269 52.5903 3.09265 51.9892 3.09265 50.5516V40.6758H5.03284V50.4163C5.03273 52.3382 4.87428 53.1146 4.35401 53.8717Z"
          fill="#5382A1"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_20767">
          <rect
            width="31.5"
            height="56"
            fill="white"
            transform="translate(0.484863)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
