export const Android = () => {
  return (
    <svg
      width="50"
      height="56"
      viewBox="0 0 50 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_21397)">
        <path
          d="M16.9567 34.0146C18.8487 34.0146 20.3825 35.5796 20.3825 37.5101V52.5044C20.3825 54.4349 18.8487 55.9996 16.9567 55.9996C15.065 55.9996 13.5312 54.4349 13.5312 52.5044V37.5101C13.5312 35.5799 15.065 34.0149 16.9567 34.0149V34.0146Z"
          fill="#A4C639"
        />
        <path
          d="M9.13591 15.9873C9.12461 16.1198 9.11475 16.2539 9.11475 16.3893V39.1072C9.11475 41.5504 11.0101 43.5186 13.3629 43.5186H36.0048C38.3579 43.5186 40.2532 41.5501 40.2532 39.1072V16.3893C40.2532 16.2539 40.2484 16.1194 40.2373 15.9873H9.13591Z"
          fill="#A4C639"
        />
        <path
          d="M32.4118 34.0151C34.3037 34.0151 35.8375 35.5801 35.8375 37.5105V52.5049C35.8375 54.4353 34.3037 56.0001 32.4118 56.0001C30.5201 56.0001 28.9863 54.4353 28.9863 52.5049V37.5105C28.9863 35.5803 30.5201 34.0154 32.4118 34.0154V34.0151ZM3.61078 18.0095C5.5025 18.0095 7.03627 19.5745 7.03627 21.5049V36.4993C7.03627 38.4297 5.5025 39.9947 3.61078 39.9947C1.71883 39.9947 0.185059 38.4297 0.185059 36.4995V21.5052C0.184818 19.5747 1.71859 18.0095 3.61078 18.0095ZM45.7579 18.0095C47.6497 18.0095 49.1834 19.5745 49.1834 21.5049V36.4993C49.1834 38.4297 47.6497 39.9947 45.7579 39.9947C43.866 39.9947 42.3322 38.4297 42.3322 36.4995V21.5052C42.3322 19.5747 43.866 18.0095 45.7579 18.0095ZM9.23613 14.4954C9.33139 7.51738 15.3005 1.79601 22.9626 1.04199H26.4054C34.068 1.79625 40.0366 7.51787 40.1319 14.4954H9.23613Z"
          fill="#A4C639"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2474 0.0493836C10.42 -0.0522807 10.6406 0.00805756 10.7403 0.184153L14.3539 6.57069C14.4535 6.74678 14.3944 6.97195 14.2218 7.07362C14.0492 7.17528 13.8285 7.11494 13.7289 6.93885L10.1153 0.552312C10.0157 0.376216 10.0748 0.151048 10.2474 0.0493836ZM39.1217 0.0493888C39.2943 0.151058 39.3535 0.376228 39.2538 0.552321L35.64 6.93886C35.5403 7.11495 35.3197 7.17528 35.1471 7.07361C34.9745 6.97194 34.9154 6.74677 35.015 6.57068L38.6288 0.184144C38.7285 0.00805137 38.9492 -0.0522805 39.1217 0.0493888Z"
          fill="#A4C639"
        />
        <path
          d="M20.0822 8.24186C20.085 9.21726 19.2939 10.0103 18.3148 10.013C17.3362 10.0155 16.5405 9.22708 16.5376 8.25167V8.24186C16.535 7.2662 17.3261 6.47341 18.305 6.47071C19.2835 6.46801 20.0793 7.25614 20.0822 8.23204V8.24186ZM33.3116 8.24186C33.3145 9.21726 32.5234 10.0103 31.5443 10.013C30.5657 10.0155 29.77 9.22708 29.7671 8.25167V8.24186C29.7644 7.2662 30.5556 6.47341 31.5344 6.47071C32.513 6.46801 33.3088 7.25614 33.3116 8.23204V8.24186Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_21397">
          <rect
            width="49"
            height="56"
            fill="white"
            transform="translate(0.184082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
