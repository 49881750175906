export const ClockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2415 10.0744C10.5035 10.1618 10.7866 10.0202 10.8739 9.75821C10.9613 9.49624 10.8197 9.21308 10.5577 9.12575L10.2415 10.0744ZM7.99961 8.8001H7.49961C7.49961 9.01531 7.63732 9.20638 7.84149 9.27444L7.99961 8.8001ZM8.49961 5.45494C8.49961 5.17879 8.27575 4.95494 7.99961 4.95494C7.72347 4.95494 7.49961 5.17879 7.49961 5.45494H8.49961ZM10.5577 9.12575L8.15772 8.32575L7.84149 9.27444L10.2415 10.0744L10.5577 9.12575ZM8.49961 8.8001V5.45494H7.49961V8.8001H8.49961ZM13.8996 8.0001C13.8996 11.2586 11.2581 13.9001 7.99961 13.9001V14.9001C11.8104 14.9001 14.8996 11.8109 14.8996 8.0001H13.8996ZM7.99961 13.9001C4.74113 13.9001 2.09961 11.2586 2.09961 8.0001H1.09961C1.09961 11.8109 4.18884 14.9001 7.99961 14.9001V13.9001ZM2.09961 8.0001C2.09961 4.74162 4.74113 2.1001 7.99961 2.1001V1.1001C4.18884 1.1001 1.09961 4.18933 1.09961 8.0001H2.09961ZM7.99961 2.1001C11.2581 2.1001 13.8996 4.74162 13.8996 8.0001H14.8996C14.8996 4.18933 11.8104 1.1001 7.99961 1.1001V2.1001Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};
