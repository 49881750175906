export const NETcore = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_20739)">
        <path
          d="M28.4473 56C43.9112 56 56.4473 43.464 56.4473 28C56.4473 12.536 43.9112 0 28.4473 0C12.9833 0 0.447266 12.536 0.447266 28C0.447266 43.464 12.9833 56 28.4473 56Z"
          fill="#5C2D91"
        />
        <path
          opacity="0.1"
          d="M9.03986 7.875C6.19345 10.4115 3.89503 13.5025 2.28549 16.9587C0.675939 20.4148 -0.210825 24.1633 -0.320453 27.9743C-0.43008 31.7853 0.239739 35.5785 1.64795 39.1215C3.05616 42.6644 5.17309 45.8825 7.86898 48.5784C10.5649 51.2743 13.7829 53.3912 17.3259 54.7994C20.8689 56.2076 24.6621 56.8774 28.4731 56.7678C32.2841 56.6582 36.0325 55.7714 39.4887 54.1619C42.9449 52.5523 46.0359 50.2539 48.5724 47.4075L9.03986 7.875Z"
          fill="white"
        />
        <path
          d="M6.92233 32.5934C6.759 32.596 6.59691 32.5647 6.4463 32.5014C6.2957 32.4382 6.15984 32.3444 6.04733 32.2259C5.93174 32.1117 5.84038 31.9754 5.77874 31.825C5.71709 31.6747 5.68643 31.5134 5.68858 31.3509C5.68742 31.1886 5.71853 31.0276 5.78012 30.8774C5.84171 30.7272 5.93254 30.5907 6.04733 30.4759C6.15874 30.3555 6.29418 30.2598 6.44491 30.195C6.59563 30.1302 6.75828 30.0977 6.92233 30.0997C7.08615 30.0993 7.24831 30.1325 7.39881 30.1972C7.5493 30.2619 7.68493 30.3568 7.79733 30.4759C7.91411 30.5897 8.00682 30.7258 8.06995 30.8761C8.13308 31.0264 8.16535 31.1879 8.16483 31.3509C8.16636 31.5141 8.13455 31.6759 8.07136 31.8264C8.00817 31.9768 7.91492 32.1128 7.79733 32.2259C7.68387 32.3431 7.54784 32.4361 7.39745 32.4993C7.24707 32.5624 7.08544 32.5945 6.92233 32.5934Z"
          fill="white"
        />
        <path
          d="M24.3084 32.3751H22.0159L13.8171 19.6526C13.6129 19.3391 13.4428 19.0047 13.3096 18.6551H13.2396C13.3175 19.3815 13.3468 20.1123 13.3271 20.8426V32.3751H11.4634V16.4238H13.8871L21.8671 28.9188C22.1996 29.438 22.4155 29.7938 22.5146 29.9863H22.5584C22.4661 29.2083 22.428 28.4247 22.4446 27.6413V16.4238H24.3084V32.3751Z"
          fill="white"
        />
        <path
          d="M36.926 32.3751H28.4473V16.4238H36.5323V18.1126H30.3548V23.4151H36.1123V25.0951H30.3548V30.6251H36.9348L36.926 32.3751Z"
          fill="white"
        />
        <path
          d="M49.4471 18.1126H44.8096V32.3751H42.9458V18.1126H38.3608V16.4238H49.4471V18.1126Z"
          fill="white"
        />
        <path
          d="M23.3022 43.2245C22.6705 43.5305 21.9737 43.6777 21.2722 43.6533C20.8061 43.6743 20.3409 43.5954 19.9077 43.4218C19.4746 43.2483 19.0836 42.9841 18.761 42.647C18.118 41.9274 17.7793 40.9864 17.816 40.022C17.7924 39.4999 17.874 38.9784 18.0559 38.4884C18.2378 37.9984 18.5162 37.5499 18.8747 37.1695C19.2241 36.8116 19.6441 36.5303 20.1081 36.3435C20.5721 36.1568 21.0698 36.0686 21.5697 36.0845C22.168 36.0641 22.764 36.1684 23.3197 36.3908V37.2658C22.7846 36.9696 22.1813 36.8187 21.5697 36.8283C21.1842 36.8174 20.8008 36.89 20.4459 37.041C20.091 37.192 19.773 37.418 19.5135 37.7033C18.9678 38.325 18.6853 39.1346 18.726 39.9608C18.6851 40.7435 18.9485 41.5116 19.461 42.1045C19.7068 42.3689 20.0069 42.5769 20.3407 42.7144C20.6745 42.8518 21.034 42.9154 21.3947 42.9008C22.0666 42.9184 22.73 42.7487 23.311 42.4108L23.3022 43.2245Z"
          fill="#F2F2F2"
        />
        <path
          d="M26.8811 43.6534C26.5328 43.6647 26.1861 43.601 25.8646 43.4666C25.543 43.3323 25.2541 43.1304 25.0173 42.8747C24.5389 42.3407 24.2905 41.6395 24.3261 40.9234C24.3042 40.5483 24.3572 40.1725 24.4819 39.818C24.6065 39.4635 24.8005 39.1373 25.0523 38.8584C25.3094 38.6075 25.6152 38.4121 25.9508 38.2842C26.2864 38.1562 26.6448 38.0985 27.0036 38.1147C27.3428 38.0966 27.6818 38.1522 27.9975 38.2775C28.3132 38.4029 28.598 38.5951 28.8323 38.8409C29.2974 39.4014 29.532 40.1177 29.4886 40.8447C29.5253 41.5825 29.2708 42.3052 28.7798 42.8572C28.5386 43.1179 28.2443 43.3238 27.9168 43.4612C27.5893 43.5985 27.2361 43.6641 26.8811 43.6534ZM26.9423 38.8672C26.7016 38.857 26.4618 38.9013 26.2406 38.9968C26.0194 39.0922 25.8226 39.2363 25.6648 39.4184C25.3281 39.8519 25.1603 40.3929 25.1923 40.9409C25.1618 41.4726 25.3302 41.9966 25.6648 42.4109C25.8242 42.5904 26.0215 42.7322 26.2425 42.826C26.4634 42.9198 26.7025 42.9634 26.9423 42.9534C27.1771 42.9673 27.412 42.9272 27.6289 42.8361C27.8458 42.7451 28.039 42.6057 28.1936 42.4284C28.5117 41.9937 28.6666 41.4609 28.6311 40.9234C28.6657 40.3833 28.511 39.848 28.1936 39.4097C28.0411 39.2287 27.8488 39.0855 27.6317 38.9914C27.4146 38.8973 27.1786 38.8548 26.9423 38.8672Z"
          fill="#F2F2F2"
        />
        <path
          d="M33.5746 39.13C33.3857 39.0062 33.1613 38.9478 32.9359 38.9638C32.7596 38.9702 32.5876 39.0198 32.4351 39.1083C32.2825 39.1968 32.154 39.3215 32.0609 39.4713C31.8005 39.8836 31.6751 40.3668 31.7021 40.8538V43.5313H30.8271V38.2813H31.7021V39.375C31.7983 39.0366 31.9897 38.7329 32.2534 38.5C32.4816 38.302 32.7737 38.1932 33.0759 38.1938C33.245 38.1862 33.4141 38.2099 33.5746 38.2638V39.13Z"
          fill="#F2F2F2"
        />
        <path
          d="M38.7022 41.1246H35.001C34.9761 41.6137 35.1353 42.0944 35.4472 42.4721C35.6094 42.6338 35.8039 42.7595 36.018 42.841C36.2321 42.9224 36.461 42.9577 36.6897 42.9446C37.2829 42.9387 37.8565 42.732 38.3172 42.3583V43.1458C37.7762 43.5024 37.1356 43.6771 36.4885 43.6446C36.1619 43.6611 35.8358 43.6053 35.5333 43.4813C35.2308 43.3573 34.9594 43.168 34.7385 42.9271C34.2829 42.3589 34.0552 41.6415 34.0997 40.9146C34.0683 40.1859 34.3154 39.4726 34.791 38.9196C35.0074 38.6696 35.2767 38.4708 35.5793 38.3376C35.882 38.2045 36.2104 38.1402 36.541 38.1496C36.8394 38.1329 37.1378 38.184 37.4136 38.2992C37.6894 38.4144 37.9355 38.5906 38.1335 38.8146C38.5364 39.3458 38.7351 40.0041 38.6935 40.6696L38.7022 41.1246ZM37.8272 40.4158C37.845 40.0104 37.7212 39.6114 37.4772 39.2871C37.3575 39.1518 37.2087 39.0453 37.042 38.9756C36.8754 38.9059 36.6951 38.8748 36.5147 38.8846C36.3264 38.8823 36.1397 38.9192 35.9665 38.993C35.7932 39.0668 35.6372 39.1759 35.5085 39.3133C35.2205 39.618 35.0393 40.008 34.9922 40.4246L37.8272 40.4158Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2401_20739">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.447266)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
