export const Apple = () => {
  return (
    <svg
      width="46"
      height="56"
      viewBox="0 0 46 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.4436 29.6944C38.5221 38.1528 45.8639 40.9676 45.9452 41.0035C45.8831 41.202 44.7721 45.0148 42.0772 48.9531C39.7476 52.358 37.3298 55.7505 33.521 55.8207C29.7784 55.8897 28.575 53.6014 24.2962 53.6014C20.0188 53.6014 18.6817 55.7505 15.139 55.8897C11.4625 56.0288 8.66295 52.2077 6.31398 48.8153C1.51413 41.876 -2.15394 29.2065 2.77136 20.6543C5.21814 16.4073 9.59072 13.7179 14.3368 13.6489C17.947 13.58 21.3545 16.0777 23.5615 16.0777C25.7671 16.0777 29.908 13.074 34.2612 13.5152C36.0836 13.591 41.1992 14.2513 44.484 19.0594C44.2193 19.2235 38.3801 22.6228 38.4436 29.6944V29.6944ZM31.4099 8.92421C33.3618 6.56157 34.6755 3.27255 34.3171 0C31.5037 0.113076 28.1016 1.87481 26.0835 4.23615C24.275 6.32723 22.6911 9.67414 23.1185 12.8819C26.2544 13.1245 29.458 11.2884 31.4099 8.92422"
        fill="black"
      />
    </svg>
  );
};
