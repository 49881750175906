export const NextJS = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1882 0.0150698C26.0677 0.0260164 25.6845 0.0643294 25.3397 0.0916959C17.3858 0.808697 9.93556 5.09976 5.21689 11.6951C2.58932 15.3622 0.908776 19.5219 0.273781 23.9279C0.0493431 25.4659 0.0219727 25.9202 0.0219727 28.0055C0.0219727 30.0908 0.0493431 30.5451 0.273781 32.0831C1.79558 42.5973 9.27867 51.4312 19.4276 54.7042C21.245 55.2898 23.161 55.6894 25.3397 55.9302C26.1882 56.0233 29.8558 56.0233 30.7043 55.9302C34.465 55.5142 37.6509 54.5838 40.793 52.9801C41.2748 52.7338 41.3678 52.6681 41.3021 52.6134C41.2583 52.5806 39.2056 49.8275 36.7422 46.4997L32.2644 40.4517L26.6534 32.1488C23.5661 27.584 21.0261 23.8513 21.0042 23.8513C20.9823 23.8458 20.9604 27.5348 20.9494 32.0393C20.933 39.9263 20.9275 40.2438 20.829 40.4299C20.6867 40.698 20.5772 40.8075 20.3473 40.9279C20.1721 41.0155 20.0188 41.0319 19.1923 41.0319H18.2452L17.9934 40.8732C17.8292 40.7692 17.7088 40.6324 17.6267 40.4736L17.5117 40.2273L17.5227 29.2534L17.5391 18.274L17.7088 18.0605C17.7964 17.9456 17.9825 17.7978 18.1139 17.7266C18.3383 17.6172 18.4259 17.6062 19.3729 17.6062C20.4896 17.6062 20.6757 17.65 20.9659 17.9675C21.048 18.055 24.0861 22.6307 27.7209 28.1423C31.3557 33.6539 36.3262 41.1797 38.7676 44.8742L43.2016 51.5899L43.4261 51.4421C45.4132 50.1504 47.5152 48.3114 49.1794 46.3957C52.7211 42.3291 55.0038 37.3703 55.7702 32.0831C55.9946 30.5451 56.022 30.0908 56.022 28.0055C56.022 25.9202 55.9946 25.4659 55.7702 23.9279C54.2484 13.4137 46.7653 4.57979 36.6163 1.30677C34.8263 0.726598 32.9213 0.327047 30.7864 0.0862226C30.2609 0.0314897 26.6425 -0.0287165 26.1882 0.0150698ZM37.6509 16.9494C37.9137 17.0808 38.1272 17.3326 38.2038 17.5953C38.2476 17.7376 38.2585 20.7807 38.2476 27.6388L38.2312 37.4798L36.4959 34.8197L34.7551 32.1597V25.0061C34.7551 20.3812 34.777 17.7814 34.8099 17.6555C34.8974 17.349 35.089 17.1082 35.3518 16.9659C35.5762 16.8509 35.6583 16.84 36.5178 16.84C37.3279 16.84 37.4703 16.8509 37.6509 16.9494Z"
        fill="black"
      />
    </svg>
  );
};
