export const LogoMobile = () => {
  return <svg width="209" height="282" viewBox="0 0 209 282" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.05" filter="url(#filter0_f_3010_4700)" >
          <path fillRule="evenodd" clipRule="evenodd" d="M5.03793 265.432L155.916 5.82397L320.845 276.06L5.03793 265.432Z" fill="#1D1E2C"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M78.3446 203.141L73.075 199.367L68.7731 194.568L67.7839 192.96L66.7801 188.977L67.8181 185.11L70.6555 182.29L74.6073 181.225L76.502 181.288L82.7998 182.652L88.6492 185.411L93.943 189.186L98.2449 193.985L99.2341 195.593L100.238 199.576L99.1999 203.443L96.3625 206.263L92.3864 207.328L90.5159 207.265L84.2425 205.902L78.3446 203.141Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M112.794 204.3L107.5 200.526L103.198 195.726L102.233 194.119L101.205 190.135L102.243 186.268L105.08 183.449L109.057 182.384L110.927 182.447L117.225 183.811L123.098 186.57L128.368 190.344L132.67 195.143L133.659 196.751L134.687 200.735L133.649 204.603L130.787 207.421L126.836 208.487L124.965 208.424L118.667 207.06L112.794 204.3Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M147.209 205.458L141.915 201.684L137.613 196.885L136.649 195.277L135.62 191.293L136.658 187.426L139.496 184.607L143.472 183.542L145.342 183.605L151.64 184.969L157.514 187.729L162.808 191.503L167.109 196.302L168.074 197.91L169.102 201.893L168.065 205.761L165.227 208.58L161.251 209.645L159.381 209.582L153.083 208.218L147.209 205.458Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M154.638 224.743L153.609 220.735L154.647 216.868L157.485 214.072L161.46 212.984L163.331 213.047L169.629 214.434L175.502 217.17L180.772 220.944L185.074 225.767L186.063 227.351L187.092 231.359L186.054 235.226L183.192 238.021L179.24 239.111L177.37 239.048L171.071 237.66L165.198 234.924L159.905 231.149L155.602 226.326L154.638 224.743Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M141.063 216.011L146.357 219.786L150.659 224.609L151.648 226.193L152.652 230.2L151.614 234.067L148.776 236.863L144.801 237.952L142.93 237.889L136.656 236.502L130.759 233.765L125.489 229.991L121.187 225.168L120.198 223.584L119.194 219.577L120.232 215.71L123.07 212.914L127.021 211.825L128.916 211.888L135.214 213.276L141.063 216.011Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M85.7785 222.425L84.7498 218.418L85.7878 214.551L88.6257 211.755L92.6013 210.666L94.4718 210.729L100.77 212.117L106.643 214.853L111.937 218.628L116.239 223.45L117.204 225.034L118.232 229.042L117.195 232.909L114.357 235.705L110.381 236.793L108.511 236.73L102.212 235.343L96.3392 232.606L91.0695 228.833L86.7428 224.009L85.7785 222.425Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M104.733 253.489L103.768 251.882L102.74 247.898L103.777 244.031L106.615 241.211L110.591 240.146L112.461 240.209L118.759 241.573L124.633 244.333L129.902 248.107L134.204 252.906L135.193 254.514L136.222 258.498L135.184 262.365L132.322 265.184L128.37 266.249L126.5 266.186L120.202 264.823L114.328 262.063L109.034 258.288L104.733 253.489Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M139.148 254.647L138.183 253.04L137.155 249.056L138.193 245.189L141.03 242.37L145.006 241.305L146.877 241.367L153.175 242.731L159.048 245.491L164.342 249.266L168.644 254.065L169.609 255.672L170.637 259.656L169.599 263.523L166.762 266.343L162.785 267.408L160.915 267.345L154.617 265.981L148.744 263.221L143.45 259.446L139.148 254.647Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M204.058 256.832L205.062 260.815L204.024 264.682L201.187 267.501L197.21 268.566L195.34 268.503L189.042 267.139L183.169 264.38L177.899 260.606L173.597 255.807L172.608 254.198L171.58 250.215L172.642 246.348L175.48 243.529L179.431 242.463L181.326 242.527L187.6 243.89L193.473 246.65L198.767 250.424L203.069 255.223L204.058 256.832Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M185.573 171.299L187.744 177.226L188.588 183.389L188.071 189.507L186.209 195.274L185.304 196.819L182.292 199.468L178.262 200.319L174.179 199.195L171.021 196.338L170.032 194.73L167.86 188.827L167.017 182.663L167.534 176.545L169.395 170.755L170.301 169.234L173.313 166.585L177.342 165.71L181.426 166.858L184.584 169.715L185.573 171.299Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M205.043 155.101L204.525 161.195L202.64 166.985L201.759 168.53L198.747 171.179L194.717 172.031L190.634 170.906L187.476 168.05L186.487 166.442L184.315 160.515L183.472 154.375L183.989 148.257L185.85 142.467L186.755 140.922L189.767 138.273L193.797 137.422L197.88 138.546L201.038 141.403L202.003 143.01L204.199 148.937L205.043 155.101Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M221.478 126.785L220.961 132.903L219.1 138.67L218.194 140.215L215.207 142.864L211.152 143.715L207.069 142.59L203.911 139.734L202.946 138.126L200.751 132.223L199.908 126.059L200.424 119.941L202.309 114.151L203.191 112.63L206.203 109.981L210.233 109.106L214.34 110.255L217.474 113.111L218.463 114.695L220.635 120.622L221.478 126.785Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M239.458 156.259L238.94 162.354L237.08 168.144L236.174 169.688L233.163 172.338L229.132 173.189L225.049 172.065L221.891 169.208L220.902 167.6L218.73 161.674L217.888 155.533L218.405 149.415L220.265 143.625L221.171 142.081L224.182 139.432L228.213 138.58L232.296 139.705L235.454 142.561L236.443 144.169L238.615 150.096L239.458 156.259Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M219.997 172.458L222.169 178.384L223.012 184.548L222.495 190.665L220.634 196.433L219.729 197.977L216.741 200.627L212.687 201.477L208.604 200.353L205.446 197.496L204.481 195.889L202.285 189.985L201.442 183.822L201.959 177.704L203.844 171.914L204.726 170.392L207.737 167.744L211.767 166.868L215.875 168.017L219.009 170.873L219.997 172.458Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M185.006 212.114L185.524 206.02L187.384 200.229L188.29 198.685L191.302 196.036L195.332 195.184L199.415 196.309L202.573 199.166L203.538 200.773L205.734 206.7L206.576 212.84L206.059 218.958L204.175 224.748L203.293 226.293L200.281 228.919L196.251 229.794L192.168 228.646L189.01 225.812L188.021 224.204L185.849 218.278L185.006 212.114Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M224.547 242.31L224.029 248.404L222.169 254.195L221.263 255.739L218.276 258.389L214.221 259.24L210.138 258.115L206.98 255.259L206.015 253.651L203.819 247.724L202.977 241.584L203.493 235.466L205.378 229.677L206.26 228.131L209.272 225.506L213.302 224.631L217.409 225.78L220.543 228.612L221.532 230.22L223.704 236.146L224.547 242.31Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M240.992 213.994L240.365 220.555L238.174 226.687L234.781 230.498L232.864 231.444L230.687 231.724L228.492 231.297L226.521 230.22L222.919 226.173L220.398 219.883L219.421 213.268L220.049 206.73L222.239 200.575L225.633 196.787L227.549 195.817L229.726 195.538L231.922 195.964L233.892 197.065L237.494 201.088L240.016 207.402L240.992 213.994Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M254.417 173.616L256.613 179.543L257.456 185.707L256.939 191.825L255.054 197.591L254.173 199.136L251.161 201.785L247.131 202.637L243.048 201.512L239.89 198.655L238.9 197.047L236.729 191.144L235.886 184.981L236.403 178.863L238.263 173.072L239.17 171.551L242.181 168.903L246.211 168.027L250.295 169.176L253.453 172.032L254.417 173.616Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M67.9643 164.124L72.0181 159.583L77.0971 156.181L82.8587 153.812L89.0703 152.846L90.9407 152.909L94.9812 154.267L97.973 157.259L99.2185 161.204L98.4254 165.149L97.5192 166.67L93.4655 171.212L88.363 174.637L82.6492 176.983L76.4133 177.948L74.5185 177.885L70.5024 176.527L67.5106 173.535L66.2651 169.591L67.0582 165.645L67.9643 164.124Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M102.389 165.283L106.443 160.741L111.546 157.34L117.284 154.971L123.519 154.005L125.39 154.068L129.406 155.426L132.422 158.419L133.668 162.363L132.85 166.308L131.944 167.829L127.89 172.37L122.812 175.796L117.074 178.141L110.838 179.107L108.968 179.044L104.927 177.686L101.935 174.694L100.69 170.75L101.507 166.805L102.389 165.283Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M159.805 155.227L163.846 156.585L166.838 159.577L168.083 163.521L167.266 167.466L166.384 168.988L162.33 173.529L157.227 176.954L151.489 179.3L145.254 180.265L143.383 180.202L139.343 178.844L136.351 175.852L135.105 171.908L135.923 167.963L136.805 166.441L140.858 161.9L145.962 158.498L151.699 156.129L157.935 155.164L159.805 155.227Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M182.815 140.699L178.76 145.217L173.682 148.643L167.944 151.012L161.709 151.977L159.838 151.914L155.798 150.556L152.805 147.54L151.56 143.596L152.378 139.675L153.259 138.129L157.313 133.612L162.416 130.187L168.154 127.841L174.39 126.876L176.26 126.939L180.276 128.273L183.293 131.289L184.538 135.233L183.72 139.155L182.815 140.699Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M141.821 125.78L145.861 127.115L148.853 130.13L150.098 134.074L149.305 137.996L148.399 139.541L144.345 144.059L139.242 147.484L133.529 149.854L127.293 150.819L125.399 150.755L121.382 149.398L118.39 146.382L117.144 142.438L117.938 138.516L118.844 136.971L122.898 132.453L127.976 129.028L133.739 126.683L139.95 125.717L141.821 125.78Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M84.3998 135.812L88.4783 131.295L93.5567 127.869L99.2948 125.524L105.531 124.558L107.401 124.621L111.441 125.956L114.433 128.972L115.679 132.916L114.861 136.837L113.979 138.383L109.925 142.901L104.823 146.325L99.0851 148.694L92.8494 149.66L90.9789 149.597L86.9384 148.239L83.9461 145.223L82.7005 141.279L83.5186 137.358L84.3998 135.812Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M107.433 121.282L103.393 119.947L100.401 116.931L99.1555 112.987L99.9735 109.066L100.855 107.52L104.909 103.002L110.012 99.5775L115.749 97.2085L121.985 96.243L123.855 96.3059L127.872 97.6635L130.888 100.656L132.134 104.624L131.316 108.545L130.41 110.09L126.356 114.608L121.278 118.033L115.539 120.379L109.304 121.344L107.433 121.282Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M164.85 111.249L160.796 115.767L155.693 119.192L149.955 121.537L143.719 122.503L141.849 122.44L137.809 121.105L134.816 118.089L133.571 114.146L134.389 110.224L135.27 108.678L139.324 104.161L144.427 100.736L150.165 98.3666L156.4 97.4011L158.271 97.4641L162.311 98.8224L165.303 101.814L166.549 105.782L165.731 109.704L164.85 111.249Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M190.117 120.345L183.946 122.841L177.197 123.648L171.924 122.507L170.024 121.314L168.547 119.619L167.74 117.57L167.61 115.427L169.229 110.592L173.416 105.585L178.851 101.889L185.047 99.3944L191.772 98.5628L197.069 99.7284L198.969 100.921L200.446 102.616L201.252 104.641L201.382 106.784L199.739 111.619L195.576 116.626L190.117 120.345Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M45.4575 202.834L55.7398 185.173L60.1753 188.614L63.8306 192.827L64.7954 194.434L65.8235 198.418L64.7857 202.285L61.9482 205.104L57.9722 206.17L56.1017 206.107L50.6362 205.006L45.4575 202.834Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M239.52 259.694L241.548 264.769L242.442 270.088L220.969 269.366L221.605 264.098L223.343 259.149L224.248 257.605L227.26 254.956L231.29 254.104L235.373 255.229L238.531 258.086L239.52 259.694Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M193.872 74.4045L205.088 92.7878L200.015 94.6155L194.599 95.3732L192.729 95.3103L188.688 93.9522L185.696 90.96L184.451 87.0161L185.268 83.0709L186.15 81.5493L189.611 77.5521L193.872 74.4045Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M116.404 80.7534L116.613 80.4078L150.195 22.6067L153.633 18.6322L157.893 15.4846L171.976 38.572L147.05 81.4321L146.865 81.7785L142.811 86.3199L137.708 89.7212L131.995 92.0911L125.759 93.0566L123.864 92.9928L119.848 91.6356L116.856 88.6433L115.611 84.6995L116.404 80.7534Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M150.843 81.9124L151.028 81.566L168.18 52.0782L171.618 48.1038L175.878 44.9562L187.094 63.3398L189.96 68.02L181.489 82.591L181.28 82.9366L177.226 87.478L172.147 90.8802L166.41 93.2493L160.174 94.2147L158.304 94.1518L154.263 92.7937L151.271 89.8015L150.026 85.8577L150.843 81.9124Z" fill="#99ADFE"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M256.249 202.548L257.13 201.003L260.142 198.354L264.172 197.502L268.279 198.628L271.413 201.483L308.357 262.01L310.385 267.086L311.303 272.406L284.34 271.498L256.886 226.523L254.69 220.596L253.846 214.432L254.364 208.338L256.249 202.548Z" fill="#EBEFFF"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M12.5671 259.438L14.0518 256.855L25.4479 237.281L78.038 239.051L84.3358 240.415L90.1852 243.174L95.479 246.948L99.7809 251.747L100.77 253.356L101.774 257.339L100.736 261.206L97.8985 264.025L93.9224 265.09L23.2113 262.711L17.7456 261.61L12.5671 259.438Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M41.9021 208.96L60.0474 209.571L66.3458 210.958L72.2189 213.695L77.4885 217.469L81.7909 222.291L82.7795 223.876L83.8082 227.883L82.7702 231.75L79.908 234.545L75.9567 235.635L39.6416 234.413L34.1998 233.289L29.0213 231.117L41.9021 208.96Z" fill="#718BF2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M239.798 230.837L240.704 229.293L243.716 226.667L247.746 225.792L251.829 226.94L254.987 229.773L255.952 231.381L273.941 260.852L275.969 265.927L276.887 271.247L249.924 270.34L241.424 256.42L240.435 254.812L238.263 248.886L237.421 242.746L237.937 236.628L239.798 230.837Z" fill="#EBEFFF"/>
      </g>
      <defs>
          <filter id="filter0_f_3010_4700" x="0.0380859" y="0.824219" width="325.808" height="280.236" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_3010_4700"/>
          </filter>
      </defs>
  </svg>

}