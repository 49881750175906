export const Bitcoin = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2401_21583)">
        <path
          d="M55.6791 35.0153C51.9379 50.0234 36.7325 59.1464 21.7459 55.4053C6.73747 51.6642 -2.38574 36.4592 1.35543 21.473C5.09661 6.46487 20.2801 -2.65814 35.2885 1.08295C50.2751 4.80217 59.4202 20.0072 55.6791 35.0153Z"
          fill="url(#paint0_linear_2401_21583)"
        />
        <path
          d="M41.8484 24.7553C42.3953 21.0365 39.5734 19.024 35.6797 17.6896L36.9484 12.6365L33.8859 11.8709L32.6609 16.7928C31.8516 16.5959 31.0203 16.399 30.1891 16.224L31.4141 11.2803L28.3516 10.5146L27.1047 15.5459C26.4266 15.3928 25.7703 15.2396 25.1359 15.0865V15.0646L20.8922 14.0146L20.0828 17.2959C20.0828 17.2959 22.3578 17.8209 22.3141 17.8428C23.5609 18.149 23.7797 18.9803 23.7359 19.6365L22.2922 25.3896C22.3797 25.4115 22.4891 25.4334 22.6203 25.499C22.5109 25.4771 22.4016 25.4553 22.2922 25.4115L20.2797 33.4615C20.1266 33.8334 19.7328 34.4021 18.8797 34.1834C18.9016 34.2271 16.6484 33.6365 16.6484 33.6365L15.1172 37.1584L19.1203 38.1646C19.8641 38.3615 20.5859 38.5365 21.3078 38.7334L20.0391 43.8303L23.1016 44.5959L24.3703 39.5428C25.2016 39.7615 26.0328 39.9803 26.8203 40.1772L25.5734 45.2084L28.6359 45.974L29.9047 40.8771C35.1547 41.8615 39.0922 41.4678 40.7328 36.7209C42.0672 32.9146 40.6672 30.7053 37.9109 29.2615C39.9453 28.8021 41.4547 27.4678 41.8484 24.7553ZM34.8266 34.599C33.8859 38.4053 27.4547 36.349 25.3766 35.824L27.0609 29.0646C29.1391 29.5896 35.8328 30.6178 34.8266 34.599ZM35.7891 24.6896C34.9141 28.1678 29.5766 26.3959 27.8484 25.9584L29.3797 19.8334C31.1078 20.2709 36.6859 21.0803 35.7891 24.6896Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2401_21583"
          x1="28.5017"
          y1="0.230951"
          x2="28.5017"
          y2="56.2385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9AA4B" />
          <stop offset="1" stop-color="#F7931A" />
        </linearGradient>
        <clipPath id="clip0_2401_21583">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.516602 0.244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
