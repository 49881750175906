export const GitLab = () => {
  return (
    <svg
      width="61"
      height="56"
      viewBox="0 0 61 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4846 55.9998L41.4461 21.5459H19.5391L30.4846 55.9998Z"
        fill="#E24329"
      />
      <path
        d="M4.18408 21.5459L0.846072 32.0134C0.544011 32.9639 0.870215 34.0131 1.66749 34.6056L30.483 55.9997L4.18408 21.5459Z"
        fill="#FCA326"
      />
      <path
        d="M4.18311 21.5461H19.5364L12.9288 0.796194C12.5906 -0.265398 11.1168 -0.265398 10.7664 0.796194L4.18311 21.5461Z"
        fill="#E24329"
      />
      <path
        d="M56.7981 21.5459L60.1242 32.0134C60.4262 32.9639 60.1 34.0131 59.3028 34.6056L30.4834 55.9998L56.7981 21.5459Z"
        fill="#FCA326"
      />
      <path
        d="M56.7947 21.5461H41.4414L48.0369 0.796194C48.3751 -0.265398 49.8489 -0.265398 50.1992 0.796194L56.7947 21.5461Z"
        fill="#E24329"
      />
      <path
        d="M30.4834 55.9998L41.4448 21.5459H56.7981L30.4834 55.9998Z"
        fill="#FC6D26"
      />
      <path
        d="M30.482 55.9998L4.18311 21.5459H19.5364L30.482 55.9998Z"
        fill="#FC6D26"
      />
    </svg>
  );
};
