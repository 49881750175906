export const DotNet = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2607 21.6821C22.1932 27.5914 22.9251 38.1325 28.5759 38.1325C29.0055 38.1325 29.4408 38.0905 29.877 38.0074C24.7407 36.81 24.1317 26.3835 20.9971 20.9834C20.7473 21.2117 20.5018 21.4446 20.2607 21.6821Z"
        fill="url(#paint0_linear_2401_20738)"
      />
      <path
        d="M20.9971 20.9836C24.1318 26.3832 24.7408 36.8097 29.877 38.0076C30.2892 37.9298 30.6948 37.8202 31.0902 37.6799C26.4794 35.4207 25.2054 25.2961 21.6454 20.4219C21.4302 20.6012 21.2136 20.7889 20.9971 20.9836Z"
        fill="url(#paint1_linear_2401_20738)"
      />
      <path
        d="M25.5133 17.7725C25.0845 17.7725 24.6484 17.8145 24.2109 17.8976C23.0681 18.1133 21.9061 18.6055 20.7354 19.3505C21.0547 19.6664 21.3597 20.0274 21.6458 20.4215C22.8371 19.4332 24.0201 18.703 25.1983 18.2638C25.6286 18.103 26.0719 17.9797 26.5235 17.8954C26.1931 17.8127 25.8538 17.7714 25.5133 17.7725Z"
        fill="#14559A"
      />
      <path
        d="M34.6894 35.5976C35.1806 35.2052 35.6536 34.7906 36.1069 34.3551C34.1434 28.4738 33.4403 17.7812 27.7502 17.7812C27.3433 17.7812 26.9329 17.8193 26.5234 17.895C31.7065 19.1843 32.3706 30.4609 34.6894 35.5976Z"
        fill="url(#paint2_linear_2401_20738)"
      />
      <path
        d="M26.5242 17.8959C26.1937 17.8128 25.854 17.7713 25.5132 17.7725L27.7506 17.7808C27.3441 17.7808 26.9337 17.8184 26.5242 17.8959Z"
        fill="#3092C4"
      />
      <path
        d="M34.5899 36.8463C34.3634 36.6274 34.1493 36.396 33.9485 36.1533C32.9913 36.831 32.0375 37.3451 31.0903 37.6798C31.4276 37.8443 31.7807 37.9668 32.1561 38.0424C32.4466 38.1019 32.748 38.1326 33.0639 38.1326C34.1275 38.1326 34.9683 38.0066 35.6832 37.6636C35.2895 37.4501 34.9281 37.1744 34.5899 36.8463Z"
        fill="#1969BC"
      />
      <path
        d="M25.1978 18.2652C29.8401 20.6933 30.2439 31.6894 33.9482 36.1541C34.1967 35.9791 34.4426 35.7936 34.688 35.5981C32.3693 30.4614 31.7064 19.1835 26.5243 17.8955C26.0819 17.9782 25.6405 18.1007 25.1978 18.2652Z"
        fill="url(#paint3_linear_2401_20738)"
      />
      <path
        d="M21.6455 20.4215C25.2054 25.2957 26.4794 35.4208 31.0903 37.68C32.0379 37.3453 32.9916 36.8317 33.9484 36.1536C30.2441 31.6889 29.8403 20.6932 25.198 18.2646C24.0198 18.703 22.8368 19.4332 21.6455 20.4215Z"
        fill="url(#paint4_linear_2401_20738)"
      />
      <path
        d="M17.9835 21.5395C17.4257 22.8008 16.8556 24.4594 16.167 26.6237C17.5368 24.693 18.9027 23.0301 20.2607 21.6821C20.0893 21.1452 19.8874 20.6184 19.6561 20.1045C19.0728 20.552 18.5145 21.031 17.9835 21.5395Z"
        fill="url(#paint5_linear_2401_20738)"
      />
      <path
        d="M20.1511 19.7402C19.9875 19.8575 19.8226 19.9774 19.6567 20.1042C19.8733 20.5767 20.0737 21.107 20.2605 21.6823C20.502 21.4451 20.7476 21.2122 20.9972 20.9836C20.7475 20.548 20.4647 20.1324 20.1511 19.7402Z"
        fill="#2B74B1"
      />
      <path
        d="M20.7353 19.3506C20.5406 19.474 20.3468 19.6043 20.1504 19.7404C20.4545 20.1145 20.7336 20.5323 20.9974 20.9846C21.214 20.7886 21.4301 20.6001 21.6458 20.4216C21.3596 20.0278 21.0551 19.6665 20.7353 19.3506Z"
        fill="#125A9E"
      />
      <path
        d="M52.4009 17.3225C49.7755 27.448 44.2914 35.5811 39.6942 37.6313H39.685L39.437 37.7376L39.4068 37.7494L39.3363 37.7765L39.3048 37.7896L39.1898 37.8303L39.1425 37.8469L39.0852 37.8658L39.0301 37.885L38.9811 37.9016L38.8831 37.9314L38.8411 37.9423L38.775 37.9602L38.7299 37.9725L38.6578 37.9913L38.5698 38.0106C38.7776 38.0871 38.9942 38.1261 39.2274 38.1261C43.5871 38.1261 47.9831 30.3132 55.2565 17.3203H52.4005L52.4009 17.3225Z"
        fill="url(#paint6_linear_2401_20738)"
      />
      <path
        d="M15.2199 18.1843L15.2282 18.1773L15.2383 18.1707H15.2439L15.3144 18.1436L15.3284 18.1366L15.3468 18.1292L15.4224 18.102H15.4264L15.5939 18.0434L15.6189 18.0368L15.6906 18.0154L15.7234 18.0049L15.7956 17.9835L15.8232 17.976L16.0629 17.9139L16.0958 17.9065L16.1658 17.8929L16.1999 17.8824L16.2703 17.8693H16.2869L16.4326 17.8422H16.4615L16.5289 17.8303L16.5669 17.8238L16.6313 17.8142L16.6667 17.8072L16.8233 17.7932C16.669 17.7781 16.5141 17.7702 16.3591 17.7695C11.4491 17.7695 4.68445 26.8792 1.6377 38.6768H2.22526C3.13617 37.0484 4.02033 35.4052 4.87738 33.7479C7.01195 25.3917 11.3585 19.6928 15.2199 18.183"
        fill="url(#paint7_linear_2401_20738)"
      />
      <path
        d="M17.9834 21.5399C18.5146 21.0315 19.0729 20.5523 19.656 20.1044C19.5374 19.8405 19.4043 19.5833 19.2574 19.334C18.8085 19.8791 18.3981 20.5984 17.9834 21.5399Z"
        fill="#0D82CA"
      />
      <path
        d="M18.3868 18.2526C18.7075 18.5213 18.995 18.8914 19.2575 19.3346C19.3401 19.2331 19.4241 19.139 19.5112 19.0506C19.1587 18.7171 18.7589 18.4374 18.3247 18.2207L18.3772 18.2456L18.3868 18.2526Z"
        fill="#0D82CA"
      />
      <path
        d="M4.87793 33.7476C9.35487 25.1205 11.4759 19.6605 15.2204 18.1826C11.3595 19.6937 7.01205 25.3922 4.87793 33.7476Z"
        fill="url(#paint8_linear_2401_20738)"
      />
      <path
        d="M19.5111 19.0508C19.424 19.14 19.3409 19.2332 19.2573 19.3347C19.3982 19.5701 19.529 19.8265 19.6568 20.1047C19.8221 19.9778 19.9875 19.8584 20.1507 19.7407C19.9539 19.4961 19.7401 19.2655 19.5111 19.0508Z"
        fill="#127BCA"
      />
      <path
        d="M6.91051 38.6378L6.82913 38.6474H6.81382L6.7447 38.6544H6.73332L6.56882 38.6654H6.55176C11.0825 38.5385 13.1541 36.2701 14.1818 32.9792C14.9627 30.485 15.6045 28.3832 16.1667 26.6201C14.2688 29.2915 12.3622 32.4756 10.4455 36.0434C9.52151 37.7628 8.11582 38.4812 6.91051 38.6343"
        fill="url(#paint9_linear_2401_20738)"
      />
      <path
        d="M6.91059 38.6365C8.11591 38.4829 9.52159 37.7654 10.4456 36.0451C12.3623 32.4791 14.2698 29.2941 16.1659 26.6223C16.8558 24.4571 17.425 22.7998 17.9833 21.5381C14.2037 25.1356 10.4097 31.1176 6.91016 38.6343"
        fill="url(#paint10_linear_2401_20738)"
      />
      <path
        d="M4.87766 33.748C4.02062 35.4055 3.13631 37.0487 2.2251 38.6769H3.9331C4.1488 37.0168 4.46408 35.3712 4.87722 33.7489"
        fill="#05A1E6"
      />
      <path
        d="M16.6666 17.8098L16.6303 17.8168L16.5664 17.826L16.5288 17.8326L16.4614 17.8444L16.4326 17.8514L16.2869 17.8768H16.2702L16.1994 17.8908L16.1639 17.9004L16.0952 17.914L16.0629 17.9218L15.8227 17.984L15.7951 17.9918L15.7225 18.0133L15.6897 18.0225L15.6184 18.0439L15.5921 18.0509L15.4254 18.1095L15.3497 18.1358L15.3182 18.1489L15.2478 18.1751L15.2242 18.1865C11.4801 19.664 9.35905 25.124 4.88211 33.751C4.46809 35.3731 4.1528 37.0188 3.93799 38.679H4.1773L5.82011 38.672H6.57393L6.73799 38.6611H6.74849L6.81761 38.6541H6.8338L6.91518 38.6445C10.4152 31.1273 14.2092 25.1458 17.9883 21.5483C18.4039 20.6068 18.8139 19.8875 19.2619 19.3433C18.9994 18.9001 18.7124 18.5291 18.3921 18.26L18.3829 18.253L18.3296 18.2272L18.2766 18.2005L18.2254 18.1756L18.1646 18.1502L18.1174 18.1296L18.0181 18.0881L17.9756 18.0723L17.9017 18.0426L17.8619 18.0295L17.7591 17.994H17.7507L17.6317 17.9603L17.6051 17.952L17.5049 17.924L17.4817 17.9175C17.3994 17.8962 17.3164 17.8779 17.2327 17.8628L17.2069 17.8558L17.0989 17.8383L17.0774 17.8317L16.9497 17.8146H16.9274L16.8285 17.8037L16.6719 17.8181"
        fill="url(#paint11_linear_2401_20738)"
      />
      <path
        d="M42.146 22.9278C41.2841 25.6871 40.5872 27.9634 39.9795 29.8363C42.3433 26.5835 44.6717 22.492 46.8885 17.7705C44.3016 18.5829 42.9226 20.4423 42.146 22.9278Z"
        fill="url(#paint12_linear_2401_20738)"
      />
      <path
        d="M40.0539 37.4591C39.9336 37.5203 39.8124 37.5785 39.6938 37.6315C44.2911 35.5809 49.7756 27.4478 52.4006 17.3223H51.8686C45.8364 28.0983 43.8965 35.3123 40.0539 37.4591Z"
        fill="url(#paint13_linear_2401_20738)"
      />
      <path
        d="M37.0522 36.5543C38.0528 35.3332 38.867 33.2577 39.9795 29.8369C38.6963 31.5996 37.4048 33.1147 36.112 34.3502L36.1055 34.3611C36.3877 35.2059 36.697 35.9519 37.0496 36.5539"
        fill="#079AE1"
      />
      <path
        d="M37.052 36.5537C36.7117 36.9693 36.3507 37.2856 35.95 37.5201C35.8625 37.5713 35.7732 37.6195 35.6831 37.6632C36.118 37.899 36.5942 38.0492 37.0857 38.1055L37.315 38.1256H37.3285L37.4388 38.1326H37.8129L37.8553 38.1256L37.938 38.1186H37.9782L38.0679 38.109H38.0754L38.1029 38.1025L38.1987 38.0876H38.2267L38.3344 38.0696H38.3519L38.5667 38.0241C37.973 37.8071 37.4764 37.2948 37.049 36.5655"
        fill="#1969BC"
      />
      <path
        d="M28.5762 38.1325C29.0058 38.1325 29.4407 38.0905 29.8769 38.0065C30.2892 37.9295 30.6948 37.8202 31.09 37.6797C31.4274 37.8442 31.7809 37.9667 32.1554 38.0433C32.4459 38.1023 32.7477 38.133 33.064 38.133H28.5762V38.1325ZM33.064 38.1325C34.1267 38.1325 34.9685 38.0065 35.6829 37.6631C36.1178 37.899 36.594 38.0493 37.0855 38.1058L37.3148 38.1255H37.3284L37.4386 38.1325H37.5707H33.064Z"
        fill="#1E5CB3"
      />
      <path
        d="M37.5708 38.1332H37.8162L37.8587 38.1262L37.9422 38.1192H37.9825L38.0717 38.1096H38.0792L38.1063 38.1026L38.2021 38.0881L38.2314 38.0816L38.3395 38.0641H38.3566L38.5714 38.0186C38.7792 38.0951 38.9957 38.1341 39.2289 38.1341L37.5717 38.1411L37.5708 38.1332Z"
        fill="#1D60B5"
      />
      <path
        d="M36.1084 34.3615V34.3545C35.6551 34.79 35.1821 35.2046 34.6909 35.597C34.4494 35.7894 34.2024 35.9748 33.9502 36.1531C34.1584 36.4016 34.3698 36.6343 34.5924 36.8465C34.9302 37.1742 35.2911 37.4502 35.6853 37.6633C35.775 37.6191 35.8647 37.571 35.9518 37.5202C36.3534 37.2857 36.7134 36.969 37.0543 36.5538C36.7016 35.9518 36.3923 35.2054 36.1106 34.3611"
        fill="#175FAB"
      />
      <path
        d="M50.1844 17.3223H47.5064L47.0821 17.3428L46.8848 17.7655C44.6675 22.4874 42.34 26.5789 39.9762 29.8317C38.8649 33.2521 38.0494 35.328 37.0493 36.5486C37.4768 37.2784 37.9742 37.7911 38.5657 38.0086L38.6121 37.9985H38.6217L38.6545 37.9915L38.7289 37.9731L38.7731 37.9605L38.8391 37.9434L38.8803 37.9316L38.9791 37.9018L39.0281 37.8852L39.0833 37.8651L39.1401 37.8471L39.1865 37.8296L39.302 37.789L39.3339 37.7771L39.4048 37.7496L39.4346 37.7386L39.6831 37.6323H39.6918C39.8104 37.5803 39.9311 37.5212 40.051 37.46C43.8944 35.3127 45.8348 28.0992 51.867 17.3236H50.1822L50.1844 17.3223Z"
        fill="url(#paint14_linear_2401_20738)"
      />
      <path
        d="M16.8242 17.7957L16.8518 17.8027L16.9218 17.8102H16.9454L17.0732 17.8268L17.0946 17.8338L17.2022 17.8517L17.2293 17.8583L17.4774 17.9134L17.5002 17.9204L17.5999 17.9484L17.6275 17.9567L17.746 17.9904L17.7552 17.9974L17.8589 18.0329L17.8983 18.0455L17.9727 18.0757L18.0147 18.0906L18.1135 18.1317L18.1617 18.1523L18.2212 18.1781L18.2737 18.203L18.3257 18.2302C18.754 18.4402 19.1478 18.7206 19.5127 19.0605C19.6474 18.9192 19.7874 18.7924 19.9335 18.6782C19.136 18.1125 18.2317 17.79 17.19 17.79C17.0692 17.79 16.9485 17.797 16.826 17.8062"
        fill="#7DCBEC"
      />
      <path
        d="M19.5107 19.0503C19.7343 19.2582 19.9482 19.49 20.1504 19.7403C20.3464 19.6042 20.5406 19.473 20.7353 19.3505C20.4871 19.1005 20.2179 18.8721 19.9307 18.668C19.7851 18.7817 19.6455 18.9095 19.5107 19.0503Z"
        fill="#5EC5ED"
      />
      <path
        d="M16.7081 17.824L17.0723 17.8085C18.1147 17.8085 19.0182 18.1309 19.8154 18.6963C20.6569 18.0315 21.6748 17.7997 23.0883 17.7997H16.2441L16.7081 17.824Z"
        fill="url(#paint15_linear_2401_20738)"
      />
      <path
        d="M23.0883 17.8008C21.6748 17.8008 20.6591 18.0327 19.8154 18.6974C20.0937 18.894 20.3632 19.1237 20.6194 19.3799C21.7921 18.6356 22.9515 18.1409 24.0955 17.9266C24.5328 17.8449 24.9678 17.803 25.3963 17.803H23.0883V17.8008Z"
        fill="url(#paint16_linear_2401_20738)"
      />
      <path
        d="M39.9795 29.8384C40.588 27.9641 41.2832 25.6887 42.1468 22.9285C42.9243 20.4418 44.3015 18.5824 46.8872 17.7713L47.0867 17.3486C42.8709 17.6011 40.9004 19.7457 39.9095 22.9215C38.1752 28.4659 37.1248 32.06 36.1133 34.3503C37.4048 33.1153 38.6963 31.5993 39.9795 29.8384Z"
        fill="url(#paint17_linear_2401_20738)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2401_20738"
          x1="25.1647"
          y1="17.6329"
          x2="25.1647"
          y2="43.247"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0994DC" />
          <stop offset="0.35" stopColor="#66CEF5" />
          <stop offset="0.846" stopColor="#127BCA" />
          <stop offset="1" stopColor="#127BCA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2401_20738"
          x1="26.1291"
          y1="17.017"
          x2="26.1291"
          y2="43.2166"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E76BC" />
          <stop offset="0.36" stopColor="#36AEE8" />
          <stop offset="0.846" stopColor="#00ADEF" />
          <stop offset="1" stopColor="#00ADEF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2401_20738"
          x1="31.3135"
          y1="37.5105"
          x2="31.3135"
          y2="16.6639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C63B7" />
          <stop offset="0.5" stopColor="#33BDF2" />
          <stop offset="1" stopColor="#33BDF2" stopOpacity="0.42" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2401_20738"
          x1="29.9046"
          y1="14.2801"
          x2="29.9046"
          y2="41.5517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#166AB8" />
          <stop offset="0.4" stopColor="#36AEE8" />
          <stop offset="0.846" stopColor="#0798DD" />
          <stop offset="1" stopColor="#0798DD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2401_20738"
          x1="27.8955"
          y1="14.4924"
          x2="27.8955"
          y2="43.4923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#124379" />
          <stop offset="0.39" stopColor="#1487CB" />
          <stop offset="0.78" stopColor="#165197" />
          <stop offset="1" stopColor="#165197" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2401_20738"
          x1="15.947"
          y1="23.0541"
          x2="20.0403"
          y2="23.0541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33BDF2" stopOpacity="0.698" />
          <stop offset="1" stopColor="#1DACD8" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2401_20738"
          x1="46.9446"
          y1="36.9688"
          x2="46.9446"
          y2="16.6614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#136AB4" />
          <stop offset="0.6" stopColor="#59CAF5" stopOpacity="0.549" />
          <stop offset="1" stopColor="#59CAF5" stopOpacity="0.235" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2401_20738"
          x1="1.42016"
          y1="27.9114"
          x2="16.6043"
          y2="27.9114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05A1E6" stopOpacity="0.247" />
          <stop offset="1" stopColor="#05A1E6" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2401_20738"
          x1="10.1303"
          y1="37.4922"
          x2="10.1303"
          y2="16.699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#318ED5" />
          <stop offset="1" stopColor="#38A7E4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2401_20738"
          x1="11.2511"
          y1="37.6032"
          x2="11.2511"
          y2="17.3391"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05A1E6" />
          <stop offset="1" stopColor="#05A1E6" stopOpacity="0.549" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2401_20738"
          x1="12.5218"
          y1="38.6631"
          x2="12.5218"
          y2="21.5397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1959A6" />
          <stop offset="0.5" stopColor="#05A1E6" />
          <stop offset="0.918" stopColor="#7EC5EA" />
          <stop offset="1" stopColor="#7EC5EA" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2401_20738"
          x1="13.0353"
          y1="37.5288"
          x2="9.76989"
          y2="19.0097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#165096" />
          <stop offset="1" stopColor="#0D82CA" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2401_20738"
          x1="43.3282"
          y1="27.0238"
          x2="43.3282"
          y2="17.3457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05A1E6" />
          <stop offset="0.874" stopColor="#0495D6" />
          <stop offset="1" stopColor="#0495D6" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2401_20738"
          x1="48.0451"
          y1="16.4893"
          x2="44.11"
          y2="38.8062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#38A7E4" stopOpacity="0.329" />
          <stop offset="0.962" stopColor="#0E88D3" />
          <stop offset="1" stopColor="#0E88D3" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2401_20738"
          x1="44.3312"
          y1="17.2858"
          x2="44.3312"
          y2="38.3992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#168CD4" />
          <stop offset="0.5" stopColor="#1C87CC" />
          <stop offset="1" stopColor="#154B8D" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2401_20738"
          x1="15.7759"
          y1="15.2251"
          x2="40.3252"
          y2="25.4079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#97D6EE" />
          <stop offset="0.703" stopColor="#55C1EA" />
          <stop offset="1" stopColor="#55C1EA" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_2401_20738"
          x1="22.45"
          y1="20.9892"
          x2="41.8398"
          y2="12.1997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ACCEC" />
          <stop offset="1" stopColor="#3FB7ED" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_2401_20738"
          x1="41.4506"
          y1="18.4339"
          x2="41.4506"
          y2="39.085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DA7E7" />
          <stop offset="1" stopColor="#37ABE7" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
