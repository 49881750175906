export const Django = () => {
  return (
    <svg
      width="162"
      height="56"
      viewBox="0 0 162 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0763 27.4185C13.0496 32.5192 16.7717 35.5599 22.7115 34.1774V20.6596C16.6966 18.819 13.1014 22.5428 13.0763 27.4185ZM22.8932 1.11328H31.074V40.023C23.2324 41.3112 15.7221 42.1282 10.3493 39.1095C3.43136 35.2229 2.87521 22.8722 8.34941 17.3714C11.1249 14.5826 16.7125 12.6193 22.7115 13.9005V1.66143C22.6958 1.40204 22.6813 1.14381 22.8932 1.11328Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3454 9.69897H36.1646V1.11328H44.3454V9.69897Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.515 34.5424C151.433 38.0249 152.42 18.5023 143.424 20.2938C137.837 21.4064 138.017 32.786 142.515 34.5424ZM142.879 13.5349C149.689 12.8032 154.827 15.8231 156.877 20.4765C160.375 28.4163 156.685 37.5268 151.06 40.2054C148.144 41.5938 145.091 41.726 142.333 41.4841C133.808 40.7371 128.432 32.0073 131.426 22.3031C131.894 20.7843 132.42 19.8553 132.698 19.3804C134.551 16.2145 137.68 14.0935 142.879 13.5349Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6165 31.4371C53.3538 35.2581 59.039 36.1736 62.7063 35.0907V29.4277C59.8885 29.823 56.681 29.6391 55.6165 31.4371ZM69.9782 40.3882C63.2205 40.7788 52.9506 43.0919 49.2536 38.5615C47.0816 35.8996 47.3152 30.8225 49.2536 28.3316C51.7585 25.1132 57.2267 24.4112 62.161 23.9475C62.609 22.8392 62.3069 21.0359 61.7974 20.2937C57.284 19.1385 52.7996 20.9286 49.6174 22.3031V15.9097C54.4237 13.6907 65.5567 11.3543 68.8876 16.4577C70.6369 19.1381 69.9782 23.8127 69.9782 27.9661V40.3882Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.7023 40.754H90.5215V30.1587C90.5215 26.6702 91.083 22.2468 89.2486 20.8423C87.812 19.7417 84.4037 20.294 82.3407 20.8423V40.754H74.1597V15.3621C82.5226 13.1792 97.6181 10.8389 98.7023 21.3902C99.237 26.5924 98.3062 34.3972 98.7023 40.754Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.883 31.9852C112.259 34.2232 115.987 35.0624 119.063 33.6292C118.894 29.354 118.835 24.9683 118.882 20.4766C111.811 18.5996 107.598 26.6465 110.883 31.9852ZM131.426 14.6312C130.697 16.8224 129.632 18.6741 128.699 20.6596C127.856 20.8493 127.125 20.3228 126.699 20.6596C126.512 29.5642 128.586 41.6356 125.063 48.0605C122.941 51.9288 119.035 54.4105 113.064 54.8196C108.714 55.1177 105.338 54.4035 102.156 53.1755V45.5032C105.888 47.6926 115.318 49.4776 117.973 44.955C118.754 43.6244 118.704 42.2491 119.063 40.2055C117.354 40.2957 115.695 41.091 113.61 41.119C105.036 41.2334 99.7314 34.3735 101.793 24.8607C102.59 21.1811 104.736 18.2183 107.61 16.4578C113.581 12.8013 122.759 13.0789 131.426 14.6312Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.345 14.0469C44.3357 20.1001 44.3371 24.4498 44.3365 31.8037C43.989 39.0307 44.0699 44.7856 41.4364 48.2436C40.917 48.9256 39.814 49.9203 38.8912 50.6186C38.1161 51.2048 36.6391 52.3937 35.8006 52.4454C34.9554 52.4975 32.7838 51.2663 31.801 50.8012C30.5104 50.1904 29.3175 49.3794 27.9834 48.9746C31.0952 47.0369 34.0492 45.5842 35.2551 41.6675C36.3039 38.2612 36.1642 33.3253 36.1642 28.5149C36.1642 23.765 36.1853 18.2428 36.1853 14.049L44.345 14.0469Z"
        fill="black"
      />
    </svg>
  );
};
