export const MongoDB = () => {
  return (
    <svg
      width="25"
      height="56"
      viewBox="0 0 25 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5484 22.2857C21.6729 9.27079 15.6795 5.80605 14.1506 3.35683C13.5202 2.27789 12.9617 1.15638 12.479 0C12.3983 1.1562 12.2499 1.8846 11.292 2.76139C9.36871 4.52074 1.20036 11.35 0.512929 26.1378C-0.127542 39.9255 10.3923 48.4274 11.7822 49.3061C12.8509 49.8457 14.1525 49.3177 14.7874 48.8224C19.8585 45.2517 26.7873 35.7323 24.5541 22.2857"
        fill="#10AA50"
      />
      <path
        d="M12.9165 41.9639C12.6517 45.3766 12.462 47.3595 11.7896 49.3096C11.7896 49.3096 12.2309 52.5585 12.5408 56.0001H13.6377C13.8994 53.5766 14.2988 51.1708 14.8341 48.7951C13.4142 48.0782 12.9709 44.9584 12.9165 41.9639Z"
        fill="#B8C4C2"
      />
      <path
        d="M14.8293 48.7975C13.3944 48.1172 12.9793 44.9319 12.9154 41.9643C13.2703 37.0691 13.3738 32.1581 13.2254 27.2517C13.1502 24.6772 13.261 3.407 12.6074 0.292969C13.0545 1.34766 13.5697 2.37059 14.1494 3.35497C15.6783 5.80611 21.6736 9.27086 24.5472 22.2858C26.7861 35.7093 19.8949 45.2036 14.8293 48.7975Z"
        fill="#12924F"
      />
    </svg>
  );
};
