export const UkraineIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.77 10.85L11.665 13.3333H7.49837V12.5H10.8859C10.9612 12.5 11.0352 12.4795 11.0998 12.4407C11.1645 12.402 11.2174 12.3464 11.253 12.28C11.2886 12.2136 11.3054 12.1387 11.3018 12.0634C11.2982 11.9881 11.2743 11.9152 11.2325 11.8525L10.4925 10.7425C10.3407 10.514 10.1347 10.3266 9.89294 10.1971C9.65113 10.0675 9.38103 9.99982 9.10671 10H2.49837C2.27736 10 2.0654 10.0878 1.90912 10.2441C1.75284 10.4004 1.66504 10.6123 1.66504 10.8333V15.8333C1.66504 16.2754 1.84063 16.6993 2.15319 17.0118C2.46575 17.3244 2.88968 17.5 3.33171 17.5H11.3642C11.7205 17.5001 12.0728 17.424 12.3973 17.2768C12.7218 17.1296 13.0112 16.9148 13.2459 16.6467L18.3317 10.8333L17.1217 10.43C16.7258 10.298 16.3032 10.2673 15.8924 10.3407C15.4816 10.4141 15.0958 10.5892 14.77 10.85ZM16.0467 6.15833C16.4225 5.77083 16.655 5.235 16.655 4.64333C16.655 4.05167 16.4225 3.51583 16.0467 3.12833C15.8566 2.92998 15.6285 2.77208 15.3758 2.66411C15.1232 2.55613 14.8514 2.50031 14.5767 2.5C14.5767 2.5 13.54 2.4975 12.4984 3.57167C11.4567 2.4975 10.42 2.5 10.42 2.5C10.1454 2.50035 9.87366 2.5561 9.62108 2.66392C9.3685 2.77174 9.14026 2.92941 8.95004 3.1275C8.57421 3.51583 8.34171 4.05083 8.34171 4.6425C8.34171 5.23417 8.57421 5.77083 8.95004 6.1575L12.4984 10L16.0467 6.15833Z"
        fill="#FFD700"
      />
    </svg>
  );
};
