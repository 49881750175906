export const FutureThree = () => {
   return (
<svg width="384" height="314" viewBox="0 0 384 314" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M244.294 123.737C256.513 142.142 251.498 166.967 233.093 179.186L166.444 223.434C148.04 235.653 123.214 230.638 110.996 212.233L244.294 123.737Z" fill="#FCFCFC"/>
<path d="M503.606 -57.0946C515.825 -38.6899 510.811 -13.8648 492.406 -1.64601L425.757 42.602C407.352 54.8208 382.527 49.8061 370.308 31.4015L503.606 -57.0946Z" fill="#FCFCFC"/>
<path d="M-16.4627 304.04C-29.3821 286.12 -25.3287 261.12 -7.40896 248.2L57.4841 201.415C75.4038 188.495 100.404 192.549 113.323 210.469L-16.4627 304.04Z" fill="#FCFCFC"/>
<path d="M243.252 124.122C230.333 106.202 234.386 81.202 252.306 68.2825L317.199 21.4968C335.119 8.57734 360.119 12.6308 373.038 30.5505L243.252 124.122Z" fill="#FCFCFC"/>
<path d="M212.692 51.3312C224.911 69.7358 219.896 94.561 201.492 106.78L134.843 151.028C116.438 163.247 91.6128 158.232 79.3941 139.827L212.692 51.3312Z" fill="#FCFCFC"/>
<path d="M-48.0642 231.634C-60.9837 213.714 -56.9302 188.714 -39.0105 175.794L25.8825 129.009C43.8022 116.089 68.8023 120.143 81.7218 138.062L-48.0642 231.634Z" fill="#FCFCFC"/>
<path d="M211.651 51.7156C198.731 33.7958 202.785 8.79572 220.704 -4.12377L285.597 -50.9094C303.517 -63.8289 328.517 -59.7754 341.437 -41.8557L211.651 51.7156Z" fill="#FCFCFC"/>
<path d="M181.091 -21.0712C193.31 -2.66651 188.295 22.1587 169.89 34.3774L103.241 78.6255C84.8364 90.8442 60.0113 85.8295 47.7925 67.4249L181.091 -21.0712Z" fill="#FCFCFC"/>
<path d="M-79.6658 159.231C-92.5853 141.311 -88.5318 116.311 -70.6121 103.392L-5.71905 56.6062C12.2007 43.6867 37.2008 47.7402 50.1203 65.6599L-79.6658 159.231Z" fill="#FCFCFC"/>
</svg>

   );
 };
 